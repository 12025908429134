import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";

export const workSchema = {
    title: i18next.t("beneficiaryForm.addressesTitle"),
    required:[
        //"id",
        "oaedRegistrationNumber",
        "oaedRegistrationDate",
        "workPosition",
        "validFrom",
        "validTo",
        "employmentStatus"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.addresses.id"),
        //     type:"integer",
        // },
        oaedRegistrationNumber:{
            label: i18next.t('beneficiaryForm.works.oaedRegistrationNumber'),
            type:"string"
        },
        oaedRegistrationDate:{
            label: i18next.t('beneficiaryForm.works.oaedRegistrationDate'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        workPosition:{
            label: i18next.t('beneficiaryForm.works.workPosition'),
            type:"string"
        },
        validFrom:{
            label: i18next.t('beneficiaryForm.works.validFrom'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        validTo:{
            label: i18next.t('beneficiaryForm.works.validTo'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        notes:{
            label: i18next.t('beneficiaryForm.works.notes'),
            type:"string"
        },
        comments:{
            label: i18next.t('beneficiaryForm.works.comments'),
            type:"string"
        },
        employmentStatus: {
            label: i18next.t('beneficiaryForm.works.employmentStatus'),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t('beneficiaryForm.works.employmentStatus'),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        }
    }
};
