import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import i18next from "../../../translations/i18nConfigInstance";

export const ApproveFormPromptComponent = ({showApproveFormPrompt, confirmActionCallback, cancelActionCallback}) => {
    return(
        <div className={"sweetAlertCustomStyles"}>
            <SweetAlert
                show={showApproveFormPrompt}
                showCancel
                confirmBtnText={i18next.t("prompts.approveFormAction.confirmButtonTitle")}
                confirmBtnBsStyle="success"
                cancelBtnText={i18next.t("prompts.approveFormAction.cancelButtonTitle")}
                //cancelBtnBsStyle="warning"
                title={i18next.t("prompts.approveFormAction.promptTitle")}
                onConfirm={confirmActionCallback}
                onCancel={cancelActionCallback}
                focusConfirmBtn
            >
                {i18next.t("prompts.approveFormAction.promptSubtitle")}
            </SweetAlert>
        </div>
    )
};
