import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";

export const legalStateSchema = {
    title: i18next.t("beneficiaryForm.languagesTitle"),
    required:[
        //"id",
        "citizenship",
        "countryOfOrigin",
        "legalResidenceStatus"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.languages.id"),
        //     type:"integer",
        // },
        citizenship:{
            label: i18next.t("beneficiaryForm.legalStates.citizenship"),
            type:"string"
        },
        countryOfOrigin:{
            label: i18next.t("beneficiaryForm.legalStates.countryOfOrigin"),
            type:"string"
        },
        legalResidenceStatus:{
            label: i18next.t("beneficiaryForm.legalStates.legalResidenceStatus"),
            type:"string"
        },
        motherLanguage:{
            label: i18next.t("beneficiaryForm.legalStates.motherLanguage"),
            type:"string"
        },
        arrivalDate:{
            label: i18next.t("beneficiaryForm.legalStates.arrivalDate"),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        yearsOfStay:{
            label: i18next.t("beneficiaryForm.legalStates.yearsOfStay"),
            oneOf: [{ type: "number" }, { type: "null" }],
        }
    }
};
