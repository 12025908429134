import {Card, Row, Col} from "@themesberg/react-bootstrap";
import React from "react";

const GenericSectionHeaderComponent = ({title, bgColor, titleColor}) => {
    return (
        <Row>
            <Col xs={12} xl={12}>
                <Card border="light"
                      className="customSectionHeaderStyle shadow-xl"
                      style={{backgroundColor: bgColor ? bgColor : '#4A5073'}}
                >
                    <Card.Body>
                        <h6 style={{color: titleColor ? titleColor : "#fefefe"}}>
                            {title}
                        </h6>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
};

export default GenericSectionHeaderComponent;
