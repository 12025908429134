export const genderData = [
    {
        label: "Άνδρας",
        value: "MALE",
    },
    {
        label: "Γυναίκα",
        value: "FEMALE",
    },
    {
        label: "Άλλο",
        value: "OTHER",
    }
];
