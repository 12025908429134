import React from 'react';
import TableFurtherActionsComponent from "../../../../../components/genericTableComponent/TableFurtherActionsComponent";


type Props = {
	isEditMode: boolean,
	isViewMode: boolean,
	isCreateMode: boolean,
	onViewRowItemFormCallback: Function,
	onEditRowItemFormCallback: Function,
	onDeleteRowItemFormCallback: Function,
}

class ReferralsTableRowComp extends React.Component<Props> {

	onViewActionPress = (objectData) => {
		const {
			rowIndex,
			onViewRowItemFormCallback
		} = this.props;
		onViewRowItemFormCallback(objectData, rowIndex);
	};

	onEditActionPress = (objectData) => {
		const {
			rowIndex,
			onEditRowItemFormCallback
		} = this.props;
		onEditRowItemFormCallback(objectData, rowIndex);
	};

	onDeleteActionPress = (objectData) => {
		const {
			rowIndex,
			onDeleteRowItemFormCallback
		} = this.props;
		onDeleteRowItemFormCallback(objectData, rowIndex);
	};

	render() {
		const {
			isViewMode,
			isEditMode,
			isCreateMode,
			recordData: {
				id,
				whoIsCreated,
				whoIsServed,
				notes
			}
		} = this.props;

		return (
			<tr>
				<td>
					<span className="fw-normal">
						{id}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{whoIsCreated}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{whoIsServed}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{notes}
					</span>
				</td>
				<td>
					<TableFurtherActionsComponent
						isViewDisabled={false}
						isEditDisabled={isViewMode}
						isDeleteDisabled={isViewMode}
						viewRowItemCallback={() => this.onViewActionPress(this.props.recordData)}
						editRowItemCallback={() => this.onEditActionPress(this.props.recordData)}
						deleteRowItemCallback={() => this.onDeleteActionPress(this.props.recordData)}
					/>
				</td>
			</tr>
		)
	}
}

export default ReferralsTableRowComp;
