import React from 'react';
import {Button, ButtonGroup, Col, Form, InputGroup, Row, Card, FormGroup, FormCheck} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import i18next from "../../../translations/i18nConfigInstance";

const initSearchFilters = {
    lastName: '',
    amka: '',
    afm: '',
    active: null,
    approved: null,
    alive: null,
}

type Props = {
    pendingGettingRecords: boolean,
    filterSearchRequestCallback: Function
}

class BeneficiariesSearchFilterComponent extends React.Component<Props> {

    state = {
        searchFilters: initSearchFilters
    }

    doTheSearchFilterRequest = () => {
        const {
            searchFilters
        } = this.state;

        const {
            filterSearchRequestCallback
        } = this.props;

        filterSearchRequestCallback(0, 5, searchFilters);
    }

    onClearingSearchFilters = () => {
        this.setState({
            searchFilters: initSearchFilters
        }, () => {
            this.doTheSearchFilterRequest();
        });
    };

    onChangeLastNameInput = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.lastName = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    onChangeAmkaInput = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.amka = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    onChangeAfmInput = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.afm = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    setIsApproved = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.approved = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    setIsActive = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.active = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    render() {

        const {
            // Records from Network Request
            pendingGettingRecords,
        } = this.props;

        const {
            // Search Inputs state
            searchFilters: {
                lastName,
                amka,
                afm,
                active,
                approved,
                alive,
            },
        } = this.state;

        return (
            <Card border="light" className="bg-secondary shadow-sm mb-4 mt-1">
                <Card.Body>

                    <div className=" h-100 d-flex justify-content-center align-items-center">
                        <Row>
                            <Col xs={6} md={4}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled={pendingGettingRecords}
                                        type="text"
                                        placeholder={i18next.t("beneficiaryForm.lastName")}
                                        value={lastName}
                                        onChange={this.onChangeLastNameInput}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs={6} md={4}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled={pendingGettingRecords}
                                        type="text"
                                        placeholder={i18next.t("beneficiaryForm.amka")}
                                        value={amka}
                                        onChange={this.onChangeAmkaInput}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs={6} md={4}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled={pendingGettingRecords}
                                        type="text"
                                        placeholder={i18next.t("beneficiaryForm.afm")}
                                        value={afm}
                                        onChange={this.onChangeAfmInput}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>

                    <div className=" h-100 d-flex justify-content-center align-items-center mt-4">
                        <Row>
                            <Col xs={6} xl={6}>
                                <Form.Group>
                                    <Form.Label>{i18next.t('beneficiaryForm.approved')}</Form.Label>
                                    <Form.Select
                                        disabled={pendingGettingRecords}
                                        defaultValue={approved || ''}
                                        onChange={this.setIsApproved}
                                    >
                                        <option value={''}>{i18next.t('generic.all')}</option>
                                        <option value={true}>{i18next.t('generic.yes')}</option>
                                        <option value={false}>{i18next.t('generic.no')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col xs={6} xl={6}>
                                <Form.Group>
                                    <Form.Label>{i18next.t('beneficiaryForm.active')}</Form.Label>
                                    <Form.Select
                                        disabled={pendingGettingRecords}
                                        defaultValue={active || ''}
                                        onChange={this.setIsActive}
                                    >
                                        <option value={''}>{i18next.t('generic.all')}</option>
                                        <option value={true}>{i18next.t('generic.yes')}</option>
                                        <option value={false}>{i18next.t('generic.no')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className=" h-100 d-flex justify-content-center align-items-center mt-4">
                        <Row>
                            <Col xs={12}>
                                <ButtonGroup>
                                    <Button
                                        disabled={pendingGettingRecords}
                                        variant="warning"
                                        className="text-white"
                                        onClick={this.onClearingSearchFilters}

                                    >
                                        <FontAwesomeIcon icon={faTimesCircle} className="me-2"/>
                                        <span>{i18next.t("generic.clearSearchFilter")}</span>
                                    </Button>

                                    <Button
                                        disabled={pendingGettingRecords}
                                        variant="primary"
                                        className="text-white"
                                        onClick={this.doTheSearchFilterRequest}

                                    >
                                        <FontAwesomeIcon icon={faSearch} className="me-2"/>
                                        <span>{i18next.t("generic.search")}</span>
                                    </Button>

                                </ButtonGroup>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default BeneficiariesSearchFilterComponent;
