import React from "react";
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {Routes} from "../../../routing/routes";
import AuthService from "../../../_services/authorization/authService";
import {withStyles} from '@material-ui/core/styles';
import Copyright from '../../loginPage/Copyright';
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import i18next from "../../../translations/i18nConfigInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import SweetAlert from "react-bootstrap-sweetalert";
import GenericBeneficiaryDetailsComponent
    from "../../../components/genericBeneficiaryDetailsComponent/GenericBeneficiaryDetailsComponent";

const styles = (theme) => ({
    root: {
        height: '100vh',
        //  opacity: 0,
        transition: '2s opacity'
    },
    fadeIn: {
        opacity: 1,
    },
    paper: {
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none'
    },
});

type Props = {
    taxisNetValidationData: any
}

class SignupFormComponent extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            confirmPassword: "",
            email: "",
            loading: false,
            message: "",
            errors: {},
            userError: "",
            passwordError: "",
            emailError: "",
            validUsername: true,
            validPassword: true,
            validEmail: true,

            pendingGettingRegistrationData: false,
            errorGettingTaxisNetValidationData: false,
            registrationData: null,
        };
    }

    onChangeUsername = (e) => {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    onChangeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value
        });

    }

    onChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    validate = () => {
        let errors = {};
        let isValid = true;

        const {
            username,
            password,
            confirmPassword
        } = this.state;

        // if (this.state.email === undefined || this.state.email === '') {
        //     errors['username'] = "Please provide an email";
        //     this.setState({validEmail: false});
        //     isValid = false;
        // }
        // if (!validationUtils.validateEmail(this.state.email)) {
        //     errors['email'] = "Please provide a valid email.";
        //     this.setState({validEmail: false});
        //     isValid = false;
        // }
        if (username === undefined || username === '') {
            errors['username'] = i18next.t('authPages.usernameEmpty');
            this.setState({validUsername: false});
            isValid = false;
        }

        if (password === undefined || password === '') {
            errors['password'] = i18next.t('authPages.passwordEmpty');
            this.setState({validPassword: false});
            isValid = false;
        }

        if (password !== undefined && confirmPassword !== undefined) {
            if (password !== confirmPassword) {
                isValid = false;
                this.setState({validPassword: false});
                errors['password'] = i18next.t('authPages.passwordsDoesNotMatch');
            }
        }

        // TODO Change Password Validation because does not conform to what it claims to be (eg. P@parakis1978)
        // if (password !== undefined && password !== "") {
        //     if (!validationUtils.validatePassword(password)) {
        //         isValid = false;
        //         this.setState({validPassword: false});
        //         errors['password'] = i18next.t('authPages.passwordRegX');
        //     }
        // }

        this.setState({
            userError: errors.username,
            passwordError: errors.password,
            emailError: errors.email
        });

        return isValid;
    }

    handleRegister = (e) => {
        e.preventDefault();

        const {
            username,
            password
        } = this.state;

        const {
            taxisNetValidationData
        } = this.props;

        if (this.validate()) {
            this.setState({
                pendingGettingRegistrationData: true,
            });

            AuthService.register(
                username,
                password,
                taxisNetValidationData.alfa
            ).then(
                response => {
                    this.setState({
                        pendingGettingRegistrationData: false,
                        errorGettingTaxisNetValidationData: false,
                        registrationData: response.data,
                    });
                })
                .catch((error) => {
                    console.log("Error getting Registration Data: ", error);
                    this.setState({
                        pendingGettingRegistrationData: false,
                        errorGettingRegistrationData: true,
                    })
                });
        }
    };

    onSuccessRegistrationCallback = () => {
        this.props.history.push("/");
    }

    render() {
        const {
            classes,
            taxisNetValidationData
        } = this.props;

        const {
            username,
            userError,
            validUsername,
            password,
            passwordError,
            validPassword,
            confirmPassword,

            pendingGettingRegistrationData,
            errorGettingRegistrationData,
            registrationData,
        } = this.state;


        return (
            <>
                <Grid item xs={12} sm={8} md={5} lg={5} component={Paper} elevation={6} square>

                    <Row style={{marginTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                        <GenericBeneficiaryDetailsComponent
                            firstName={taxisNetValidationData?.firstName}
                            lastName={taxisNetValidationData?.lastName}
                            amka={taxisNetValidationData?.amka}
                            afm={taxisNetValidationData?.afm}
                            title={i18next.t('authPages.successTaxisNetValidation')}
                        />
                    </Row>

                    <Card border="light" className="bg-white shadow-sm"
                          style={{marginTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                        <Card.Body>
                            <h4 className={"text-center"}>{i18next.t('authPages.registrationInfoText')}</h4>
                            <div className={classes.paper}>
                                <form className={classes.form}>
                                    <TextField
                                        InputProps={{
                                            error: !validUsername
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={username}
                                        onChange={this.onChangeUsername}
                                        id="username"
                                        label={i18next.t('authPages.username')}
                                        name="username"
                                        autoComplete="email"
                                        helperText={userError}
                                        autoFocus
                                        disabled={pendingGettingRegistrationData}
                                    />
                                    {/*<TextField*/}
                                    {/*    InputProps={{*/}
                                    {/*        error: !this.state.validEmail*/}
                                    {/*    }}*/}
                                    {/*    variant="outlined"*/}
                                    {/*    margin="normal"*/}
                                    {/*    required*/}
                                    {/*    fullWidth*/}
                                    {/*    name="email"*/}
                                    {/*    label={i18next.t('authPages.email')}*/}
                                    {/*    type="email"*/}
                                    {/*    value={this.state.email}*/}
                                    {/*    onChange={this.onChangeEmail}*/}
                                    {/*    helperText={this.state.emailError}*/}
                                    {/*    id="email"*/}
                                    {/*/>*/}
                                    <TextField
                                        InputProps={{
                                            error: !validPassword
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={i18next.t('authPages.password')}
                                        type="password"
                                        value={password}
                                        onChange={this.onChangePassword}
                                        helperText={passwordError}
                                        id="password"
                                        autoComplete="current-password"
                                        disabled={pendingGettingRegistrationData}
                                    />
                                    <TextField
                                        InputProps={{
                                            error: !validPassword
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={i18next.t('authPages.confirmPassword')}
                                        type="password"
                                        value={confirmPassword}
                                        onChange={this.onChangeConfirmPassword}
                                        helperText={passwordError}
                                        id="confirmpassword"
                                        autoComplete="current-password"
                                        disabled={pendingGettingRegistrationData}
                                    />

                                    <div className="d-flex align-content-center justify-content-center">
                                        <BeatLoader
                                            size={35}
                                            color={"#4A5073"}
                                            loading={pendingGettingRegistrationData}
                                        />
                                    </div>

                                    <Button
                                        disabled={pendingGettingRegistrationData}
                                        onClick={this.handleRegister}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        {i18next.t('authPages.signUp')}
                                    </Button>

                                    <Grid container>
                                        <Grid item>
                                            <Link to={Routes.Signin.path} className="fw-bold" variant="body2">
                                                {i18next.t('authPages.alreadyHaveAnAccount')}
                                            </Link>
                                        </Grid>
                                    </Grid>

                                    <Box mt={5}>
                                        <Copyright copyrightTitle={i18next.t('generic.ppel')}/>
                                    </Box>
                                </form>
                            </div>
                        </Card.Body>
                    </Card>
                </Grid>

                <div className={"sweetAlertCustomStyles"}>
                    <SweetAlert
                        show={registrationData != null}
                        success
                        confirmBtnText={i18next.t("prompts.successSaveFormAction.confirmButtonTitle")}
                        confirmBtnBsStyle="success"
                        title={i18next.t("authPages.successRegistrationTitle")}
                        onConfirm={this.onSuccessRegistrationCallback}
                        focusConfirmBtn
                    >
                        {i18next.t("authPages.successRegistrationMessage")}
                    </SweetAlert>
                </div>
            </>
        );
    }
}

export default withRouter((withStyles(styles)(SignupFormComponent)));
