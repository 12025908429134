export const communicationWayKindData = [
    {
        label: "Email",
        value: "EMAIL",
    },
    {
        label: "Κινητό Τηλέφωνο",
        value: "MOBILE_PHONE",
    },
    {
        label: "Αριθμός Σταθερού",
        value: "PHONE",
    }
];
