import React from 'react';
import TableFurtherActionsComponent from "../../../components/genericTableComponent/TableFurtherActionsComponent";
import {convertDateToLocaleDateTime} from "../../../utils/dateTimeUtils";
import {withRouter} from "react-router-dom";
import {statusTitle} from "../../../utils/applicationFormStatusUtil";

type Props = {
    isBeneficiaryUser: boolean,
    isBackOfficerUser: boolean
}

class ApplicationFormTableRowComponent extends React.Component<Props> {

    onViewActionPress = (appFormId) => {
        const {
            history
        } = this.props;
        history.push({
            pathname: '/applicationForm/view/' + appFormId,
            state: {detail: appFormId}
        })
    };

    onEditActionPress = (appFormId) => {
        const {
            history
        } = this.props;

        // We can pass params to URL or even pass Object via state,
        // and can retrieve object from Component Navigatin to,
        // accessing this.props.location.state
        // *Important info to access 'this.props.location' like here 'this.props.history'
        // component doing the handling MUST be wrapped in 'react-router-dom.withRouter'
        history.push({
            pathname: '/applicationForm/edit/' + appFormId,
            state: {detail: appFormId}
        })
    };

    render() {
        const {
            isBeneficiaryUser,
            isBackOfficerUser,
            recordData: {
                id,
                user: {
                    firstName,
                    lastName,
                    amka,
                    afm
                },
                applicationFormType: {
                    description
                },
                status,
                submittedAt,
                actionAt,
                actionByUsername,
            }
        } = this.props;

        return (
            <tr>
                <td>
                    <span className="fw-normal">
                      {id}
                    </span>
                </td>
                {isBackOfficerUser &&
                <td>
                  <span className="fw-normal">
                      {lastName} {firstName}
                  </span>
                </td>}
                {isBackOfficerUser &&
                <td>
                  <span className="fw-normal">
                      {amka}
                  </span>
                </td>}
                {isBackOfficerUser &&
                <td>
                  <span className="fw-normal">
                      {afm}
                  </span>
                </td>}
                <td>
                  <span className="fw-normal">
                      {description}
                  </span>
                </td>
                <td>
                    <span className={`text-${status === 0 ? 'warning' : status === 1 ? 'success' : status === 2 ? 'danger' : status === 3 ? 'secondary' : status === 4 ? 'success'  : 'black'} text-lg`}>● </span>
                    <span>{statusTitle(status)}</span>
                </td>
                <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(submittedAt)}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(actionAt)}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {actionByUsername}
                  </span>
                </td>
                <td>
                    <TableFurtherActionsComponent
                        isViewDisabled={false}
                        isEditDisabled={false}
                        isDeleteDisabled={true}
                        isSendMessageActionDisabled={false}
                        viewRowItemCallback={() => this.onViewActionPress(id)}
                        editRowItemCallback={ (isBeneficiaryUser && (status === 0 || status === 3)) || (isBackOfficerUser && status === 4) ? () => this.onEditActionPress(id) : null}
                        sendMessageRowItemCallback={null}
                        deleteRowItemCallback={null}
                    />
                </td>
            </tr>
        )
    }
}

export default withRouter(ApplicationFormTableRowComponent);
