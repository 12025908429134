export const EducationModel = {
    id: null,
    specialization: null,
    greekSpeakingLevel: null,
    greekWritingLevel: null,
    educationLevel: {
        id: null,
        description: null
    }
}
