export const WorkModel = {
    id: null,
    oaedRegistrationNumber: "",
    oaedRegistrationDate: "2021-06-27T21:00:00.000+00:00",
    workPosition: null,
    validFrom: "2021-06-27T21:00:00.000+00:00",
    validTo: "2021-06-27T21:00:00.000+00:00",
    notes: "",
    comments: "",
    employmentStatus: {
        id: null,
        description: null
    }
}
