import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import {Col, Row} from "@themesberg/react-bootstrap";
import {AutoField, AutoForm, SelectField} from "uniforms-bootstrap5";
import {createUniformSchema} from "../../../../../utils/uniformsSchemaBridgeGenerator";
import {socialProgramSchema} from "../../../../../schema/beneficiaryFormSchemas/nested/socialProgramSchema";
import {SocialProgramToParticipateModel} from "../../../../../model/beneficiary/socialProgramToParticipateModel";
import domainsService from "../../../../../_services/domainsServices/domainsService";

type Props = {
    openFormModal: boolean,
    modalTitle: String,
    onClosingModalCallback: Function,
    onSaveButtonCallback: Function,
    okButtonTitle: String,
    recordData: Object,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    isEditModalFormState: boolean,
    isViewModalFormState: boolean,
    isCreateModalFormState: boolean,
}

class SocialProgramsToParticipateFormComp extends React.Component<Props> {

    state = {
        localRecordData: Object.assign({}, this.props.recordData),

        // SocialProgramCategories state
        pendingGettingSocialProgramCategories: false,
        errorGettingSocialProgramCategories: false,
        socialProgramCategories: null,
    };

    _socialProgramFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _socialProgramUniformSchema = createUniformSchema(socialProgramSchema);

    componentDidMount() {
        const {
            // Parent Form State
            isCreateMode,
            isEditMode,
            isViewMode,

            // Modal Form State
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        if (isCreateModalFormState) {
            this.setState({
                localRecordData: Object.assign({}, SocialProgramToParticipateModel)
            })
        }

        this.fetchSocialProgramCategories();
    }

    componentWillUnmount() {
        this._socialProgramFormRef = null;
    }

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if(model) {
            if(model.socialProgramCategory && model.socialProgramCategory.id) {
                model.socialProgramCategory.id = parseInt(model.socialProgramCategory.id);
            }
        }

        this.setState({
            localRecordData: Object.assign({}, model)
        });
    };

    /**
     *  In case of Autoform of single
     */
    onSaveButtonPress = () => {
        const {
            localRecordData
        } = this.state;

        const {
            onSaveButtonCallback
        } = this.props;

        this._socialProgramFormRef.submit()
            .then(() => {
                onSaveButtonCallback(localRecordData);
            }).catch((error) => {
            console.log("Form has validation errors", error);
        });
    };

    fetchSocialProgramCategories = () => {
        this.setState({
            pendingGettingSocialProgramCategories: true,
        });

        domainsService.getSocialProgramCategories()
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {
                const uniformObjCompatible = response.map((item, index) => {
                    return {
                        label: item.description,
                        value: item.id
                    }
                });

                this.setState({
                    pendingGettingSocialProgramCategories: false,
                    errorGettingSocialProgramCategories: false,
                    socialProgramCategories: uniformObjCompatible,
                })
            })
            .catch((error) => {
                console.log("Error getting Social Program Categories: ", error);
                this.setState({
                    pendingGettingSocialProgramCategories: false,
                    errorGettingSocialProgramCategories: true
                })
            });
    }

    render() {
        const {
            openFormModal,
            onClosingModalCallback,
            okModalButtonCallback,
            okButtonTitle,
            modalTitle,
            isViewMode,
            isEditMode,
            isCreateMode,
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        const {
            localRecordData,

            // SocialProgramCategories state
            pendingGettingSocialProgramCategories,
            errorGettingSocialProgramCategories,
            socialProgramCategories,
        } = this.state;

        return (
            <GenericModalComponent
                showOkButton={!isViewModalFormState}
                showCancelButton={true}
                okModalButtonTitle={okButtonTitle}
                okButtonCallback={this.onSaveButtonPress}
                closeModalCallback={onClosingModalCallback}
                isModalVisible={openFormModal}
                modalTitle={modalTitle}
                isModalFullScreen={false}
                modalSize={'md'}
            >

                <Row className={"autoFormCustom"}>
                    <Col xs={12} xl={12}>
                        <AutoForm
                            ref={(form) => {
                                this._socialProgramFormRef = form
                            }}
                            schema={this._socialProgramUniformSchema}
                            onChangeModel={(model) => this.updateModelCallback(model)}
                            model={localRecordData ? localRecordData : {}}
                            readOnly={isViewMode}
                            showInlineError
                            placeholder
                        >
                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"socialInstitution"}/>
                                </Col>
                            </Row>

                            <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={10}>
                                    <SelectField
                                        name="socialProgramCategory.id"
                                        options={socialProgramCategories}
                                    />
                                </Col>
                            </Row>

                        </AutoForm>
                    </Col>
                </Row>
            </GenericModalComponent>
        )
    }
}

export default SocialProgramsToParticipateFormComp;
