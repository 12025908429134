import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import BgImage from "../../assets/img/beneficiary-monitoring.jpg";
import {withStyles} from '@material-ui/core/styles';
import SignupFormComponent from "./components/SignupFormComponent";
import TaxisNetValidationFormComponent from "./components/TaxisNetValidationFormComponent";

const styles = (theme) => ({
    root: {
        height: '100vh',
        //  opacity: 0,
        transition: '2s opacity'
    },
    fadeIn: {
        opacity: 1,
    },
    image: {
        backgroundImage: 'url(' + BgImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
});

class SignupPage extends React.Component {

    state = {
        isTaxisNetValidationForm: true,
        taxisNetValidationData: null
    }

    updateTaxisNetValidationData = (taxisNetValidationData) => {
        this.setState({
            isTaxisNetValidationForm: false,
            taxisNetValidationData: taxisNetValidationData
        })
    }

    render() {
        const {classes} = this.props;
        const {
            isTaxisNetValidationForm,
            taxisNetValidationData
        } = this.state;

		const urlParams = this.props.match.params;

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>

                {isTaxisNetValidationForm && <TaxisNetValidationFormComponent urlParams={urlParams} updateParentForm={this.updateTaxisNetValidationData}/>}

                {!isTaxisNetValidationForm && <SignupFormComponent urlParams={urlParams} taxisNetValidationData={taxisNetValidationData}/>}
            </Grid>
        );
    }
}

export default withStyles(styles)(SignupPage);
