import {combineReducers} from "redux";


/**
 * combining multiple reducers into a single reducer
 */
const rootReducer = combineReducers({

});

export default rootReducer;
