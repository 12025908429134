import React from 'react';
import RoutingComponent from "../../routing/RoutingComponent";
import AuthService from "../../_services/authorization/authService";
import Preloader from "../Preloader";
import {decodeJwtToken} from "../../utils/jwtUtils";

const LOCAL_STORAGE_AUTH_USER_DATA = process.env.REACT_APP_AUTH_USER_DATA;

class AuthManagerComponent extends React.Component {

    state = {
        pendingValidation: true,
    };

    componentDidMount(): void {
        AuthService.validateTokenAndGetUser()
            .then((response) => {
                this.isTokenValidCallback(response);
            });
    }

    isTokenValidCallback = (accessToken) => {
        if (accessToken !== null) {
            const userAuthenticatedData = decodeJwtToken(accessToken);
            localStorage.setItem(LOCAL_STORAGE_AUTH_USER_DATA, JSON.stringify(userAuthenticatedData));
        } else {
            localStorage.removeItem(LOCAL_STORAGE_AUTH_USER_DATA);
        }
        this.setPendingValidation(false);
    };

    setPendingValidation = (value) => {
        this.setState({
            pendingValidation: value,
        });
    };

    render() {
        const {
            pendingValidation
        } = this.state;

        return (
            <>
                {pendingValidation === true &&
                <Preloader show={true}/>
                }

                {pendingValidation === false &&
                <RoutingComponent/>
                }
            </>
        )
    }
}

export default AuthManagerComponent;
