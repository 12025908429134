export const HousingStatusModel = {
    id: null,
    noPermanentResidence: false,
    homelessFrom: null,
    homelessReasonDescription: "",
    residenceStatusDescription: "",
    residenceStatusType: {
        id: null,
        description: null
    },
    housingStructType: {
        id: null,
        description: null
    }
}
