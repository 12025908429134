import i18next from "../../../translations/i18nConfigInstance";

export const languageSchema = {
    title: i18next.t("beneficiaryForm.languagesTitle"),
    required:[
        //"id",
        "name",
        "spokenLanguage"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.languages.id"),
        //     type:"integer",
        // },
        name:{
            label: i18next.t("beneficiaryForm.languages.name"),
            type:"string"
        },
        spokenLanguage:{
            label: i18next.t("beneficiaryForm.languages.spokenLanguage"),
            oneOf: [{ type: "boolean" }, { type: "null" }],
        }
    }
};
