export const isoDateFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

export const convertDateToLocaleDateTime = (dateToBeFormatted) => {
    if(dateToBeFormatted) {
        return new Intl.DateTimeFormat(
            "el-GR", // TODO Take the locale value dynamically when functionality is ready
            {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            }).format(new Date(dateToBeFormatted))
    } else {
        return null;
    }
};
