import React from 'react';
import { Card, Col, FormCheck, FormGroup, Row } from "@themesberg/react-bootstrap";
import { WidgetWithAvatarComponent } from "../../../../components/WidgetWithAvatar/WidgetWithAvatarComponent";
import beneficiaryBulletinLottieAnimation from "../../../../assets/lottieAnimations/office/36678-check-list.json";
import i18next from "../../../../translations/i18nConfigInstance";
import { createUniformSchema } from "../../../../utils/uniformsSchemaBridgeGenerator";
import { educationSchema } from "../../../../schema/beneficiaryFormSchemas/nested/educationSchema";
import { AutoField, AutoForm, SelectField } from "uniforms-bootstrap5";
import { beneficiaryGeneralDataSchema } from "../../../../schema/beneficiaryFormSchemas/nested/beneficiaryGeneralDataSchema";

type Props = {
    updateModelParentCallback: Function,
    registerUniformRefCallback: Function,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    recordData: any
}

class BeneficiaryPersonalDataFormComponent extends React.Component<Props> {


    // Reference to AutoForm form property for validate/submit operations
    _beneficiaryGeneralDataFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _beneficiaryGeneralDataUniformSchema = createUniformSchema(beneficiaryGeneralDataSchema);

    state = {

    }

    /**
     * When rendering has completed
     * we register to parent component,
     * the Autoform Reference in order to
     * control the validation and submit
     * from parent Component
     */
    componentDidMount() {
        const {
            registerUniformRefCallback
        } = this.props;
        registerUniformRefCallback(this._beneficiaryGeneralDataFormRef);
    }

    componentWillUnmount() {
        this._beneficiaryGeneralDataFormRef = null;
    }

    /**
     * Updates the parent model containing
     * current data schema with local schema refreshed data
     * via callback
     * @param data
     */
    updateParentModel = (data) => {
        const {
            updateModelParentCallback
        } = this.props;
        updateModelParentCallback(data);
    };

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if (model) {

        }
        this.updateParentModel(model);
    };

    render() {
        const {
            isViewMode,
            isEditMode,
            isCreateMode,
            recordData
        } = this.props;

        return (
            <Row>
                <Col xs={12} xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>

                            <Row className={"autoFormCustom"}>
                                <Col xs={12} xl={12}>
                                    <AutoForm
                                        ref={(form) => {
                                            this._beneficiaryGeneralDataFormRef = form
                                        }}
                                        schema={this._beneficiaryGeneralDataUniformSchema}
                                        onChangeModel={(model) => this.updateModelCallback(model)}
                                        model={recordData ? recordData : {}}
                                        showInlineError
                                        placeholder
                                    >

                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"amka"} />
                                            </Col>

                                            <Col xs={12} xl={6}>
                                                <AutoField name={"afm"} readOnly disabled />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"lastName"} readOnly disabled />
                                            </Col>

                                            <Col xs={12} xl={6}>
                                                <AutoField name={"firstName"} readOnly disabled />
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"gender"} />
                                            </Col>
                                        </Row>

                                        <Row className={"mt-4 mb-4"}>
                                            <Col xs={12} xl={4}>
                                                <AutoField name={"birthDate"} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} xl={4}>
                                                <AutoField name={"alive"} />
                                            </Col>
                                        </Row>

                                    </AutoForm>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <WidgetWithAvatarComponent
                                title={i18next.t("beneficiaryForm.page.beneficiary")}
                                subtitle={recordData?.firstName + " " + recordData?.lastName}
                                lottieAnimationSourceFile={beneficiaryBulletinLottieAnimation}
                                animationWidth={130}
                                animationHeight={130}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default BeneficiaryPersonalDataFormComponent;
