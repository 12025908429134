import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import { Col, Row } from "@themesberg/react-bootstrap";
import { AutoField, AutoForm, SelectField } from "uniforms-bootstrap5";
import { createUniformSchema } from "../../../../../utils/uniformsSchemaBridgeGenerator";
import { financeSchema } from "../../../../../schema/beneficiaryFormSchemas/nested/financeSchema";
import domainsService from "../../../../../_services/domainsServices/domainsService";
import { FinanceModel } from "../../../../../model/beneficiary/financeModel";

type Props = {
	openFormModal: boolean,
	modalTitle: String,
	onClosingModalCallback: Function,
	onSaveButtonCallback: Function,
	okButtonTitle: String,
	recordData: Object,
	isEditMode: boolean,
	isViewMode: boolean,
	isCreateMode: boolean,
	isEditModalFormState: boolean,
	isViewModalFormState: boolean,
	isCreateModalFormState: boolean,
}

const incomeSelections = [
	{
		label: "0-2.500€",
		value: 0
	},
	{
		label: "2.501-5000€",
		value: 2501
	},
	{
		label: "5.001-7.500€",
		value: 5001
	},
	{
		label: "7.501-10.000€",
		value: 7501
	},
	{
		label: "10.001 κ άνω",
		value: 10001
	}
]

class FinanceFormComp extends React.Component<Props> {

	state = {
		localRecordData: Object.assign({}, this.props.recordData),

		//ResidenceTypes State
		pendingGettingVehicleTypes: false,
		errorGettingVehicleTypes: false,
		vehicleTypes: null,
	};

	_financeFormRef = null;

	/**
	 * Creates the Uniform Schema Bridge
	 * @returns {JSONSchemaBridge}
	 */
	_financeUniformSchema = createUniformSchema(financeSchema);

	componentDidMount() {
		const {
			// Parent Form State
			isCreateMode,
			isEditMode,
			isViewMode,

			// Modal Form State
			isEditModalFormState,
			isViewModalFormState,
			isCreateModalFormState,
		} = this.props;

		if (isCreateModalFormState) {
			this.setState({
				localRecordData: Object.assign({}, FinanceModel)
			})
		}

		this.fetchVehicleTypes();
	}

	componentWillUnmount() {
		this._financeFormRef = null;
	}

	/**
	 * Called every time form onSubmit occurs
	 * On Submit we update the parent model by calling it's callback to update records data
	 * witch comes back to us from parent once again via props
	 * @param model
	 */
	updateModelCallback = (model) => {
		if (model) {
			if (model.vehicleType && model.vehicleType.id) {
				model.vehicleType.id = parseInt(model.vehicleType.id);
			}
		}

		this.setState({
			localRecordData: Object.assign({}, model)
		});
	};

	/**
	 *  In case of Autoform of single
	 */
	onSaveButtonPress = () => {
		const {
			localRecordData
		} = this.state;

		const {
			onSaveButtonCallback
		} = this.props;

		this._financeFormRef.submit()
			.then(() => {
				onSaveButtonCallback(localRecordData);
			}).catch((error) => {
				console.log("Form has validation errors", error);
			});
	};

	fetchVehicleTypes = () => {
		this.setState({
			pendingGettingVehicleTypes: true,
		});

		domainsService.getVehiclesTypes()
			// In success we split model to master/details
			// so that we can easily handle them on separated forms
			.then((response) => {
				const uniformObjCompatible = response.map((item, index) => {
					return {
						label: item.description,
						value: item.id
					}
				});

				this.setState({
					pendingGettingVehicleTypes: false,
					errorGettingVehicleTypes: false,
					vehicleTypes: uniformObjCompatible,
				})
			})
			.catch((error) => {
				console.log("Error getting Residence Types Data: ", error);
				this.setState({
					pendingGettingVehicleTypes: false,
					errorGettingVehicleTypes: true
				})
			});
	}

	setIncome(income) {
		let tmpLocalRecordData = this.state.localRecordData;
		tmpLocalRecordData.income = parseInt(income);
		this.setState({
			localRecordData: tmpLocalRecordData
		})
	}


	render() {
		const {
			openFormModal,
			onClosingModalCallback,
			okModalButtonCallback,
			okButtonTitle,
			modalTitle,
			isViewMode,
			isEditMode,
			isCreateMode,
			isEditModalFormState,
			isViewModalFormState,
			isCreateModalFormState,
		} = this.props;

		const {
			localRecordData,

			//ResidenceTypes State
			pendingGettingVehicleTypes,
			vehicleTypes
		} = this.state;

		return (
			<GenericModalComponent
				showOkButton={!isViewModalFormState}
				showCancelButton={true}
				okModalButtonTitle={okButtonTitle}
				okButtonCallback={this.onSaveButtonPress}
				closeModalCallback={onClosingModalCallback}
				isModalVisible={openFormModal}
				modalTitle={modalTitle}
				isModalFullScreen={false}
				modalSize={'lg'}
			>

				<Row className={"autoFormCustom"}>
					<Col xs={12} xl={12}>
						<AutoForm
							ref={(form) => {
								this._financeFormRef = form
							}}
							schema={this._financeUniformSchema}
							onChangeModel={(model) => this.updateModelCallback(model)}
							model={localRecordData ? localRecordData : {}}
							readOnly={isViewMode}
							showInlineError
							placeholder
						>
							<Row>
								<Col xs={12} xl={4}>
									<SelectField
										name="income"
										options={incomeSelections}
										onChange={event => this.setIncome(event)}
									/>
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={4}>
									<AutoField name={"allowances"} />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={4}>
									<AutoField name={"otherSources"} />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={4}>
									<AutoField name={"valueRealEstate"} />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={4}>
									<AutoField name={"valueMovableProperty"} />
								</Col>
							</Row>

							<Row className={"mt-4 mb-4"}>
								<Col xs={12} xl={4}>
									<SelectField
										name="vehicleType.id"
										options={vehicleTypes}
									/>
								</Col>
							</Row>

							{/* <Row className={"mt-4 mb-4"}>
								<Col xs={12} xl={4}>
									<AutoField name={"validFrom"} />
								</Col>
								<Col xs={12} xl={4}>
									<AutoField name={"validTo"} />
								</Col>
							</Row> */}

							<Row>
								<Col xs={12} xl={10}>
									<AutoField name={"notes"} />
								</Col>
								<Col xs={12} xl={10}>
									<AutoField name={"comments"} />
								</Col>
							</Row>

						</AutoForm>
					</Col>
				</Row>
			</GenericModalComponent>
		)
	}
}

export default FinanceFormComp;
