import React from 'react';
import {withRouter} from "react-router-dom";
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import GenericTableComponent from "../../components/genericTableComponent/GenericTableComponent";
import beneficiaryService from "../../_services/beneficiaryServices/beneficiaryService";
import i18next from "../../translations/i18nConfigInstance";
import BeneficiaryTableRowComponent from "./components/BeneficiaryTableRowComponent";
import BeneficiariesSearchFilterComponent from "./components/BeneficiariesSearchFilterComponent";

const tableHeaderTitles = [
    i18next.t("beneficiaryForm.id"),
    i18next.t("beneficiaryForm.amka"),
    i18next.t("beneficiaryForm.afm"),
    i18next.t("beneficiaryForm.firstName"),
    i18next.t("beneficiaryForm.lastName"),
    i18next.t("beneficiaryForm.birthDate"),
    i18next.t("beneficiaryForm.gender"),
    i18next.t("beneficiaryForm.alive"),
    i18next.t("beneficiaryForm.active"),
    i18next.t("beneficiaryForm.approved"),
    i18next.t("generic.actions")
];

class BeneficiariesListPage extends React.Component {

    state = {
        // Records from Network Request
        pendingGettingRecords: false,
        errorGettingRecords: false,
        recordsData: null,

        // Search Inputs state
        searchFilters: {},
    };

    componentDidMount(): void {
        this.fetchBeneficiariesList();
    }

    fetchBeneficiariesList = (pageNumber, pageSize, searchFilterData) => {
        this.setState({
            pendingGettingRecords: true,
        });

        beneficiaryService.getList(pageNumber, pageSize, this.state.searchFilters)
            .then((response) => {
                this.setState({
                    pendingGettingRecords: false,
                    errorGettingRecords: false,
                    recordsData: response
                })
            })
            .catch((error) => {
                console.log("Error getting Beneficiaries List  records ", error);
                this.setState({
                    pendingGettingRecords: false,
                    errorGettingRecords: true
                })
            });
    };

    filterSearchRequestCallback = (pageNumber, pageSize, searchFilterData) => {
        this.setState({
            searchFilters: searchFilterData
        }, () => {
            this.fetchBeneficiariesList(pageNumber, pageSize, searchFilterData);
        });
    }

    render() {

        const {
            // Records from Network Request
            pendingGettingRecords,
            errorGettingRecords,
            recordsData,

            // Search Inputs state
            userLastNameSearchValue,
        } = this.state;

        return (
            <>
                <Row>
                    <Col xs={12}>
                        <Card border="light" className="customSectionHeaderStyle shadow-sm mb-4">
                            <Card.Body>
                                <h4 style={{color: "#fefefe"}}>{i18next.t("beneficiaryForm.page.searchPageTitle")}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <BeneficiariesSearchFilterComponent
                    filterSearchRequestCallback={this.filterSearchRequestCallback}
                    pendingGettingRecords={pendingGettingRecords}
                />

                <Row>
                    <Col xs={12}>
                        <GenericTableComponent
                            recordsData={recordsData?.elements}
                            stickyLastColumn={true}
                            pendingGettingRecordsData={pendingGettingRecords}
                            errorGettingRecordsData={errorGettingRecords}
                            tableHeaderTitles={tableHeaderTitles}
                            tableRowComponent={<BeneficiaryTableRowComponent/>}
                            serverSidePaginationEnabled={true}
                            totalItemsPerPage={5}
                            paginatedData={recordsData}
                            serverSidePaginationServiceCallback={this.fetchBeneficiariesList}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default withRouter(BeneficiariesListPage);
