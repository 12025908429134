import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";

export const sessionAndRefSchema = {
    title: i18next.t("beneficiaryForm.sessionsAndReferencesTitle"),
    required:[
        //"id",
        "subject",
        "programmed",
        "startedAt",
        "endedAt",
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t('beneficiaryForm.sessionsAndReferences.id'),
        //     type:"integer",
        // },
        subject:{
            label: i18next.t('beneficiaryForm.sessionsAndReferences.subject'),
            type:"string"
        },
        notes:{
            label: i18next.t('beneficiaryForm.sessionsAndReferences.notes'),
            type:"string"
        },
        programmed:{
            label: i18next.t('beneficiaryForm.sessionsAndReferences.programmed'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        startedAt:{
            label: i18next.t('beneficiaryForm.sessionsAndReferences.startedAt'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        endedAt:{
            label: i18next.t('beneficiaryForm.sessionsAndReferences.endedAt'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        }
    }
};
