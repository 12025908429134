import React from 'react';
import { Form, InputGroup } from "@themesberg/react-bootstrap";
import { BeatLoader } from "react-spinners";
import i18next from "../../translations/i18nConfigInstance";

type Props = {
	listData: Array,
	listPending: boolean,
	value: any,
	onChange: Function,
	showInlineError: boolean,
	errorMessage: string,
	disabled: boolean,
	readOnly: boolean,
	itemLabelFieldName: String,
	useIndexAsValue: boolean
}

class GenericDropdownSelectFieldComponent extends React.Component<Props> {

	render() {
		const {
			listData,
			listPending,
			label,
			value,
			onChange,
			showInlineError,
			errorMessage,
			disabled,
			readOnly,
			itemLabelFieldName,
			useIndexAsValue
		} = this.props;

		return (
			<>
				<InputGroup>
					<InputGroup.Text>
						<BeatLoader
							size={20}
							color={"#4A5073"}
							loading={listPending}
						/>
						{!listPending ? label : null}
					</InputGroup.Text>
					<Form.Select
						readOnly={readOnly}
						required={true}
						onChange={(value) => {
							onChange(value)
						}}
						disabled={disabled}
						value={value}
					//isValid={customFieldsValidationUtils.extractCustomFieldValidationError(outsideUniformFieldsValidationErrors, "residenceType")?.length === 0}
					//isInvalid={customFieldsValidationUtils.extractCustomFieldValidationError(outsideUniformFieldsValidationErrors, "residenceType")?.length > 0}
					>
						<option value={""}>
							{i18next.t('generic.pleaseSelect')}
						</option>

						{listData?.map((item, index) => {
							return <option
								key={item.id}
								value={ 
									useIndexAsValue ? index : item.id
								}
							>

								{
									itemLabelFieldName ? item[itemLabelFieldName] : item?.description
								}
							</option>;
						})}
					</Form.Select>
				</InputGroup>

				{showInlineError &&
					<label className={"text-danger pt-1 pb-1"} style={{
						fontSize: '0.875em'
					}}>
						{errorMessage ? errorMessage : null}
					</label>}
			</>
		)
	}
}

export default GenericDropdownSelectFieldComponent;
