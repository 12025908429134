import opsYtkeApiAxiosInstance from "../OpsYtkeApi";

const urlPath = process.env.REACT_APP_WEB_API_BASE + '/domain';

/**
 * Update or create Beneficiary Form Data
 * @returns
 */
const domainsService = {
	getCorrelationTypes: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/correlationTypes")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getEducationLevels: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/educationLevels")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getEmploymentStatuses: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/employmentStatuses")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getHousingStructTypes: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/housingStructTypes")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getInsuranceCapacityTypes: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/insuranceCapacityTypes")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getResidenceStatuses: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/residenceStatuses")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getResidenceTypes: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/residenceTypes")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getSocialProgramCategories: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/socialProgramCategories")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getSpecialVulnerableCategories: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/specialVulnerableCategories")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getSpecialVulnerableHeadCategories: function () {
		return opsYtkeApiAxiosInstance.get(urlPath + "/specialVulnerableHeadCategories");

	},
	getVehiclesTypes: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/vehiclesTypes")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getApplicationFormTypes: function () {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/applicationFormTypes")
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	}
};

export default domainsService;
