import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";
import {communicationWayKindData} from "../../../dataStatic/beneficiary/communicationWayKindData";

export const communicationWaySchema = {
    title: i18next.t("beneficiaryForm.communicationWaysTitle"),
    required:[
        //"id",
        "value",
        "communicationWayKind",
        "communicationWayType",
        "validFrom",
        "validTo",
        "isMain"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.communicationWays.id"),
        //     type:"integer",
        // },
        value:{
            label: i18next.t("beneficiaryForm.communicationWays.value"),
            type:"string"
        },
        communicationWayKind:{
            label: i18next.t("beneficiaryForm.communicationWays.communicationWayKind"),
            type:"string",
            uniforms: {
                type: "String",
                placeholder: i18next.t("generic.pleaseSelect")
            },
            options: communicationWayKindData
            // enum:[
            //     "EMAIL",
            //     "MOBILE_PHONE",
            //     "PHONE"
            // ]
        },
        communicationWayType: {
            title: i18next.t("beneficiaryForm.communicationWayType"),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t("beneficiaryForm.communicationWays.communicationWayType"),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        },
        validFrom:{
            label: i18next.t("beneficiaryForm.communicationWays.validFrom"),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        validTo:{
            label: i18next.t("beneficiaryForm.communicationWays.validTo"),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        notes:{
            label: i18next.t("beneficiaryForm.communicationWays.notes"),
            type:"string"
        },
        comments:{
            label: i18next.t("beneficiaryForm.communicationWays.comments"),
            type:"string"
        },
        isMain:{
            label: i18next.t("beneficiaryForm.communicationWays.isMain"),
            type:"boolean"
        }
    }
};
