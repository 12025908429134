import React from 'react';
import {CancelFormPromptComponent} from "./components/CancelFormPromptComponent";
import {SaveFormPromptComponent} from "./components/SaveFormPromptComponent";
import {SuccessSaveFormPromptComponent} from "./components/SuccessSaveFormPromptComponent";
import {PendingSaveFormPromptComponent} from "./components/PendingSaveFormPromptComponent";
import {ApproveFormPromptComponent} from "./components/ApproveFormPromptComponent";
import {SuccessApproveFormPromptComponent} from "./components/SuccessApproveFormPromptComponent";
import {RejectFormPromptComponent} from "./components/RejectFormPromptComponent";
import {SuccessRejectFormPromptComponent} from "./components/SuccessRejectFormPromptComponent";
import {SendToFormPromptComponent} from "./components/SendToFormPromptComponent";
import {SuccessSendToFormPromptComponent} from "./components/SuccessSendToFormPromptComponent";

export const FormActionsPromptsModule = ({
                                             showCancelFormPrompt,
                                             showSaveFormPrompt,
                                             showPendingFormPrompt,
                                             showSuccessSaveFormPrompt,
                                             showApproveFormPrompt,
                                             showSuccessApproveFormPrompt,
                                             showRejectFormPrompt,
                                             showSuccessRejectFormPrompt,
                                             showSendToFormPrompt,
                                             showSuccessSendToFormPrompt,
                                             onClosingCancelFormPromptCallback,
                                             onConfirmCancelFormPromptCallback,
                                             onClosingSaveFormPromptCallback,
                                             onConfirmSaveFormPromptCallback,
                                             onClosingSuccessSaveFormPromptCallback,
                                             onClosingApproveFormPromptCallback,
                                             onConfirmApproveFormPromptCallback,
                                             onClosingSuccessApproveFormPromptCallback,
                                             onClosingRejectFormPromptCallback,
                                             onConfirmRejectFormPromptCallback,
                                             onClosingSuccessRejectFormPromptCallback,
                                             onClosingSendToFormPromptCallback,
                                             onConfirmSendToFormPromptCallback,
                                             onClosingSuccessSendToRejectFormPromptCallback,
                                             formIsEditMode,
                                             formIsCreateMode,
											 showAddComments
                                         }) => {
    return (
        <>
            <CancelFormPromptComponent
                showCancelFormPrompt={showCancelFormPrompt}
                cancelActionCallback={onClosingCancelFormPromptCallback}
                confirmActionCallback={onConfirmCancelFormPromptCallback}
            />

            <SaveFormPromptComponent
                showSaveFormPrompt={showSaveFormPrompt}
                cancelActionCallback={onClosingSaveFormPromptCallback}
                confirmActionCallback={onConfirmSaveFormPromptCallback}
            />

            <PendingSaveFormPromptComponent
                showPendingFormPrompt={showPendingFormPrompt}
            />

            <SuccessSaveFormPromptComponent
                showSuccessSaveFormPrompt={showSuccessSaveFormPrompt}
                confirmActionCallback={onClosingSuccessSaveFormPromptCallback}
                isCreateMode={formIsCreateMode}
                isEditMode={formIsEditMode}
            />

            <ApproveFormPromptComponent
                showApproveFormPrompt={showApproveFormPrompt}
                cancelActionCallback={onClosingApproveFormPromptCallback}
                confirmActionCallback={onConfirmApproveFormPromptCallback}
            />

            <SuccessApproveFormPromptComponent
                showSuccessApproveFormPrompt={showSuccessApproveFormPrompt}
                confirmActionCallback={onClosingSuccessApproveFormPromptCallback}
            />

            <RejectFormPromptComponent
                showRejectFormPrompt={showRejectFormPrompt}
                cancelActionCallback={onClosingRejectFormPromptCallback}
                confirmActionCallback={onConfirmRejectFormPromptCallback}
            />

            <SuccessRejectFormPromptComponent
                showSuccessRejectFormPrompt={showSuccessRejectFormPrompt}
                confirmActionCallback={onClosingSuccessRejectFormPromptCallback}
            />

            <SendToFormPromptComponent
                showSendToFormPrompt={showSendToFormPrompt}
                cancelActionCallback={onClosingSendToFormPromptCallback}
                confirmActionCallback={onConfirmSendToFormPromptCallback}
				showAddComments={showAddComments}
            />

            <SuccessSendToFormPromptComponent
                showSuccessSendToFormPrompt={showSuccessSendToFormPrompt}
                confirmActionCallback={onClosingSuccessSendToRejectFormPromptCallback}
            />
        </>
    )
};
