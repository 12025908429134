import React from "react";
import {Card, Col, Row} from '@themesberg/react-bootstrap';
import {BeatLoader} from "react-spinners";
import i18next from "../../translations/i18nConfigInstance";
import AccordionComponent from "../../components/accordionComponent/AccordionComponent";
import GenericFormMenuComponent from "../../modules/genericFormMenuModule/GenericFormMenuModule";
import {withRouter} from 'react-router-dom';
import {Routes} from "../../routing/routes";
import {beneficiaryModel} from "../../model/beneficiary/beneficiaryModel";
import beneficiaryService from "../../_services/beneficiaryServices/beneficiaryService";
import BeneficiaryPersonalDataFormComponent from "./components/beneficiaryGeneralDataFormComponent/BeneficiaryPersonalDataFormComponent";
import AddressesListComponent from "./components/addressesListComponent/AddressesListComponent";
import CommunicationWaysListComponent from "./components/communicationWaysListComponent/CommunicationWaysListComponent";
import LanguagesListComponent from "./components/languagesListComponent/LanguagesListComponent";
import LegalStatesListComponent from "./components/legalStatesListComponent/LegalStatesListComponent";
import EducationFormComponent from "./components/educationFormComponent/EducationFormComponent";
import FamilyDetailsFormComponent from "./components/familyDetailsFormComponent/FamilyDetailsFormComponent";
import FinancesListComponent from "./components/financesListComponent/FinancesListComponent";
import WorksListComponent from "./components/worksListComponent/WorksListComponent";
import HousingStatusesListComponent from "./components/housingStatusesListComponent/HousingStatusesListComponent";
import SessionsAndRefsListComponent from "./components/sessionsAndRefsListComponent/SessionsAndRefsListComponent";
import SocialProgramsToParticipateListComponent
    from "./components/socialProgramsToParticipateDatatable/SocialProgramsToParticipateListComponent";
import SpecialCategoriesListComponent from "./components/specialCategoriesDatatable/SpecialCategoriesListComponent";
import InsuranceCoveragesListComponent
    from "./components/insuranceCoveragesListDatatable/InsuranceCoveragesListComponent";
import userAccessLevelUtil from "../../utils/userAccessLevelUtil";
import ReferralsListComponent from "./components/referralsListComponent/ReferralsListComponent";

class BeneficiaryFormCreateUpdatePage extends React.Component {

    _isBeneficiaryUser = userAccessLevelUtil.isBeneficiaryUser();
    _isBackOfficerUser = userAccessLevelUtil.isBackOfficerUser();

    // Reference to AutoForm form property for validate/submit operations
    _beneficiaryGeneralDataFormRef = null;
    _beneficiaryEducationDataFormRef = null;

    // Our form state data
    state = {
        userIdFromUrlParams: null,

        // Network data state
        pendingGettingRecord: false,
        errorGettingRecord: false,
        recordData: null,
        originalRecordData: null,

        // form error's state handling
        beneficiaryGeneralDataValidationFormErrorCounter: null,
        beneficiaryEducationValidationFormErrorCounter: null,
    };

    componentDidMount(): void {
        const {
            // Form State
            isCreateMode,
            isEditMode,
            isViewMode,
            urlParams
        } = this.props;

        // We fetch the URL Param like this
        // from urlParams provided by parent component genericFormModule
        const {
            userId
        } = urlParams;

        this.setState({
            userIdFromUrlParams: userId
        });

        if (isEditMode || isViewMode) {
            this.fetchBeneficiaryFormData(userId)
        } else if (isCreateMode) {
            this.setState({
                recordData: beneficiaryModel,
                originalRecordData: beneficiaryModel,
            })
        }
    }

    fetchBeneficiaryFormData = (userId) => {
        this.setState({
            pendingGettingRecord: true,
        });

        beneficiaryService.getSingle(userId)
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {
                this.setState({
                    pendingGettingRecord: false,
                    errorGettingRecord: false,
                    recordData: response,
                    originalRecordData: response,
                })
            })
            .catch((error) => {
                console.log("Error getting Beneficiary Data: ", error);
                this.setState({
                    pendingGettingRecord: false,
                    errorGettingRecord: true
                })
            });
    };

    /** UNIFORM FORM'S REFERENCES START **/
    registerBeneficiaryGeneralDataUniformRef = (ref) => {
        this._beneficiaryGeneralDataFormRef = ref;
    };

    registerBeneficiaryEducationUniformRef = (ref) => {
        this._beneficiaryEducationDataFormRef = ref;
    };

    /** UNIFORM FORM'S REFERENCES END **/

    /** FORM UPDATE DATA / SETTERS START **/
    updateParentModel = (model) => {
        // console.log("UPDATED Beneficiary MODEL FROM ROOT", model);
        this.setState({
            recordData: model
        })
    };
    /** FORM UPDATE DATA / SETTERS END **/

    validateForm = async () => {
        return new Promise((resolve, reject) => {
            const {
                // Form State
                isCreateMode,
                isEditMode,
                isViewMode,
            } = this.props;

            this.setState({
                beneficiaryGeneralDataValidationFormErrorCounter: null,
                beneficiaryEducationValidationFormErrorCounter: null,
            });

            const {
                recordData,
            } = this.state;


            const formPromisesArray = [
                this._beneficiaryGeneralDataFormRef.submit(),
                this._beneficiaryEducationDataFormRef.submit()
            ];

            // Promise.allSettled returns the results of EVERY promise (rejected/resolved)
            // in the order they are imported for settle
            Promise.allSettled(formPromisesArray)
                .then((promisesResults) => {

                    let validationFailed = false;

                    // Catch validation errors for each form
                    // as Promise.allSettled returns
                    for (let i = 0; i < formPromisesArray.length; i++) {
                        if (promisesResults[i].status === 'rejected') {

                            validationFailed = true;

                            if (i === 0) {
                                this.setState({
                                    beneficiaryGeneralDataValidationFormErrorCounter: promisesResults[i].reason?.details?.length,
                                });
                            }

                            if (i === 1) {
                                this.setState({
                                    beneficiaryEducationValidationFormErrorCounter: promisesResults[i].reason?.details?.length,
                                });
                            }
                        }
                    }

                    if (!validationFailed) {
                        const modelToBeSent = Object.assign({}, recordData);
                        resolve(modelToBeSent);
                    } else {
                        console.log("Uniform Validation Errors", formPromisesArray);
                        reject("Validation Failed");
                    }
                });
        })
    };

    updateGeneralDataModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        tempModel = Object.assign({}, freshData);
        this.updateParentModel(tempModel);
    };

    updateAddressesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.addresses) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['addresses'] = [];
        }
        tempModel.addresses = freshData;
        this.updateParentModel(tempModel);
    };

    updateCommunicationWaysListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.addresses) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['communicationWays'] = [];
        }
        tempModel.communicationWays = freshData;
        this.updateParentModel(tempModel);
    };

    updateLanguagesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.languages) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['languages'] = [];
        }
        tempModel.languages = freshData;
        this.updateParentModel(tempModel);
    };

    updateLegalStatesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.legalStates) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['legalStates'] = [];
        }
        tempModel.legalStates = freshData;
        this.updateParentModel(tempModel);
    };

    updateEducationModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        tempModel = Object.assign({}, recordData);
        tempModel.education = freshData;
        this.updateParentModel(tempModel);
    };

    updateFamilyDetailsModel = (freshData) => {
        const {
            recordData
        } = this.state;
        const tempModel = Object.assign({}, recordData);
        tempModel.familyDetails = freshData;
        this.updateParentModel(tempModel);
    };

    updateFinancesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.finances) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['finances'] = [];
        }
        tempModel.finances = freshData;
        this.updateParentModel(tempModel);
    };

    updateWorksListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.works) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['works'] = [];
        }
        tempModel.works = freshData;
        this.updateParentModel(tempModel);
    };

    updateHousingStatusesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.housingStatuses) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['housingStatuses'] = [];
        }
        tempModel.housingStatuses = freshData;
        this.updateParentModel(tempModel);
    };

    updateSessionsAndRefsListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.sessionsAndReferences) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['sessionsAndReferences'] = [];
        }
        tempModel.sessionsAndReferences = freshData;
        this.updateParentModel(tempModel);
    };


	updatereferralsCollectionModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.referralsCollection) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['referralsCollection'] = [];
        }
        tempModel.referralsCollection = freshData;
        this.updateParentModel(tempModel);
    };

	

    updateSocialProgramsToParticipateListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.socialProgramsToParticipate) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['socialProgramsToParticipate'] = [];
        }
        tempModel.socialProgramsToParticipate = freshData;
        this.updateParentModel(tempModel);
    };

    updateSpecialCategoriesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.specialCategories) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['specialCategories'] = [];
        }
        tempModel.specialCategories = freshData;
        this.updateParentModel(tempModel);
    };

    updateInsuranceCoveragesListModel = (freshData) => {
        let tempModel = {};
        const {
            recordData
        } = this.state;
        if (recordData.insuranceCoverages) {
            tempModel = Object.assign({}, recordData);
        } else {
            tempModel['insuranceCoverages'] = [];
        }
        tempModel.insuranceCoverages = freshData;
        this.updateParentModel(tempModel);
    };

    approveCallback = () => {
        const {
            recordData
        } = this.state;

        return new Promise((resolve, reject) => {
            beneficiaryService.approveBeneficiaryRegistrationForm(recordData?.id)
                .then((response) => {
                   resolve(response)
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


    render() {
        const {
            userIdFromUrlParams,

            // Network data state
            recordData,
            pendingGettingRecord,
            errorGettingRecord,

            // form error's state handling
            beneficiaryValidationFormErrorCounter,
            beneficiaryGeneralDataValidationFormErrorCounter,
            beneficiaryEducationValidationFormErrorCounter,
        } = this.state;

        const {
            // Form State
            isCreateMode,
            isEditMode,
            isViewMode,
        } = this.props;

        return (
            <Row className="justify-content-md-center">

                <GenericFormMenuComponent
                    isSaveButtonDisabled={isViewMode || pendingGettingRecord}
                    onValidateCallback={this.validateForm}
                    saveService={isEditMode ? beneficiaryService.update : isCreateMode ? beneficiaryService.create : null}
                    formIsEditMode={isEditMode}
                    formIsCreateMode={isCreateMode}
                    navigationUrl={Routes.BeneficiaryListPageRoute.path}
                    showApproveButton={this._isBackOfficerUser && recordData?.approved === false}
                    isApproveButtonDisabled={isViewMode || pendingGettingRecord}
                    approveService={this.approveCallback}
                />

                <Row>
                    <Col xs={12}>
                        <Card border="light" className="customSectionHeaderStyle shadow-sm mb-4">
                            <Card.Body>
                                <h4 style={{color: "#fefefe"}}>
                                    {(isEditMode
                                        ? i18next.t('beneficiaryForm.page.editFormTitle') + " " + userIdFromUrlParams
                                        : isViewMode
                                            ? i18next.t('beneficiaryForm.page.viewFormTitle') + userIdFromUrlParams
                                            : i18next.t('beneficiaryForm.page.newFormTitle') + " " + userIdFromUrlParams)
                                    }
                                </h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <>
                    {pendingGettingRecord &&
                    <Row>
                        <Col xs={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <div className="d-flex align-content-center justify-content-center pt-5">
                                        <BeatLoader
                                            size={35}
                                            color={"#4A5073"}
                                            loading={pendingGettingRecord}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>}
                    {!pendingGettingRecord && recordData &&
                    <>
                        <BeneficiaryPersonalDataFormComponent
                            updateModelParentCallback={this.updateGeneralDataModel}
                            registerUniformRefCallback={this.registerBeneficiaryGeneralDataUniformRef}
                            isEditMode={isEditMode}
                            isViewMode={isViewMode}
                            isCreateMode={isCreateMode}
                            recordData={recordData}
                        />

                        <Row>
                            <Col xs={12}>
                                <div>
                                    <AccordionComponent
                                        className="accordion-dark"
                                        defaultKey={i18next.t("beneficiaryForm.addressesTitle")}
                                        data={
                                            [
                                                {
                                                    id: i18next.t("beneficiaryForm.addressesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.addressesTitle"),
                                                    title: i18next.t("beneficiaryForm.addressesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <AddressesListComponent
                                                            dataArray={recordData?.addresses}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateAddressesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.communicationWaysTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.communicationWaysTitle"),
                                                    title: i18next.t("beneficiaryForm.communicationWaysTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <CommunicationWaysListComponent
                                                            dataArray={recordData?.communicationWays}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateCommunicationWaysListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.languagesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.languagesTitle"),
                                                    title: i18next.t("beneficiaryForm.languagesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <LanguagesListComponent
                                                            dataArray={recordData?.languages}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateLanguagesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.legalStatesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.legalStatesTitle"),
                                                    title: i18next.t("beneficiaryForm.legalStatesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <LegalStatesListComponent
                                                            dataArray={recordData?.legalStates}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateLegalStatesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.educationTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.educationTitle"),
                                                    title: i18next.t("beneficiaryForm.educationTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <EducationFormComponent
                                                            updateModelParentCallback={this.updateEducationModel}
                                                            registerUniformRefCallback={this.registerBeneficiaryEducationUniformRef}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            recordData={recordData?.education}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.familyDetailsTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.familyDetailsTitle"),
                                                    title: i18next.t("beneficiaryForm.familyDetailsTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <FamilyDetailsFormComponent
                                                            recordData={recordData?.familyDetails}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateFamilyDetailsModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.insuranceCoveragesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.insuranceCoveragesTitle"),
                                                    title: i18next.t("beneficiaryForm.insuranceCoveragesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <InsuranceCoveragesListComponent
                                                            dataArray={recordData?.insuranceCoverages}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateInsuranceCoveragesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.worksTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.worksTitle"),
                                                    title: i18next.t("beneficiaryForm.worksTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <WorksListComponent
                                                            dataArray={recordData?.works}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateWorksListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.financesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.financesTitle"),
                                                    title: i18next.t("beneficiaryForm.financesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <FinancesListComponent
                                                            dataArray={recordData?.finances}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateFinancesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.housingStatusesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.housingStatusesTitle"),
                                                    title: i18next.t("beneficiaryForm.housingStatusesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <HousingStatusesListComponent
                                                            dataArray={recordData?.housingStatuses}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateHousingStatusesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.specialCategoriesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.specialCategoriesTitle"),
                                                    title: i18next.t("beneficiaryForm.specialCategoriesTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <SpecialCategoriesListComponent
                                                            dataArray={recordData?.specialCategories}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateSpecialCategoriesListModel}
                                                        />
                                                },
                                                {
                                                    id: i18next.t("beneficiaryForm.socialProgramsToParticipateTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.socialProgramsToParticipateTitle"),
                                                    title: i18next.t("beneficiaryForm.socialProgramsToParticipateTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <SocialProgramsToParticipateListComponent
                                                            dataArray={recordData?.socialProgramsToParticipate}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateSocialProgramsToParticipateListModel}
                                                        />
                                                },
                                                // {
                                                //     id: i18next.t("beneficiaryForm.sessionsAndReferencesTitle"),
                                                //     eventKey: i18next.t("beneficiaryForm.sessionsAndReferencesTitle"),
                                                //     title: i18next.t("beneficiaryForm.sessionsAndReferencesTitle"),
                                                //     subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                //     component:
                                                //         <SessionsAndRefsListComponent
                                                //             dataArray={recordData?.sessionsAndReferences}
                                                //             isEditMode={isEditMode}
                                                //             isViewMode={isViewMode}
                                                //             isCreateMode={isCreateMode}
                                                //             updateModelParentCallback={this.updateSessionsAndRefsListModel}
                                                //         />
                                                // },
												{
                                                    id: i18next.t("beneficiaryForm.sessionsTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.sessionsTitle"),
                                                    title: i18next.t("beneficiaryForm.sessionsTitle"),
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    hide: !this._isBackOfficerUser,
													component:
                                                        <SessionsAndRefsListComponent
                                                            dataArray={recordData?.sessionsAndReferences}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updateSessionsAndRefsListModel}
                                                        />
                                                },
												{
                                                    id: i18next.t("beneficiaryForm.referencesTitle"),
                                                    eventKey: i18next.t("beneficiaryForm.referencesTitle"),
                                                    title: i18next.t("beneficiaryForm.referencesTitle"),
													hide: !this._isBackOfficerUser,
                                                    subtitle: beneficiaryValidationFormErrorCounter ? i18next.t(beneficiaryValidationFormErrorCounter === 1 ? "generic.errorFound" : "generic.errorsFound", {errorNumber: beneficiaryValidationFormErrorCounter}) : null,
                                                    component:
                                                        <ReferralsListComponent
                                                            dataArray={recordData?.referralsCollection}
                                                            isEditMode={isEditMode}
                                                            isViewMode={isViewMode}
                                                            isCreateMode={isCreateMode}
                                                            updateModelParentCallback={this.updatereferralsCollectionModel}
                                                        />
                                                },
                                            ]
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>}
                </>
            </Row>
        );
    }

}

export default withRouter(BeneficiaryFormCreateUpdatePage);
