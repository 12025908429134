import { Col, Row } from '@themesberg/react-bootstrap';
import React from 'react';
import applicationFormService from '_services/beneficiaryServices/applicationFormService';
import axios from 'axios';
import authHeader from '_services/authorization/authHeader';
import i18next from 'i18next';
import { addNotificationService } from 'components/notificationComponent/NotificationsService';

const urlPath = process.env.REACT_APP_WEB_API_BASE + '/applicationForm';


class Attachment extends React.Component {

	state = {
		files: null,
		selectedFile: null
	};

	componentDidMount() {
		if (typeof this.props.applicationFormId !== "undefined" && this.props.applicationFormId !== null && this.props.applicationFormId !== "") {
			applicationFormService.getFile(this.props.applicationFormId)
				.then((res) => {
					this.setState({
						files: res.data
					})
				}).catch((error) => {
					console.log(error)
				});
		}
	}

	handleSubmit =(event) => {
		const formData = new FormData();
		formData.append("filedata", event.target.files[0]);
		try {
			axios({
				method: "post",
				url: urlPath + "/addApplicationFormFile/" + this.props.applicationFormId,
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: authHeader().Authorization
				}
			})
				.then((res) => {
					if (typeof this.props.applicationFormId !== "undefined" && this.props.applicationFormId !== null && this.props.applicationFormId !== "") {
						applicationFormService.getFile(this.props.applicationFormId)
							.then((res) => {
								this.setState({
									files: res.data
								})
							}).catch((error) => {
								console.log(error)
							});
					}
				}).catch((error) => {
					addNotificationService(i18next.t("networking.400RangeHttpError"), i18next.t("applicationForm.attachedFileExists"), "warning");
				});

		} catch (error) {
			console.log(error)
		}
	}

	handleFileSelect = (event) => {
		this.setState({
			selectedFile: event.target.files[0]
		});
	}

	downloadEmployeeData = (url, fileName, ext) => {
		fetch(url)
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = fileName + "." + ext;
					a.click();
				});
			});
	}


	render() {
		const {
			applicationFormId,
			isFormEditDisabled
		} = this.props;

		return (
			<>
				{applicationFormId &&
					<>
						{(!this.state.files || this.state.files.length < 1) && isFormEditDisabled !== true &&
							<Row>
								<Col xs={3}>
									<div className='m-1'>
										{i18next.t('applicationForm.attachFile')}
									</div>

								</Col>
								<Col xs={9}>
									<input id='filePicker' type="file" onChange={this.handleSubmit} />
								</Col>
							</Row>
						}
						{this.state.files && this.state.files.length > 0 &&
							<Row>
								<Col xs={3}>
									<div className='m-1'>
										{i18next.t('applicationForm.attachedFile')}
									</div>

								</Col>
								<Col xs={9}>
									<div className='m-1 btn btn-primary' onClick={() => this.downloadEmployeeData(urlPath + "/getFile/" + this.state.files[0].alfrescoId, this.state.files[0].fileName, this.state.files[0].fileExt)}>
										{i18next.t('applicationForm.download')}
									</div>
								</Col>
							</Row>
						}
					</>
				}
			</>
		)
	}
}

export default Attachment;