export const CommunicationWayModel = {
    id: null,
    value: null,
    communicationWayKind: null,
    isMain: false,
    validFrom: "2021-06-27T21:00:00.000+00:00",
    validTo: "2021-06-27T21:00:00.000+00:00",
    comments: "",
    notes: "",
    communicationWayType: {
        id: null,
        description: null
    }
}
