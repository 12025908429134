import React from 'react';
import TableFurtherActionsComponent from "../../../../../components/genericTableComponent/TableFurtherActionsComponent";
import { convertDateToLocaleDateTime } from "../../../../../utils/dateTimeUtils";
import i18next from "../../../../../translations/i18nConfigInstance";

type Props = {
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    onViewRowItemFormCallback: Function,
    onEditRowItemFormCallback: Function,
    onDeleteRowItemFormCallback: Function,
}

class AddressTableRowComp extends React.Component<Props> {

    onViewActionPress = (objectData) => {
        const {
            rowIndex,
            onViewRowItemFormCallback
        } = this.props;
        onViewRowItemFormCallback(objectData, rowIndex);
    };

    onEditActionPress = (objectData) => {
        const {
            rowIndex,
            onEditRowItemFormCallback
        } = this.props;
        onEditRowItemFormCallback(objectData, rowIndex);
    };

    onDeleteActionPress = (objectData) => {
        const {
            rowIndex,
            onDeleteRowItemFormCallback
        } = this.props;
        onDeleteRowItemFormCallback(objectData, rowIndex);
    };

    render() {
        const {
            isViewMode,
            isEditMode,
            isCreateMode,
            recordData: {
                id,
                state,
                municipality,
                address,
                addressNumber,
                zipCode,
                validFrom,
                validTo,
                notes,
                comments,
                residenceType: {
                    description,
                },
                main,
            }
        } = this.props;

        return (
            <tr>
                <td>
                    <span className="fw-normal">
                        {id}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {state}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {municipality}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {address}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {addressNumber}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {zipCode}
                    </span>
                </td>
                {/* <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(validFrom)}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(validTo)}
                  </span>
                </td> */}
                <td>
                    <span className="fw-normal">
                        {notes}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {comments}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {description}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {main ? i18next.t('generic.yes') : i18next.t('generic.no')}
                    </span>
                </td>

                <td>
                    <TableFurtherActionsComponent
                        isViewDisabled={false}
                        isEditDisabled={isViewMode}
                        isDeleteDisabled={isViewMode}
                        viewRowItemCallback={() => this.onViewActionPress(this.props.recordData)}
                        editRowItemCallback={() => this.onEditActionPress(this.props.recordData)}
                        deleteRowItemCallback={() => this.onDeleteActionPress(this.props.recordData)}
                    />
                </td>
            </tr>
        )
    }
}

export default AddressTableRowComp;
