import opsYtkeApiAxiosInstance from "../OpsYtkeApi";

const urlPath = process.env.REACT_APP_WEB_API_BASE + '/user';

/**
 * Update or create Beneficiary Form Data
 * @returns
 */
const beneficiaryService = {
    getList: function (page = 0, size = 5, userModel) {
        let criteriaBuilder = {};

        if (userModel != null) {
            criteriaBuilder = Object.assign({}, userModel);
        }

        return new Promise((resolve, reject) => {
            opsYtkeApiAxiosInstance.post(
                urlPath + "/search",
                criteriaBuilder,
                {
                    params: {
                        page: page,
                        size: size,
                    }
                }
            )
                .then((res) => {
                    const mappedResponse = res.data;
                    resolve(mappedResponse);
                }).catch((error) => {
                reject(error);
            });
        });
    },
    getSingle: function (userId) {
        return new Promise((resolve, reject) => {
            opsYtkeApiAxiosInstance.get(urlPath + "/" + userId)
                .then((res) => {
                    const mappedResponse = res.data;
                    resolve(mappedResponse);
                }).catch((error) => {
                reject(error);
            });
        });
    },
    update: function (userModel) {
        return new Promise((resolve, reject) => {
            opsYtkeApiAxiosInstance.put(
                urlPath,
                userModel
            ).then((res) => {
                const mappedResponse = res.data;
                resolve(mappedResponse);
            })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    create: function (userModel) {
        return new Promise((resolve, reject) => {
            opsYtkeApiAxiosInstance.post(
                urlPath,
                userModel
            ).then((res) => {
                const mappedResponse = res.data;
                resolve(mappedResponse);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    approveBeneficiaryRegistrationForm: function (beneficiaryId) {
        return new Promise((resolve, reject) => {
            opsYtkeApiAxiosInstance.get(
                "/userManagement/approve/" + beneficiaryId)
                .then((res) => {
                    const mappedResponse = res.data;
                    resolve(mappedResponse);
                }).catch((error) => {
                reject(error);
            });
        });
    },
};

export default beneficiaryService;
