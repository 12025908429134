import i18next from "../../../translations/i18nConfigInstance";
import { UniformsMaterialDatePicker } from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";

export const addressSchema = {
    title: i18next.t("beneficiaryForm.addressesTitle"),
    required: [
        //"id",
        "state",
        "municipality",
        "address",
        "addressNumber",
        "zipCode"
    ],
    type: "object",
    properties: {
        // id:{
        //     label: i18next.t("beneficiaryForm.addresses.id"),
        //     type:"integer",
        // },
        state: {
            label: i18next.t("beneficiaryForm.addresses.state"),
            type: "string"
        },
        municipality: {
            label: i18next.t("beneficiaryForm.addresses.municipality"),
            type: "string"
        },
        address: {
            label: i18next.t("beneficiaryForm.addresses.address"),
            type: "string"
        },
        addressNumber: {
            label: i18next.t("beneficiaryForm.addresses.addressNumber"),
            type: "string"
        },
        zipCode: {
            label: i18next.t("beneficiaryForm.addresses.zipCode"),
            type: "string"
        },
        // validFrom: {
        //     label: i18next.t("beneficiaryForm.addresses.validFrom"),
        //     type: "string",
        //     require: false,
        //     uniforms: {
        //         component: UniformsMaterialDatePicker
        //     }
        // },
        // validTo: {
        //     label: i18next.t("beneficiaryForm.addresses.validTo"),
        //     type: "string",
        //     uniforms: {
        //         component: UniformsMaterialDatePicker
        //     }
        // },
        notes: {
            label: i18next.t("beneficiaryForm.addresses.notes"),
            type: "string"
        },
        comments: {
            label: i18next.t("beneficiaryForm.addresses.comments"),
            type: "string"
        },
        residenceType: {
            title: i18next.t("beneficiaryForm.residenceType"),
            required: [
                "id"
            ],
            type: "object",
            properties: {
                id: {
                    label: i18next.t("beneficiaryForm.addresses.residenceType"),
                    type: "integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        },
        main: {
            label: i18next.t("beneficiaryForm.addresses.main"),
            type: "boolean"
        }
    }
};
