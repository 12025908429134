export const FinanceModel = {
    id: null,
    income: 0,
    allowances: 0,
    otherSources: 0,
    valueRealEstate: 0,
    valueMovableProperty: 0,
    validFrom: "2021-06-27T21:00:00.000+00:00",
    validTo: "2021-06-27T21:00:00.000+00:00",
    comments: "",
    notes: "",
    vehicleType: {
        id: null,
        description: null
    }
}
