import React from 'react';
import GenericFormMenuComponent from "../../modules/genericFormMenuModule/GenericFormMenuModule";
import { Routes } from "../../routing/routes";
import { Card, Col, Form, Row } from "@themesberg/react-bootstrap";
import i18next from "../../translations/i18nConfigInstance";
import { BeatLoader } from "react-spinners";
import userAccessLevelUtil from "../../utils/userAccessLevelUtil";
import applicationFormService from "../../_services/beneficiaryServices/applicationFormService";
import { beneficiaryApplicationFormModel } from "../../model/beneficiaryApplicationForm/BeneficiaryApplicationFormModel";
import domainsService from "../../_services/domainsServices/domainsService";
import { WidgetWithAvatarComponent } from "../../components/WidgetWithAvatar/WidgetWithAvatarComponent";
import pendingAnimation from "../../assets/lottieAnimations/statusAnimations/41420-pending.json";
import approvedAnimation from "../../assets/lottieAnimations/statusAnimations/63268-success-lottie-icons.json";
import rejectedAnimation
	from "../../assets/lottieAnimations/statusAnimations/33273-failure.json";
import sentBackToBeneficiaryAnimation
	from "../../assets/lottieAnimations/statusAnimations/61228-back-arrow.json";
import sentBackToBackOfficerAnimation
	from "../../assets/lottieAnimations/statusAnimations/68168-send-mail.json";
import GenericBeneficiaryDetailsComponent
	from "../../components/genericBeneficiaryDetailsComponent/GenericBeneficiaryDetailsComponent";
import { statusTitle } from "../../utils/applicationFormStatusUtil";
import Attachment from 'modules/attachment/Attachment';

class BeneficiaryApplicationFormPage extends React.Component {

	_isBeneficiaryUser = userAccessLevelUtil.isBeneficiaryUser();
	_isBackOfficerUser = userAccessLevelUtil.isBackOfficerUser();

	// Our form state data
	state = {
		applicationFormIdFromUrlParams: null,

		// Network data state
		pendingGettingRecord: false,
		errorGettingRecord: false,
		recordData: null,
		originalRecordData: null,

		// Application Form Types State
		pendingGettingApplicationFormTypes: false,
		errorGettingApplicationFormTypes: false,
		applicationFormTypes: null,
	};

	componentDidMount(): void {
		const {
			// Form State
			isCreateMode,
			isEditMode,
			isViewMode,
			urlParams
		} = this.props;

		// We fetch the URL Param like this
		// from urlParams provided by parent component genericFormModule
		const {
			applicationFormID
		} = urlParams;

		this.setState({
			applicationFormIdFromUrlParams: applicationFormID
		});

		if (isEditMode || isViewMode) {
			this.fetchBeneficiaryApplicationFormData(applicationFormID)
		} else if (isCreateMode) {
			this.setState({
				recordData: beneficiaryApplicationFormModel,
				originalRecordData: beneficiaryApplicationFormModel,
			})
		}

		this.fetchApplicationFormTypesList();
	}

	fetchBeneficiaryApplicationFormData = (applicationFormId) => {
		this.setState({
			pendingGettingRecord: true,
		});

		applicationFormService.getSingle(applicationFormId)
			// In success we split model to master/details
			// so that we can easily handle them on separated forms
			.then((response) => {
				this.setState({
					pendingGettingRecord: false,
					errorGettingRecord: false,
					recordData: response,
					originalRecordData: response,
				})
			})
			.catch((error) => {
				console.log("Error getting Beneficiary Application Form Data: ", error);
				this.setState({
					pendingGettingRecord: false,
					errorGettingRecord: true
				})
			});
	}

	fetchApplicationFormTypesList = () => {
		this.setState({
			pendingGettingApplicationFormTypes: true,
		});

		domainsService.getApplicationFormTypes()
			.then((response) => {
				this.setState({
					pendingGettingApplicationFormTypes: false,
					errorGettingApplicationFormTypes: false,
					applicationFormTypes: response,
				})
			})
			.catch((error) => {
				console.log("Error getting Application Form Types List  records ", error);
				this.setState({
					pendingGettingApplicationFormTypes: false,
					errorGettingApplicationFormTypes: true,
				})
			});
	};

	validateForm = async () => {
		return new Promise((resolve, reject) => {

			let validationSucceed = false;

			const {
				recordData
			} = this.state;

			// TODO Do the validation checks

			if (1 === 1) {
				validationSucceed = true;
			}

			if (validationSucceed) {
				resolve(recordData);
			} else {
				console.log("Beneficiary Application Form Validation Errors");
				reject("Validation Failed");
			}
		});
	}

	setApplicationFormType = (event) => {
		const {
			recordData
		} = this.state;

		const tmp = Object.assign({}, recordData);
		tmp.applicationFormType.id = parseInt(event.target.value);

		this.setState({
			recordData: tmp
		});
	};

	setNotes = (event) => {
		const {
			recordData
		} = this.state;

		const tmp = Object.assign({}, recordData);
		tmp.notes = event.target.value;

		this.setState({
			recordData: tmp
		});
	}

	setComments = (event) => {
		const {
			recordData
		} = this.state;

		const tmp = Object.assign({}, recordData);
		tmp.comments = event.target.value;

		this.setState({
			recordData: tmp
		});
	}

	approveCallback = () => {
		const {
			recordData
		} = this.state;

		return new Promise((resolve, reject) => {
			applicationFormService.approveBeneficiaryApplicationForm(recordData?.id)
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	rejectCallback = () => {
		const {
			recordData
		} = this.state;

		return new Promise((resolve, reject) => {
			applicationFormService.rejectBeneficiaryApplicationForm(recordData?.id)
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	backOfficerSendToBeneficiaryCallback = (comments) => {
		const {
			recordData
		} = this.state;

		return new Promise((resolve, reject) => {
			applicationFormService.sendToBeneficiaryApplicationForm(recordData?.id, comments)
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	beneficiarySendToBackOfficerCallback = () => {
		const {
			recordData
		} = this.state;

		return new Promise((resolve, reject) => {
			applicationFormService.sendToBackOfficerApplicationForm(recordData?.id)
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	render() {

		const {
			applicationFormIdFromUrlParams,

			// Network data state
			recordData,
			pendingGettingRecord,
			errorGettingRecord,

			// Application Form Types State
			pendingGettingApplicationFormTypes,
			errorGettingApplicationFormTypes,
			applicationFormTypes,
		} = this.state;

		const {
			// Form State
			isCreateMode,
			isEditMode,
			isViewMode,
		} = this.props;

		const isFormEditDisabled = isViewMode || pendingGettingRecord;

		return (
			<Row className="justify-content-md-center">

				<GenericFormMenuComponent
					isSaveButtonDisabled={isFormEditDisabled}
					onValidateCallback={this.validateForm}
					saveService={isEditMode ? applicationFormService.update : isCreateMode ? applicationFormService.create : null}
					formIsEditMode={isEditMode}
					formIsCreateMode={isCreateMode}
					navigationUrl={Routes.BeneficiaryApplicationFormsListPageRoute.path}
					showApproveButton={this._isBackOfficerUser && (recordData?.status === 0 || recordData?.status === 4)}
					isApproveButtonDisabled={pendingGettingRecord}
					approveService={this.approveCallback}
					showRejectButton={this._isBackOfficerUser && (recordData?.status === 0 || recordData?.status === 4)}
					isRejectButtonDisabled={pendingGettingRecord}
					rejectService={this.rejectCallback}
					showSendToButton={(this._isBackOfficerUser && (recordData?.status === 0 || recordData?.status === 4)) || (this._isBeneficiaryUser && recordData?.status === 3)}
					isSendToButtonDisabled={pendingGettingRecord}
					sendToService={this._isBackOfficerUser ? this.backOfficerSendToBeneficiaryCallback : this._isBeneficiaryUser ? this.beneficiarySendToBackOfficerCallback : null}
					showAddComments={this._isBackOfficerUser ? true : false}
					sendToButtonTitle={this._isBackOfficerUser ? i18next.t("generic.sendToBeneficiary") : this._isBeneficiaryUser ? i18next.t("generic.sendToBackOfficer") : ''}
				/>

				<Row>
					<Col xs={12}>
						<Card border="light" className="customSectionHeaderStyle shadow-sm mb-4">
							<Card.Body>
								<h4 style={{ color: "#fefefe" }}>
									{(isEditMode
										? i18next.t('applicationForm.page.editFormTitle') + " " + applicationFormIdFromUrlParams
										: isViewMode
											? i18next.t('applicationForm.page.viewFormTitle') + " " + applicationFormIdFromUrlParams
											: i18next.t('applicationForm.page.newFormTitle'))
									}
								</h4>
							</Card.Body>
						</Card>
					</Col>
				</Row>

				<>
					{pendingGettingRecord &&
						<Row>
							<Col xs={12}>
								<Card border="light" className="bg-white shadow-sm mb-4">
									<Card.Body>
										<div className="d-flex align-content-center justify-content-center pt-5">
											<BeatLoader
												size={35}
												color={"#4A5073"}
												loading={pendingGettingRecord}
											/>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
					{!pendingGettingRecord && recordData &&
						<>

							{this._isBackOfficerUser &&
								<Row className={"mb-2"}>
									<Col xs={12}>
										<GenericBeneficiaryDetailsComponent
											firstName={recordData?.user?.firstName}
											lastName={recordData?.user?.lastName}
											amka={recordData?.user?.amka}
											afm={recordData?.user?.afm}
											title={i18next.t('applicationForm.page.beneficiaryDetails')}
										/>
									</Col>
								</Row>}

							<Row>
								<Col xs={12}>
									<Card border="light" className="shadow-sm mb-4 mt-1">
										<Card.Body>

											<Row>
												<Col xs={12} xl={8}>
													<Row className="mt-1 mb-3">
														<Col xs={12} xl={8}>
															<Form.Group>
																<Form.Label>{i18next.t('applicationForm.type')}</Form.Label>
																<Form.Select
																	disabled={isFormEditDisabled || pendingGettingApplicationFormTypes || recordData?.status}
																	defaultValue={''}
																	value={recordData?.applicationFormType?.id}
																	onChange={this.setApplicationFormType}
																>
																	<option
																		value={''}>{i18next.t('generic.pleaseSelect')}</option>
																	{applicationFormTypes && applicationFormTypes.map(appFormType =>
																		<option key={appFormType.id}
																			value={appFormType.id}>{appFormType.description}</option>
																	)};
																</Form.Select>
															</Form.Group>
														</Col>
													</Row>

													<Row className="mt-1 mb-1">
														<Col xs={12} xl={12}>
															<Form.Group className="mb-3">
																<Form.Label>{i18next.t('applicationForm.notes')}</Form.Label>
																<Form.Control
																	as="textarea"
																	rows="3"
																	disabled={isFormEditDisabled}
																	value={recordData?.notes}
																	onChange={this.setNotes}
																/>
															</Form.Group>
														</Col>
													</Row>

													<Row className="mt-1 mb-1">
														<Col xs={12} xl={12}>
															<Form.Group className="mb-3">
																<Form.Label>{i18next.t('applicationForm.comments')}</Form.Label>
																<Form.Control
																	as="textarea"
																	rows="20"
																	disabled={(isFormEditDisabled || recordData?.status === 3)}
																	value={recordData?.comments}
																	onChange={this.setComments}
																/>
															</Form.Group>
														</Col>
													</Row>
												</Col>

												<Col xs={12} xl={4}>
													<Row>
														<Col xs={12}>
															<WidgetWithAvatarComponent
																title={statusTitle(recordData?.status)}
																lottieAnimationSourceFile={recordData?.status === 0 ? pendingAnimation : recordData?.status === 1 ? approvedAnimation : recordData?.status === 2 ? rejectedAnimation : recordData?.status === 3 ? sentBackToBeneficiaryAnimation : recordData?.status === 4 ? sentBackToBackOfficerAnimation : null}
																//lottieAnimationSourceFile={recordData?.approved === true ? approvedAnimation : rejectedAnimation}
																animationWidth={130}
																animationHeight={130}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
											<Attachment applicationFormId={applicationFormIdFromUrlParams} isFormEditDisabled={isFormEditDisabled}></Attachment>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							
						</>}
					
				</>

			</Row>
		)
	}
}

export default BeneficiaryApplicationFormPage;
