import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";
import {genderData} from "../../../dataStatic/beneficiary/genderData";

export const affiliatedMemberSchema = {
    title: i18next.t("beneficiaryForm.communicationWaysTitle"),
    required:[
        //"id",
        "amka",
        "afm",
        "lastName",
        "firstName",
        "gender",
        "correlationType",
        "birthDate",
        "alive",
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.communicationWays.id"),
        //     type:"integer",
        // },
        amka:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.amka'),
            type:"string"
        },
        afm:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.afm'),
            type:"string"
        },
        lastName:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.lastName'),
            type:"string"
        },
        firstName:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.firstName'),
            type:"string"
        },
        gender:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.gender'),
            type:"string",
            options: genderData,
            uniforms: {
                type: "String",
                placeholder: i18next.t("generic.pleaseSelect")
            },
            // enum:[
            //     "MALE",
            //     "FEMALE",
            //     "OTHER"
            // ]
        },
        correlationType: {
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.correlationType'),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.correlationType'),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        },
        birthDate:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.birthDate'),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        notes:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.notes'),
            type:"string"
        },
        alive:{
            label: i18next.t('beneficiaryForm.familyDetails.affiliatedMembers.alive'),
            type:"boolean"
        }
    }
};
