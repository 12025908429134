import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import i18next from "../../../translations/i18nConfigInstance";

export const SendToFormPromptComponent = ({ showSendToFormPrompt, confirmActionCallback, cancelActionCallback, showAddComments }) => {
	return (
		<div className={"sweetAlertCustomStyles"}>
			<SweetAlert
				input={showAddComments}
				show={showSendToFormPrompt}
				showCancel
				confirmBtnText={i18next.t("prompts.sendTotFormAction.confirmButtonTitle")}
				confirmBtnBsStyle="success"
				cancelBtnText={i18next.t("prompts.sendTotFormAction.cancelButtonTitle")}
				//cancelBtnBsStyle="warning"
				title={i18next.t("prompts.sendTotFormAction.promptTitle")}
				onConfirm={(response) => confirmActionCallback(response)}
				onCancel={cancelActionCallback}
				focusConfirmBtn
			>
				{i18next.t("prompts.sendTotFormAction.promptSubtitle")}
				<br></br>
				{showAddComments === true &&
					<p>{i18next.t("prompts.sendTotFormAction.promtBackOfficerComment")}:</p>
				}
			</SweetAlert>
		</div>
	)
};
