import i18next from "../../../translations/i18nConfigInstance";

export const referralSchema = {
	title: i18next.t("beneficiaryForm.referencesTitle"),
	required: [
		"whoIsCreated",
		"system"
	],
	type: "object",
	properties: {
		// id:{
		//     label: i18next.t('beneficiaryForm.sessionsAndReferences.id'),
		//     type:"integer",
		// },
		whoIsCreated: {
			label: i18next.t('beneficiaryForm.references.whoIsCreated'),
			type: "string"
		},
		whoIsServed: {
			label: i18next.t('beneficiaryForm.references.whoIsServed'),
			type: "string"
		},
		notes: {
			label: i18next.t('beneficiaryForm.references.notes'),
			type: "string"
		},
		system: {
			label: i18next.t('beneficiaryForm.references.system'),
			type: "integer"
		},
		katigoriaYpiresias: {
			label: i18next.t('beneficiaryForm.references.katigoriaYpiresias'),
			type: "integer"
		},
		katigoriaDomis: {
			label: i18next.t('beneficiaryForm.references.katigoriaDomis'),
			type: "integer"
		},
		kinonikiDomi: {
			label: i18next.t('beneficiaryForm.references.koinonikhDomh'),
			type: "integer"
		}
	}
};
