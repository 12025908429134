import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import { Col, Row } from "@themesberg/react-bootstrap";
import { AutoField, AutoForm, SelectField } from "uniforms-bootstrap5";
import { createUniformSchema } from "../../../../../utils/uniformsSchemaBridgeGenerator";
import domainsService from "../../../../../_services/domainsServices/domainsService";
import { communicationWaySchema } from "../../../../../schema/beneficiaryFormSchemas/nested/communicationWaySchema";
import { CommunicationWayModel } from "../../../../../model/beneficiary/communicationWayModel";

type Props = {
    openFormModal: boolean,
    modalTitle: String,
    onClosingModalCallback: Function,
    onSaveButtonCallback: Function,
    okButtonTitle: String,
    recordData: Object,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    isEditModalFormState: boolean,
    isViewModalFormState: boolean,
    isCreateModalFormState: boolean,
}

class CommunicationWayFormComp extends React.Component<Props> {

    state = {
        localRecordData: Object.assign({}, this.props.recordData),

        //ResidenceTypes State
        pendingGettingCommunicationWayTypes: false,
        errorGettingCommunicationWayTypes: false,
        communicationWayTypes: null,
    };

    _communicationWayFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _communicationWayUniformSchema = createUniformSchema(communicationWaySchema);

    componentDidMount() {
        const {
            // Parent Form State
            isCreateMode,
            isEditMode,
            isViewMode,

            // Modal Form State
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        if (isCreateModalFormState) {
            this.setState({
                localRecordData: Object.assign({}, CommunicationWayModel)
            })
        }

        this.fetchCommunicationWayTypes();
    }

    componentWillUnmount() {
        this._communicationWayFormRef = null;
    }

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if (model) {
            if (model.communicationWayType && model.communicationWayType.id) {
                model.communicationWayType.id = parseInt(model.communicationWayType.id);
            }
        }

        this.setState({
            localRecordData: Object.assign({}, model)
        });
    };

    /**
     *  In case of Autoform of single
     */
    onSaveButtonPress = () => {
        const {
            localRecordData
        } = this.state;

        const {
            onSaveButtonCallback
        } = this.props;

        this._communicationWayFormRef.submit()
            .then(() => {
                onSaveButtonCallback(localRecordData);
            }).catch((error) => {
                console.log("Form has validation errors", error);
            });
    };

    fetchCommunicationWayTypes = () => {
        this.setState({
            pendingGettingCommunicationWayTypes: true,
        });

        domainsService.getResidenceTypes()
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {

                const uniformObjCompatible = response.map((item, index) => {
                    return {
                        label: item.description,
                        value: item.id
                    }
                });

                this.setState({
                    pendingGettingCommunicationWayTypes: false,
                    errorGettingCommunicationWayTypes: false,
                    communicationWayTypes: uniformObjCompatible
                })
            })
            .catch((error) => {
                console.log("Error getting Residence Types Data: ", error);
                this.setState({
                    pendingGettingCommunicationWayTypes: false,
                    errorGettingCommunicationWayTypes: true
                })
            });
    }

    render() {
        const {
            openFormModal,
            onClosingModalCallback,
            okModalButtonCallback,
            okButtonTitle,
            modalTitle,
            isViewMode,
            isEditMode,
            isCreateMode,
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        const {
            localRecordData,

            //CommunicationWayTypes State
            pendingGettingCommunicationWayTypes,
            errorGettingCommunicationWayTypes,
            communicationWayTypes,
        } = this.state;

        return (
            <GenericModalComponent
                showOkButton={!isViewModalFormState}
                showCancelButton={true}
                okModalButtonTitle={okButtonTitle}
                okButtonCallback={this.onSaveButtonPress}
                closeModalCallback={onClosingModalCallback}
                isModalVisible={openFormModal}
                modalTitle={modalTitle}
                isModalFullScreen={false}
                modalSize={'lg'}
            >

                <Row className={"autoFormCustom"}>
                    <Col xs={12} xl={12}>
                        <AutoForm
                            ref={(form) => {
                                this._communicationWayFormRef = form
                            }}
                            schema={this._communicationWayUniformSchema}
                            onChangeModel={(model) => this.updateModelCallback(model)}
                            model={localRecordData ? localRecordData : {}}
                            readOnly={isViewMode}
                            showInlineError
                            placeholder
                        >
                            <Row>
                                <Col xs={12} xl={6}>
                                    <AutoField name={"communicationWayKind"} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={6}>
                                    <AutoField name={"value"} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={4}>
                                    <SelectField
                                        name="communicationWayType.id"
                                        options={communicationWayTypes}
                                    />
                                </Col>
                            </Row>

                            <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"isMain"} />
                                </Col>
                            </Row>

                            {/* <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"validFrom"}/>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"validTo"}/>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"notes"} />
                                </Col>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"comments"} />
                                </Col>
                            </Row>

                        </AutoForm>
                    </Col>
                </Row>
            </GenericModalComponent>
        )
    }
}

export default CommunicationWayFormComp;
