import React from 'react';
import { Card, Col, Container, Collapse, Row, Button } from "@themesberg/react-bootstrap";
import i18next from "../../translations/i18nConfigInstance";
import userAccessLevelUtil from "../../utils/userAccessLevelUtil";


class MunicipalityActionsPage extends React.Component {

	_isBeneficiaryUser = userAccessLevelUtil.isBeneficiaryUser();
	_isBackOfficerUser = userAccessLevelUtil.isBackOfficerUser();

	constructor(props) {
		super(props);
		this.toggleA = this.toggleA.bind(this);
		this.toggleB = this.toggleB.bind(this);
		this.state = {
			municipalityActionsData: null,
			collapseA: true,
			collapseB: true
		};
	}


	componentDidMount() {

	}


	toggleA() {
		this.setState({ collapseA: !this.state.collapseA });
	}

	toggleB() {
		this.setState({ collapseB: !this.state.collapseB });
	}



	render() {
		const {
			// Form State
			municipalityActionsData
		} = this.state;

		return (
			<Row className="justify-content-md-center">

				<Row>
					<Col xs={12}>
						<Card border="light" className="customSectionHeaderStyle shadow-sm mb-4">
							<Card.Body>
								<h4 style={{ color: "#fefefe" }}>
									{i18next.t('municipalityActionsPage.page.title')}
								</h4>
							</Card.Body>
						</Card>
					</Col>
				</Row>


				<Row>
					<Col xs={12} className="p-3">
						<Button color="primary" onClick={this.toggleA} style={{ marginBottom: '1rem' }}>Δράση Α</Button>
						<Collapse in={this.state.collapseA}>
							<Card>
								<Card.Body>
									<article>
										<p className="fs-5 fw-light">Lorem ipsum dolor sit amet, decore labitur appareat an sit, sonet intellegat eum ea. Et velit electram vel, et tota habemus repudiandae pro, te oratio dicant quaestio est. Decore soluta periculis qui ne, ut ius adhuc mucius similique. Ad aliquid sententiae definiebas per, munere inciderint sadipscing at per.</p>
										<p>Lorem ipsum dolor sit amet, decore labitur appareat an sit, sonet intellegat eum ea. Et velit electram vel, et tota habemus repudiandae pro, te oratio dicant quaestio est. Decore soluta periculis qui ne, ut ius adhuc mucius similique. Ad aliquid sententiae definiebas per, munere inciderint sadipscing at per.</p>
										<ul className="docs-list">
											<li>Link A <Card.Link href="https://google.com" target="_blank">Lorem ipsum dolor sit amet</Card.Link></li>
											<li>Link B <Card.Link href="https://google.com" target="_blank">sonet intellegat eum ea</Card.Link></li>
										</ul>
									</article>
								</Card.Body>
							</Card>
						</Collapse>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="p-3">
						<Button color="primary" onClick={this.toggleB} style={{ marginBottom: '1rem' }}>Δράση Β</Button>
						<Collapse in={this.state.collapseB}>
							<Card>
								<Card.Body>
									<article>
										<p className="fs-5 fw-light">Tota habemus repudiandae pro, te oratio dicant quaestio est. Decore soluta periculis qui ne, ut ius adhuc mucius similique. Ad aliquid sententiae definiebas per, munere inciderint sadipscing at per. Lorem ipsum dolor sit amet, decore labitur appareat an sit, sonet intellegat eum ea. Et velit electram vel, et tota habemus repudiandae pro, te oratio dicant quaestio est. Decore soluta periculis qui ne, ut ius adhuc mucius similique. Ad aliquid sententiae definiebas per, munere inciderint sadipscing at per.</p>
										<p>Lorem ipsum dolor sit amet, decore labitur appareat an sit, sonet intellegat eum ea. Et velit electram vel, e</p>
										<ul className="docs-list">
											<li>Link A <Card.Link href="https://google.com" target="_blank">Lorem ipsum dolor sit amet</Card.Link></li>
											<li>Link B <Card.Link href="https://google.com" target="_blank">sonet intellegat eum ea</Card.Link></li>
										</ul>
									</article>
								</Card.Body>
							</Card>
						</Collapse>
					</Col>
				</Row>
			</Row>

		)
	}
}

export default MunicipalityActionsPage;
