import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

// pages
import Presentation from "../pages/@legacy/Presentation";
import Upgrade from "../pages/@legacy/Upgrade";
import DashboardOverview from "../pages/@legacy/dashboard/DashboardOverview";
import Transactions from "../pages/@legacy/Transactions";
import Settings from "../pages/@legacy/Settings";
import BootstrapTables from "../pages/@legacy/tables/BootstrapTables";
import ForgotPassword from "../pages/@legacy/examples/ForgotPassword";
import ResetPassword from "../pages/@legacy/examples/ResetPassword";
import Lock from "../pages/@legacy/examples/Lock";
import NotFoundPage from "../pages/@legacy/examples/NotFound";
import ServerError from "../pages/@legacy/examples/ServerError";

// documentation pages
import DocsOverview from "../pages/@legacy/documentation/DocsOverview";
import DocsDownload from "../pages/@legacy/documentation/DocsDownload";
import DocsQuickStart from "../pages/@legacy/documentation/DocsQuickStart";
import DocsLicense from "../pages/@legacy/documentation/DocsLicense";
import DocsFolderStructure from "../pages/@legacy/documentation/DocsFolderStructure";
import DocsBuild from "../pages/@legacy/documentation/DocsBuild";
import DocsChangelog from "../pages/@legacy/documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "../pages/@legacy/components/Accordion";
import Alerts from "../pages/@legacy/components/Alerts";
import Badges from "../pages/@legacy/components/Badges";
import Breadcrumbs from "../pages/@legacy/components/Breadcrumbs";
import Buttons from "../pages/@legacy/components/Buttons";
import Forms from "../pages/@legacy/components/Forms";
import Modals from "../pages/@legacy/components/Modals";
import Navs from "../pages/@legacy/components/Navs";
import Navbars from "../pages/@legacy/components/Navbars";
import Pagination from "../pages/@legacy/components/Pagination";
import Popovers from "../pages/@legacy/components/Popovers";
import Progress from "../pages/@legacy/components/Progress";
import Tables from "../pages/@legacy/components/Tables";
import Tabs from "../pages/@legacy/components/Tabs";
import Tooltips from "../pages/@legacy/components/Tooltips";
import Toasts from "../pages/@legacy/components/Toasts";
import GenericFormModule from "../modules/genericFormModule/GenericFormModule";
import HomePage from "../pages/homePage/HomePage";
import SignInPage from '../pages/loginPage/Signin';
import SignupPage from '../pages/signupPage/Signup';
import AuthService from "../_services/authorization/authService";
import BeneficiaryFormCreateUpdatePage from "../pages/beneficiaryFormPage/BeneficiaryFormCreateUpdatePage";
import userAccessLevelUtil from "../utils/userAccessLevelUtil";
import BeneficiariesListPage from "../pages/beneficiariesListPage/BeneficiariesListPage";
import BeneficiariesApplicationFormsListPage
	from "../pages/beneficiariesApplicationFormsListPage/BeneficiariesApplicationFormsListPage";
import BeneficiaryApplicationFormPage from "../pages/beneficiaryApplicationFormPage/BeneficiaryApplicationFormPage";
import MunicipalityActionsPage from 'pages/municipalityActions/MunicipalityActionsPage';
import ChartPage from 'pages/chart/ChartPage';

const PublicRouteWithLoader = ({ component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Route {...rest} render={props => (
			<>
				<Preloader show={!loaded} />
				{React.cloneElement(component, { ...props })}
			</>
		)} />
	);
};

const ProtectedRouteWithSidebar = ({ component, permittedRoles, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	// const localStorageIsSettingsVisible = () => {
	//     return localStorage.getItem('settingsVisible') !== 'false'
	// };
	// const getCurrentUser = () => {
	//     return JSON.parse(localStorage.getItem('user'));
	// }
	// const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);
	//
	// const toggleSettings = () => {
	//     setShowSettings(!showSettings);
	//     localStorage.setItem('settingsVisible', !showSettings);
	// };
	// return(
	//     <Route {...rest} render={props => (
	//         <>
	//             <Preloader show={!loaded}/>
	//             <Sidebar/>
	//
	//             <main className="content">
	//                 <Navbar/>
	//                 {React.cloneElement(component, {...props})}
	//                 <Footer toggleSettings={toggleSettings} showSettings={showSettings}/>
	//             </main>
	//         </>
	//     )}
	//     />
	// )

	// Get User Data from Local Storage
	const userData = AuthService.getCurrentUser();

	// Check if user data exists else send user to login screen as we are inside protected routes
	if (userData) {

		// Apply the Permitted Roles functionality to protected routes (if they exist)
		// Also make this conditionally as other protected routes may be accessible from any authenticated user)
		let userIsPermittedToSeeRouteContent = false;
		if (permittedRoles && permittedRoles.length > 0) {
			userIsPermittedToSeeRouteContent = userAccessLevelUtil.checkPermittedRolesForRouteAgainstUserRoles(permittedRoles, userData.roles);
		} else {
			userIsPermittedToSeeRouteContent = true;
		}

		if (userIsPermittedToSeeRouteContent === true) {
			return (
				<Route {...rest} render={props => (
					<>
						<Preloader show={!loaded} />

						<Sidebar />

						<main className="content">
							<Navbar />
							{React.cloneElement(component, { ...props })}
							<Footer />
						</main>
					</>
				)}
				/>
			);
		} else return (
			<Redirect to={Routes.HomePage.path} />
		);
	} else return (
		<Route {...rest} render={props => (
			<>
				<SignInPage />
			</>
		)}
		/>
	);
};

class RoutingComponent extends React.Component {

	render() {
		return (
			<Switch>
				<ProtectedRouteWithSidebar exact path={Routes.HomePage.path} component={<HomePage />} />

				{/*BENEFICIARY ROUTES*/}
				<ProtectedRouteWithSidebar exact
					path={Routes.BeneficiaryListPageRoute.path}
					permittedRoles={Routes.BeneficiaryListPageRoute.roles}
					component={
						<BeneficiariesListPage />
					}
				/>

				<ProtectedRouteWithSidebar exact
					path={Routes.BeneficiaryFormRoute.path}
					permittedRoles={Routes.BeneficiaryFormRoute.roles}
					component={
						<GenericFormModule><BeneficiaryFormCreateUpdatePage /></GenericFormModule>
					}
				/>

				<ProtectedRouteWithSidebar exact
					path={Routes.BeneficiaryFormRouteNew.path}
					permittedRoles={Routes.BeneficiaryFormRouteNew.roles}
					component={
						<GenericFormModule><BeneficiaryFormCreateUpdatePage /></GenericFormModule>
					}
				/>

				{/*BENEFICIARY APPLICATION FORM ROUTES*/}
				<ProtectedRouteWithSidebar exact
					path={Routes.BeneficiaryApplicationFormsListPageRoute.path}
					permittedRoles={Routes.BeneficiaryApplicationFormsListPageRoute.roles}
					component={
						<BeneficiariesApplicationFormsListPage />
					}
				/>

				<ProtectedRouteWithSidebar exact
					path={Routes.BeneficiaryApplicationFormRoute.path}
					permittedRoles={Routes.BeneficiaryApplicationFormRoute.roles}
					component={
						<GenericFormModule><BeneficiaryApplicationFormPage /></GenericFormModule>
					}
				/>

				<ProtectedRouteWithSidebar exact
					path={Routes.BeneficiaryApplicationFormRouteNew.path}
					permittedRoles={Routes.BeneficiaryApplicationFormRouteNew.roles}
					component={
						<GenericFormModule><BeneficiaryApplicationFormPage /></GenericFormModule>
					}
				/>

				<ProtectedRouteWithSidebar exact
					path={Routes.beneficiaryAvailableActionsByMunicipality.path}
					permittedRoles={Routes.beneficiaryAvailableActionsByMunicipality.roles}
					component={
						<GenericFormModule><MunicipalityActionsPage /></GenericFormModule>
					}
				/>

				<ProtectedRouteWithSidebar exact
					path={Routes.ChartPageRoute.path}
					permittedRoles={Routes.ChartPageRoute.roles}
					component={
						<ChartPage />
					}
				/>

				{/*LEGACY THEME ROUTES*/}
				<ProtectedRouteWithSidebar exact path={Routes.Transactions.path} component={<Transactions />} />
				<ProtectedRouteWithSidebar exact path={Routes.Settings.path} component={<Settings />} />

				<PublicRouteWithLoader exact path={Routes.Presentation.path} component={<Presentation />} />
				<PublicRouteWithLoader exact path={Routes.Signin.path} component={<SignInPage />} />
				<PublicRouteWithLoader exact path={Routes.Signup.path} component={<SignupPage />} />
				<PublicRouteWithLoader exact path={Routes.SignupWithCode.path} component={<SignupPage />} />
				<PublicRouteWithLoader exact path={Routes.ForgotPassword.path} component={<ForgotPassword />} />
				<PublicRouteWithLoader exact path={Routes.ResetPassword.path} component={<ResetPassword />} />
				<PublicRouteWithLoader exact path={Routes.Lock.path} component={<Lock />} />
				<PublicRouteWithLoader exact path={Routes.NotFound.path} component={<NotFoundPage />} />
				<PublicRouteWithLoader exact path={Routes.ServerError.path} component={<ServerError />} />

				{/* pages */}
				<ProtectedRouteWithSidebar exact path={Routes.DashboardOverview.path} component={<DashboardOverview />} />
				<ProtectedRouteWithSidebar exact path={Routes.Upgrade.path} component={<Upgrade />} />
				<ProtectedRouteWithSidebar exact path={Routes.Transactions.path} component={<Transactions />} />
				<ProtectedRouteWithSidebar exact path={Routes.Settings.path} component={<Settings />} />
				<ProtectedRouteWithSidebar exact path={Routes.BootstrapTables.path} component={<BootstrapTables />} />

				{/* components */}
				<ProtectedRouteWithSidebar exact path={Routes.Accordions.path} component={<Accordion />} />
				<ProtectedRouteWithSidebar exact path={Routes.Alerts.path} component={<Alerts />} />
				<ProtectedRouteWithSidebar exact path={Routes.Badges.path} component={<Badges />} />
				<ProtectedRouteWithSidebar exact path={Routes.Breadcrumbs.path} component={<Breadcrumbs />} />
				<ProtectedRouteWithSidebar exact path={Routes.Buttons.path} component={<Buttons />} />
				<ProtectedRouteWithSidebar exact path={Routes.Forms.path} component={<Forms />} />
				<ProtectedRouteWithSidebar exact path={Routes.Modals.path} component={<Modals />} />
				<ProtectedRouteWithSidebar exact path={Routes.Navs.path} component={<Navs />} />
				<ProtectedRouteWithSidebar exact path={Routes.Navbars.path} component={<Navbars />} />
				<ProtectedRouteWithSidebar exact path={Routes.Pagination.path} component={<Pagination />} />
				<ProtectedRouteWithSidebar exact path={Routes.Popovers.path} component={<Popovers />} />
				<ProtectedRouteWithSidebar exact path={Routes.Progress.path} component={<Progress />} />
				<ProtectedRouteWithSidebar exact path={Routes.Tables.path} component={<Tables />} />
				<ProtectedRouteWithSidebar exact path={Routes.Tabs.path} component={<Tabs />} />
				<ProtectedRouteWithSidebar exact path={Routes.Tooltips.path} component={<Tooltips />} />
				<ProtectedRouteWithSidebar exact path={Routes.Toasts.path} component={<Toasts />} />

				{/* documentation */}
				<ProtectedRouteWithSidebar exact path={Routes.DocsOverview.path} component={<DocsOverview />} />
				<ProtectedRouteWithSidebar exact path={Routes.DocsDownload.path} component={<DocsDownload />} />
				<ProtectedRouteWithSidebar exact path={Routes.DocsQuickStart.path} component={<DocsQuickStart />} />
				<ProtectedRouteWithSidebar exact path={Routes.DocsLicense.path} component={<DocsLicense />} />
				<ProtectedRouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={<DocsFolderStructure />} />
				<ProtectedRouteWithSidebar exact path={Routes.DocsBuild.path} component={<DocsBuild />} />
				<ProtectedRouteWithSidebar exact path={Routes.DocsChangelog.path} component={<DocsChangelog />} />

				<Redirect to={Routes.NotFound.path} />
			</Switch>
		);
	}
};

export default RoutingComponent;

