import React from 'react';
import {convertDateToLocaleDateTime} from "../../../../../utils/dateTimeUtils";
import i18next from "../../../../../translations/i18nConfigInstance";
import {withRouter} from "react-router-dom";

type Props = {
    onUpdateParentComponent: Function
}

class BeneficiarySearchFilterTableRowComponent extends React.Component<Props> {

    onChooseRowItem = () => {
        const {
            onUpdateParentComponent,
            recordData
        } = this.props;
        onUpdateParentComponent(recordData);
    };

    render() {
        const {
            recordData: {
                id,
                amka,
                afm,
                firstName,
                lastName,
                birthDate,
                gender,
                alive,
                active,
                approved,
            }
        } = this.props;

        return (
            <tr onClick={this.onChooseRowItem}>
                <td>
                    <span className="fw-normal">
                      {id}
                    </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {amka}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {afm}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {firstName}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {lastName}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(birthDate)}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {gender}
                  </span>
                </td>
                <td>
                    <span className={`text-${alive ? 'success' : 'danger'}`}>● </span>
                    <span>{alive ? i18next.t('generic.yes') : i18next.t('generic.no')}</span>
                </td>
                <td>
                    <span className={`text-${active ? 'success' : 'danger'}`}>● </span>
                    <span>{active ? i18next.t('generic.yes') : i18next.t('generic.no')}</span>
                </td>
                <td>
                    <span className={`text-${approved ? 'success' : 'danger'}`}>● </span>
                    <span>{approved ? i18next.t('generic.yes') : i18next.t('generic.no')}</span>
                </td>
            </tr>
        )
    }
}

export default withRouter(BeneficiarySearchFilterTableRowComponent);
