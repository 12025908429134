export const AffiliatedMemberModel = {
    id: null,
    amka: null,
    afm: null,
    lastName: null,
    firstName: null,
    gender: null,
    birthDate: null,
    alive: true,
    notes: "",
    correlationType: {
        id: null,
        description: null
    }
}
