import axios from 'axios';
import rootStore from "../store/configureStore";
import { addNotificationService } from "../components/notificationComponent/NotificationsService";
import i18next from "../translations/i18nConfigInstance";
import { isoDateFormat } from "../utils/dateTimeUtils";
import authHeader from "./authorization/authHeader";
import AuthService from "../_services/authorization/authService";

// Get current redux store state
const reduxStoreState = rootStore;

/**
 * Create Axios Instance to be used across Application Requests
 * @type {AxiosInstance}
 */
const opsYtkeApiAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_WEB_API_BASE
});

/**
 * Axios Request Interceptor
 * Add Axios Authorization Header for API Secured Routes
 */
opsYtkeApiAxiosInstance.interceptors.request.use(function (config) {

	// Get Authorization Header Prop from service
	// after fetching jwt from localstorage
	const authHeadersAuthorizationHeaderProp = authHeader();

	Object.assign(config.headers, authHeadersAuthorizationHeaderProp);

	return config;

}, function (error) {
	return Promise.reject(error);
});

/**
 * Axios Response Interceptor
 * Error Handling Interceptor showing Error Notification
 */
opsYtkeApiAxiosInstance.interceptors.response.use(function (response) {
	return response;
}, function (error) {

	const {
		config,
		response: {
			status
		}
	} = error; // Access the error object props

	// Configuration of request that can be used eg: Retry functionality
	const originalRequestConfiguration = config;

	const notificationTitle = i18next.t("networking.networkError");

	// Handle 401 Unauthorized Http Status by redirecting to LoginScreen and showing Notification
	if (status === 401) {
		addNotificationService(notificationTitle, i18next.t("networking.401HttpError"), "danger");
		AuthService.logoutAndGoToLoginScreen();
	} else if (status === 404) {
		addNotificationService(notificationTitle, i18next.t("networking.404HttpError"), "danger");
	} else if (status >= 400 && status < 500) {
		addNotificationService(notificationTitle, i18next.t("networking.400RangeHttpError"), "danger");
	} else if (status >= 500) {
		addNotificationService(notificationTitle, i18next.t("networking.500RangeHttpError"), "danger");
	}
	// Return the error as promise in case any Axios instance is in need to handle error in a custom way
	return Promise.reject(error);
});

/**
 * Convert String Dates from Network Response onto Date Objects
 */
opsYtkeApiAxiosInstance.interceptors.response.use(originalResponse => {
	originalResponse.data = handleDates(originalResponse.data);
	return originalResponse;
});

/**
 * Check if String Date is valid date format against a regex
 * @param value
 * @returns {boolean}
 */
function isIsoDateString(value) {
	if (value && typeof value === "string") {
		return isoDateFormat.test(value);
	}
}

/**
 * Recursively check Network Response Body for String Dates and converts them in Date Object
 * @param body
 * @returns {*}
 */
export function handleDates(body) {
	if (body === null || body === undefined || typeof body !== "object")
		return body;

	for (const key of Object.keys(body)) {
		const value = body[key];
		if (isIsoDateString(value)) body[key] = new Date(value);
		else if (typeof value === "object") handleDates(value);
	}
	return body;
}

/**
 * Function retrying original axios request,
 * passed as callback function
 * @param originalRequestConfiguration
 */
function retryRequest(originalRequestConfiguration) {
	opsYtkeApiAxiosInstance(originalRequestConfiguration)
		.catch(error => {
			console.log("Retry Request error: " + error);
		});
}

//Later we might have an employer instance etc
export default opsYtkeApiAxiosInstance;
