import React from 'react';
import { Col, Row, FormGroup, FormCheck, Card } from "@themesberg/react-bootstrap";
import i18next from "../../../../translations/i18nConfigInstance";
import AffiliatedMembersListComponent from "./components/affiliatedMembersListComponent/AffiliatedMembersListComponent";
import GenericSectionHeaderComponent
	from "../../../../components/genericSectionHeaderComponent/GenericSectionHeaderComponent";

type Props = {
	updateModelParentCallback: Function,
	isEditMode: boolean,
	isViewMode: boolean,
	isCreateMode: boolean,
	recordData: any
}

class FamilyDetailsFormComponent extends React.Component<Props> {
	/**
	 * When rendering has completed
	 * we register to parent component,
	 * the Autoform Reference in order to
	 * control the validation and submit
	 * from parent Component
	 */
	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/**
	 * Updates the parent model containing
	 * current data schema with local schema refreshed data
	 * via callback
	 * @param data
	 */
	updateParentModel = (data) => {
		const {
			updateModelParentCallback
		} = this.props;
		updateModelParentCallback(data);
	};

	setSingleParentFamily = (event) => {
		const {
			recordData
		} = this.props;

		const tempModel = Object.assign({}, recordData);
		tempModel.singleParentFamily = event.target.checked;

		this.updateParentModel(tempModel);
	};

	setUnmarried = (event) => {
		const {
			recordData
		} = this.props;

		const tempModel = Object.assign({}, recordData);
		tempModel.unmarried = event.target.checked;

		this.updateParentModel(tempModel);
	};

	setMaried = (event) => {
		const {
			recordData
		} = this.props;

		const tempModel = Object.assign({}, recordData);
		tempModel.maried = event.target.checked;

		this.updateParentModel(tempModel);
	};


	setDivorced = (event) => {
		const {
			recordData
		} = this.props;

		const tempModel = Object.assign({}, recordData);
		tempModel.divorced = event.target.checked;

		this.updateParentModel(tempModel);
	};

	setWidowed = (event) => {
		const {
			recordData
		} = this.props;

		const tempModel = Object.assign({}, recordData);
		tempModel.widowed = event.target.checked;

		this.updateParentModel(tempModel);
	};

	updateFamilyDetailsAffiliatedMembersListModel = (freshData) => {
		const {
			recordData
		} = this.props;

		const tempModel = Object.assign({}, recordData);
		tempModel.affiliatedMembers = freshData;

		this.updateParentModel(tempModel);
	};

	render() {
		const {
			isViewMode,
			isEditMode,
			isCreateMode,
			recordData
		} = this.props;

		return (
			<Row>
				<Col xs={12}>

					<Row>
						<Col xs={12} xl={12}>

							<Card border="light" className="shadow-sm mb-4">
								<Card.Body>
									<Row className={"mt-2 mb-2"}>
										<Col xs={12} xl={12}>
											<FormGroup controlId="formBasicCheckbox">
												<FormCheck type="checkbox"
													label={i18next.t('beneficiaryForm.familyDetails.singleParentFamily')}
													defaultChecked={recordData?.singleParentFamily}
													onChange={this.setSingleParentFamily}
													disabled={isViewMode}
												/>
											</FormGroup>
										</Col>
									</Row>

									<Row className={"mt-2 mb-2"}>
										<Col xs={12} xl={12}>
											<FormGroup controlId="formBasicCheckbox">
												<FormCheck type="checkbox"
													label={i18next.t('beneficiaryForm.familyDetails.unmarried')}
													defaultChecked={recordData?.unmarried}
													onChange={this.setUnmarried}
													disabled={isViewMode}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row className={"mt-2 mb-2"}>
										<Col xs={12} xl={12}>
											<FormGroup controlId="formBasicCheckbox">
												<FormCheck type="checkbox"
													label={i18next.t('beneficiaryForm.familyDetails.maried')}
													defaultChecked={recordData?.maried}
													onChange={this.setMaried}
													disabled={isViewMode}
												/>
											</FormGroup>
										</Col>
									</Row>

									<Row className={"mt-2 mb-2"}>
										<Col xs={12} xl={12}>
											<FormGroup controlId="formBasicCheckbox">
												<FormCheck type="checkbox"
													label={i18next.t('beneficiaryForm.familyDetails.divorced')}
													defaultChecked={recordData?.divorced}
													onChange={this.setDivorced}
													disabled={isViewMode}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row className={"mt-2 mb-2"}>
										<Col xs={12} xl={12}>
											<FormGroup controlId="formBasicCheckbox">
												<FormCheck type="checkbox"
													label={i18next.t('beneficiaryForm.familyDetails.widowed')}
													defaultChecked={recordData?.widowed}
													onChange={this.setWidowed}
													disabled={isViewMode}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12} xl={12}>

							<GenericSectionHeaderComponent
								title={i18next.t("beneficiaryForm.familyDetails.affiliatedMembersTitle")} />

							<AffiliatedMembersListComponent
								dataArray={recordData?.affiliatedMembers}
								isEditMode={isEditMode}
								isViewMode={isViewMode}
								isCreateMode={isCreateMode}
								updateModelParentCallback={this.updateFamilyDetailsAffiliatedMembersListModel}
							/>
						</Col>
					</Row>

				</Col>
			</Row>
		)
	}
}

export default FamilyDetailsFormComponent;
