import React from "react";
import {
	Link,
} from "react-router-dom";
import { Col, Row } from '@themesberg/react-bootstrap';
import i18next from "../../translations/i18nConfigInstance";
import { WidgetWithAvatarComponent } from "../../components/WidgetWithAvatar/WidgetWithAvatarComponent";
import { Routes } from "../../routing/routes";
import beneficiaryBulletinLottieAnimation from "../../assets/lottieAnimations/office/36678-check-list.json";
import beneficiaryCardLottieAnimation from "../../assets/lottieAnimations/office/35049-data-analyst.json";
import municipalityActionsAnimation from "../../assets/lottieAnimations/40272-business-app-animation.json";
import userAccessLevelUtil from "../../utils/userAccessLevelUtil";
import reportService from "_services/report/reportService";

const style = {
	avatarBackgroundStyle: {
		backgroundColor: '#f7f7f7'
	}
};

class HomePage extends React.Component {

	state = {
		isBackOfficerUser: userAccessLevelUtil.isBackOfficerUser(),
		isBeneficiaryUser: userAccessLevelUtil.isBeneficiaryUser(),
		beneficiaryData: userAccessLevelUtil.getUserDataFromLocalStorage()
	}


	getHomePageReport() {
		reportService.getTestReport();
	}

	render() {
		const {
			isBackOfficerUser,
			isBeneficiaryUser,
			beneficiaryData
		} = this.state;

		return (
			<div className="container pt-5 pb-5">
				<div className="row">
					<Row className="justify-content-md-center">

						<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
							<Link to={isBeneficiaryUser ? "/beneficiary/edit/" + beneficiaryData.userId : isBackOfficerUser ? Routes.BeneficiaryListPageRoute.path : null}>
								<WidgetWithAvatarComponent
									title={isBeneficiaryUser ? i18next.t("menu.beneficiaryPersonalDataMenuFull") : isBackOfficerUser ? i18next.t("menu.backΟfficerBeneficiariesListFull") : null}
									//subtitle={recordData?.wagonnumber}
									//imageSource={Profile1}
									lottieAnimationSourceFile={beneficiaryBulletinLottieAnimation}
									animationWidth={180}
									animationHeight={180}
									backgroundColor={style.avatarBackgroundStyle.backgroundColor}
									darkThemeEnabled
								/>
							</Link>
						</Col>

						<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
							<Link to={Routes.BeneficiaryApplicationFormsListPageRoute.path}>
								<WidgetWithAvatarComponent
									title={isBeneficiaryUser ? i18next.t("menu.beneficiaryBulletinsCardsDataMenuFull") : isBackOfficerUser ? i18next.t("menu.backΟfficerBeneficiariesBulletinsCardsDataMenuFull") : null}
									//subtitle={recordData?.wagonnumber}
									//imageSource={Profile1}
									lottieAnimationSourceFile={beneficiaryCardLottieAnimation}
									animationWidth={180}
									animationHeight={180}
									backgroundColor={style.avatarBackgroundStyle.backgroundColor}
									darkThemeEnabled
								/>
							</Link>
						</Col>
						<>
							{isBackOfficerUser &&
								<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5" >
									<Link to={Routes.ChartPageRoute.path}>
										<WidgetWithAvatarComponent
											title={i18next.t("menu.report")}
											//subtitle={recordData?.wagonnumber}
											//imageSource={Profile1}
											lottieAnimationSourceFile={municipalityActionsAnimation}
											animationWidth={180}
											animationHeight={180}
											backgroundColor={style.avatarBackgroundStyle.backgroundColor}
											darkThemeEnabled
										/>
									</Link>
								</Col>
							}
						</>

					</Row>
				</div>
			</div>
		);
	}
}
export default HomePage;
