import i18next from "../translations/i18nConfigInstance";

export const statusTitle = (statusCode) => {
    let title = '';

    if(statusCode === 0) {
        title = i18next.t('generic.pending');
    } else if(statusCode === 1) {
        title = i18next.t('generic.approved');
    } else if(statusCode === 2) {
        title = i18next.t('generic.rejected');
    } else if(statusCode === 3) {
        title = i18next.t('generic.sentBackToBeneficiary');
    } else if(statusCode === 4) {
        title = i18next.t('generic.sentBackToBackOfficer');
    } else {
        title = i18next.t('generic.unknown');
    }

    return title;
}
