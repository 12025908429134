import React from 'react';
import {Col, Row} from "@themesberg/react-bootstrap";
import {AutoField, AutoForm, SelectField} from "uniforms-bootstrap5";
import {createUniformSchema} from "../../../../utils/uniformsSchemaBridgeGenerator";
import {educationSchema} from "../../../../schema/beneficiaryFormSchemas/nested/educationSchema";
import domainsService from "../../../../_services/domainsServices/domainsService";

type Props = {
    updateModelParentCallback: Function,
    registerUniformRefCallback: Function,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    recordData: any
}

class EducationFormComponent extends React.Component<Props> {

    // Reference to AutoForm form property for validate/submit operations
    _educationFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _educationUniformSchema = createUniformSchema(educationSchema);

    state = {
        pendingGettingEducationLevelTypes: false,
        errorGettingEducationLevelTypes: false,
        educationLevelTypes: null,
    }

    /**
     * When rendering has completed
     * we register to parent component,
     * the Autoform Reference in order to
     * control the validation and submit
     * from parent Component
     */
    componentDidMount() {
        const {
            registerUniformRefCallback
        } = this.props;
        registerUniformRefCallback(this._educationFormRef);

        this.fetchEducationLevelTypes();
    }

    componentWillUnmount() {
        this._educationFormRef = null;
    }

    /**
     * Updates the parent model containing
     * current data schema with local schema refreshed data
     * via callback
     * @param data
     */
    updateParentModel = (data) => {
        const {
            updateModelParentCallback
        } = this.props;
        updateModelParentCallback(data);
    };

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if(typeof model !== "undefined") {
            if(typeof model.educationLevel !== "undefined" && model.educationLevel.id !== null) {
                model.educationLevel.id = parseInt(model.educationLevel.id);
            }
        }
        this.updateParentModel(model);
    };

    fetchEducationLevelTypes = () => {
        this.setState({
            pendingGettingEducationLevelTypes: true,
        });

        domainsService.getEducationLevels()
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {

                const uniformObjCompatible = response.map((item, index) => {
                    return {
                        label: item.description,
                        value: item.id
                    }
                });

                this.setState({
                    pendingGettingEducationLevelTypes: false,
                    errorGettingEducationLevelTypes: false,
                    educationLevelTypes: uniformObjCompatible,
                })
            })
            .catch((error) => {
                console.log("Error getting Education Level Types Data: ", error);
                this.setState({
                    pendingGettingEducationLevelTypes: false,
                    errorGettingEducationLevelTypes: true
                })
            });
    }

    render() {
        const {
            isViewMode,
            isEditMode,
            isCreateMode,
            recordData
        } = this.props;

        const {
            pendingGettingEducationLevelTypes,
            errorGettingEducationLevelTypes,
            educationLevelTypes
        } = this.state;

        return (
            <Row>
                <Col xs={12}>
                    <Row className={"autoFormCustom"}>
                        <Col xs={12} xl={12}>
                            <AutoForm
                                ref={(form) => {
                                    this._educationFormRef = form
                                }}
                                schema={this._educationUniformSchema}
                                onChangeModel={(model) => this.updateModelCallback(model)}
                                model={recordData ? recordData : {}}
                                readOnly={isViewMode}
                                showInlineError
                                placeholder
                            >

                                <Row>
                                    <Col xs={8}>
                                        <AutoField name={"specialization"}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={4}>
                                        <AutoField name={"greekSpeakingLevel"}/>
                                    </Col>

                                    <Col xs={4}>
                                        <AutoField name={"greekWritingLevel"}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={4}>
                                        <SelectField
                                            name="educationLevel.id"
                                            options={educationLevelTypes}
                                        />
                                    </Col>
                                </Row>

                            </AutoForm>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default EducationFormComponent;
