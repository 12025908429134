import React from 'react';
import TableFurtherActionsComponent from "../../../../../../../components/genericTableComponent/TableFurtherActionsComponent";
import {convertDateToLocaleDateTime} from "../../../../../../../utils/dateTimeUtils";
import i18next from "../../../../../../../translations/i18nConfigInstance";

type Props = {
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    onViewRowItemFormCallback: Function,
    onEditRowItemFormCallback: Function,
    onDeleteRowItemFormCallback: Function,
}

class AffiliatedMemberTableRowComp extends React.Component<Props> {

    onViewActionPress = (objectData) => {
        const {
            rowIndex,
            onViewRowItemFormCallback
        } = this.props;
        onViewRowItemFormCallback(objectData, rowIndex);
    };

    onEditActionPress = (objectData) => {
        const {
            rowIndex,
            onEditRowItemFormCallback
        } = this.props;
        onEditRowItemFormCallback(objectData, rowIndex);
    };

    onDeleteActionPress = (objectData) => {
        const {
            rowIndex,
            onDeleteRowItemFormCallback
        } = this.props;
        onDeleteRowItemFormCallback(objectData, rowIndex);
    };

    render() {
        const {
            isViewMode,
            isEditMode,
            isCreateMode,
            recordData: {
                id,
                amka,
                afm,
                lastName,
                firstName,
                gender,
                birthDate,
                alive,
                notes,
                correlationType: {
                    description
                }
            }
        } = this.props;

        return (
            <tr>
                <td>
                    <span className="fw-normal">
                      {id}
                    </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {description}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {amka}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {afm}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {lastName}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {firstName}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {gender}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(birthDate)}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {alive ? i18next.t('generic.yes') : i18next.t('generic.no')}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {notes}
                  </span>
                </td>
                <td>
                    <TableFurtherActionsComponent
                        isViewDisabled={false}
                        isEditDisabled={isViewMode}
                        isDeleteDisabled={isViewMode}
                        viewRowItemCallback={() => this.onViewActionPress(this.props.recordData)}
                        editRowItemCallback={() => this.onEditActionPress(this.props.recordData)}
                        deleteRowItemCallback={() => this.onDeleteActionPress(this.props.recordData)}
                    />
                </td>
            </tr>
        )
    }
}

export default AffiliatedMemberTableRowComp;
