import React from 'react';
import {Button, Col, Form, FormControl, FormGroup, InputGroup, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import GenericModalComponent from "../genericModalComponent/GenericModalComponent";
import GenericTableComponent from "../genericTableComponent/GenericTableComponent";

type Props = {
    fieldValue: any,
    fieldDescription: any,
    fieldLabel: string,
    disabled: boolean,
    searchButtonDisabled: boolean,
    placeholder: string,
    isModalOpen: boolean,
    modalToggleCallback: string,
    searchModalTitle: string,
    modalSize: string,
    serviceToFetchPagedTableData: Function,
    datatableColumnsNames: Array,
    tableRowComponent: any,
    isInvalid: boolean,
    errorMessage: string,
}

class GenericInputFieldWithSearchModalComponent extends React.Component<Props> {

    state = {
        paginationData: null,
        pendingGettingPaginationData: false,
        errorGettingPaginationData: false,

        totalItemsPerPage: 10
    };

    componentDidMount(): void {
        this.fetchPaginatedDataFromService();
    };

    fetchPaginatedDataFromService = (dataToBePassed) => {
        const {
            serviceToFetchPagedTableData
        } = this.props;

        this.setState({
            paginationData: null,
            pendingGettingPaginationData: true,
            errorGettingPaginationData: false
        });

        serviceToFetchPagedTableData(dataToBePassed)
            .then((response) => {
                this.setState({
                    paginationData: response,
                    pendingGettingPaginationData: false,
                    errorGettingPaginationData: false
                });
            }).catch((error) => {
            console.log("Error getting Pagination Data from service: ", error);
            this.setState({
                pendingGettingPaginationData: false,
                errorGettingPaginationData: true
            });
        });
    };

    // Controls the status of modal is open by calling the parent callback
    // in order to change the isModalOpen param from props fetched from parent component
    modalToggle = (toggleValue) => {
        const {
            modalToggleCallback
        } = this.props;
        modalToggleCallback(toggleValue);
    };

    setTotalItemsPerPage = (totalItemsNo) => {
        this.setState({
            totalItemsPerPage: totalItemsNo
        });
    };

    render() {
        const {
            fieldValue = "",
            fieldDescription,
            fieldLabel,
            disabled,
            searchButtonDisabled=false,
            placeholder,
            isModalOpen,
            searchModalTitle,
            modalSize = 'lg',
            datatableColumnsNames,
            tableRowComponent,
            isInvalid,
            errorMessage,
        } = this.props;

        const {
            paginationData,
            pendingGettingPaginationData,
            errorGettingPaginationData,

            totalItemsPerPage
        } = this.state;

        return (
            <>
                <Row>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label> {fieldLabel}</Form.Label>

                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder={placeholder}
                                    value={fieldValue}
                                    disabled={disabled}
                                    isInvalid={isInvalid}
                                />
                                <Button
                                    disabled={searchButtonDisabled}
                                    variant="primary"
                                    onClick={() => this.modalToggle(true)}
                                >
                                    <FontAwesomeIcon icon={faSearch} className="m-0"/>
                                </Button>

                                {/*<Form.Control.Feedback type="invalid">{errorMessage ? errorMessage : null}</Form.Control.Feedback>*/}
                            </InputGroup>

                            {isInvalid &&
                            <label className={"text-danger pt-1 pb-1"} style={{
                                fontSize: '0.875em'
                            }}>
                                {errorMessage ? errorMessage : null}
                            </label>}

                        </Form.Group>
                    </Col>
                </Row>


                {/* TODO To be implemented when service is ready so that when entity is fetched also company details to be fetched from same entity*/}
                {/*<Row>*/}
                {/*    <Col xs={8} className={"mt-2"}>*/}
                {/*        <h4>{fieldDescription}</h4>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <GenericModalComponent
                    showOkButton={false}
                    showCancelButton={true}
                    okModalButtonTitle={null}
                    okButtonCallback={null}
                    closeModalCallback={() => this.modalToggle(false)}
                    isModalVisible={isModalOpen}
                    modalTitle={searchModalTitle}
                    isModalFullScreen={false}
                    modalSize={modalSize}
                >

                    <Row>
                        <Col xs={12}>
                            <GenericTableComponent
                                recordsData={paginationData?.elements}
                                stickyLastColumn={false}
                                pendingGettingRecordsData={pendingGettingPaginationData}
                                errorGettingRecordsData={errorGettingPaginationData}
                                tableHeaderTitles={datatableColumnsNames}
                                tableRowComponent={tableRowComponent}
                                serverSidePaginationEnabled
                                totalItemsPerPage={totalItemsPerPage}
                                paginatedData={paginationData}
                                serverSidePaginationServiceCallback={this.fetchPaginatedDataFromService}
                            />
                        </Col>
                    </Row>

                </GenericModalComponent>
            </>
        )
    }
}

export default GenericInputFieldWithSearchModalComponent;
