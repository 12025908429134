import React from 'react';
import TableFurtherActionsComponent from "../../../components/genericTableComponent/TableFurtherActionsComponent";
import {convertDateToLocaleDateTime} from "../../../utils/dateTimeUtils";
import i18next from "../../../translations/i18nConfigInstance";
import {withRouter} from "react-router-dom";

class BeneficiaryTableRowComponent extends React.Component<Props> {

    onViewActionPress = (userId) => {
        const {
            history
        } = this.props;
        history.push({
            pathname: '/beneficiary/view/' + userId,
            state: {detail: userId}
        })
    };

    onEditActionPress = (userId) => {
        const {
            history
        } = this.props;

        // We can pass params to URL or even pass Object via state,
        // and can retrieve object from Component Navigatin to,
        // accessing this.props.location.state
        // *Important info to access 'this.props.location' like here 'this.props.history'
        // component doing the handling MUST be wrapped in 'react-router-dom.withRouter'
        history.push({
            pathname: '/beneficiary/edit/' + userId,
            state: {detail: userId}
        })
    };

    render() {
        const {
            recordData: {
                id,
                amka,
                afm,
                firstName,
                lastName,
                birthDate,
                gender,
                alive,
                active,
                approved,
            }
        } = this.props;

        return (
            <tr>
                <td>
                    <span className="fw-normal">
                      {id}
                    </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {amka}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {afm}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {firstName}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                      {lastName}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {convertDateToLocaleDateTime(birthDate)}
                  </span>
                </td>
                <td>
                  <span className="fw-normal">
                        {gender}
                  </span>
                </td>
                <td>
                    <span className={`text-${alive ? 'success' : 'danger'}`}>● </span>
                    <span>{alive ? i18next.t('generic.yes') : i18next.t('generic.no')}</span>
                </td>
                <td>
                    <span className={`text-${active ? 'success' : 'danger'}`}>● </span>
                    <span>{active ? i18next.t('generic.yes') : i18next.t('generic.no')}</span>
                </td>
                <td>
                    <span className={`text-${approved ? 'success' : 'danger'}`}>● </span>
                    <span>{approved ? i18next.t('generic.yes') : i18next.t('generic.no')}</span>
                </td>

                <td>
                    <TableFurtherActionsComponent
                        isViewDisabled={false}
                        isEditDisabled={false}
                        isDeleteDisabled={true}
                        isSendMessageActionDisabled={false}
                        viewRowItemCallback={() => this.onViewActionPress(id)}
                        editRowItemCallback={() => this.onEditActionPress(id)}
                        sendMessageRowItemCallback={null}
                        deleteRowItemCallback={null}
                    />
                </td>
            </tr>
        )
    }
}

export default withRouter(BeneficiaryTableRowComponent);
