import axios from 'axios';
import {addNotificationService} from "../components/notificationComponent/NotificationsService";
import i18next from "../translations/i18nConfigInstance";
import AuthService from "../_services/authorization/authService";

/**
 * Create Axios Instance to be used across Application Requests
 * @type {AxiosInstance}
 */
const opsYtkePublicApiAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_WEB_API_BASE
});

/**
 * Axios Response Interceptor
 * Error Handling Interceptor showing Error Notification
 */
opsYtkePublicApiAxiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    const {
        config,
        response: {
            status
        }
    } = error; // Access the error object props

    // Configuration of request that can be used eg: Retry functionality
    const originalRequestConfiguration = config;

    const notificationTitle = i18next.t("networking.networkError");

    // Handle 401 Unauthorized Http Status by redirecting to LoginScreen and showing Notification
    if (status === 401) {
        addNotificationService(notificationTitle, i18next.t("networking.401HttpError"), "danger");
        AuthService.logoutAndGoToLoginScreen();
    } else if (status === 404) {
        addNotificationService(notificationTitle, i18next.t("networking.404HttpError"), "danger");
    } else if (status >= 400 && status < 500) {
        addNotificationService(notificationTitle, i18next.t("networking.400RangeHttpError"), "danger");
    } else if (status >= 500) {
        addNotificationService(notificationTitle, i18next.t("networking.500RangeHttpError"), "danger");
    }
    // Return the error as promise in case any Axios instance is in need to handle error in a custom way
    return Promise.reject(error);
});

//Later we might have an employer instance etc
export default opsYtkePublicApiAxiosInstance;
