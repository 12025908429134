import jwt_decode from "jwt-decode";

/**
 * Decode JWT Token from API
 * @param jwtToken
 * @returns {{roles: *[], username: null}}
 */
export const decodeJwtToken = (jwtToken) => {
    const tokenClaimsObj = jwt_decode(jwtToken);
    return {
        userId: tokenClaimsObj?.userId,
        username: tokenClaimsObj?.sub,
        roles: tokenClaimsObj?.roles,
        accessToken: jwtToken
    }
}
