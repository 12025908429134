import opsYtkeApiAxiosInstance from "../OpsYtkeApi";

const urlPath = process.env.REACT_APP_WEB_API_BASE + '/applicationForm';

/**
 * Select / Update or create Application Form Data
 * @returns
 */
const applicationFormService = {
	getList: function (page = 0, size = 5, criteriaModel) {
		let criteriaBuilder = {};

		if (criteriaModel != null) {
			criteriaBuilder = Object.assign({}, criteriaModel);
		}

		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.post(
				urlPath + "/search",
				criteriaBuilder,
				{
					params: {
						page: page,
						size: size,
					}
				}
			)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getSingle: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	update: function (applicationFormModel) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.put(
				urlPath,
				applicationFormModel
			).then((res) => {
				const mappedResponse = res.data;
				resolve(mappedResponse);
			})
				.catch((error) => {
					reject(error);
				});
		});
	},
	create: function (applicationFormModel) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.post(
				urlPath,
				applicationFormModel
			).then((res) => {
				const mappedResponse = res.data;
				resolve(mappedResponse);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	approveBeneficiaryApplicationForm: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(
				"/applicationFormManagement/approve/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	rejectBeneficiaryApplicationForm: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(
				"/applicationFormManagement/reject/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	sendToBeneficiaryApplicationForm: function (applicationFormId, comments) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.post(
				"/applicationFormManagement/sendBackToBeneficiary/" + applicationFormId,
				{ "comments": comments }
			)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	sendToBackOfficerApplicationForm: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(
				urlPath + "/sendBackToBackOfficer/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	saveFile: function (applicationFormId, file) {
		return opsYtkeApiAxiosInstance.post(
			urlPath + "/addApplicationFormFile/" + applicationFormId,
			{ 
				"comments": file 
			},
			{
				headers: { "Content-Type": "multipart/form-data" }
			}
		);
	},
	getFile: function (applicationFormId) {
		return opsYtkeApiAxiosInstance.get(
			urlPath + "/getApplicationFormFile/" + applicationFormId
		);
	},
};

export default applicationFormService;
