import React from 'react';
import {Row, Card, Table, Nav, Pagination} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import LottieAnimationComponent from "../lottieAnimationComponent/LottieAnimationComponent";
import lottieAnimationSourceFile from '../../assets/lottieAnimations/notFoundAnimations/629-empty-box'
import i18next from "../../translations/i18nConfigInstance";

const constructTableHeaderTitles = (tableHeaderTitles) => {
    return tableHeaderTitles.map((value, index) => {
        return (
            <th className="border-bottom" style={{fontSize: 13, fontWeight: 900,}} key={`row-${index}`}>{value}</th>
        )
    })
};

type Props = {
    recordsData: Array,
    tableHeaderTitles: Array,
    tableRowComponent: any,
    pendingGettingRecordsData: boolean,
    errorGettingRecordsData: boolean,
    serverSidePaginationEnabled: boolean,
    paginatedData: any,
    serverSidePaginationServiceCallback: Function,
    totalItemsPerPage: number
}

class GenericTableComponent extends React.Component<Props> {

    render() {
        const {
            pendingGettingRecordsData,
            errorGettingRecordsData,
            recordsData,
            tableHeaderTitles,
            tableRowComponent,
            stickyLastColumn = true,
            serverSidePaginationEnabled = false,
            paginatedData,
            serverSidePaginationServiceCallback,
            totalItemsPerPage = 10
        } = this.props;

        return (
            <>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body
                        className={"pt-0 stickyLastColumn " + (stickyLastColumn === true ? "" : "nonActionDatatable")}>

                        <>

                            <Table hover className="user-table align-items-center">

                                <thead className="thead-light">
                                <tr>
                                    {constructTableHeaderTitles(tableHeaderTitles)}
                                </tr>
                                </thead>

                                {!pendingGettingRecordsData && !errorGettingRecordsData && recordsData && recordsData.length > 0 &&
                                <tbody>
                                {recordsData.map((rowRecordData, index) =>
                                    React.cloneElement(tableRowComponent, {
                                        key: index,
                                        recordData: rowRecordData,
                                        rowIndex: index
                                    })
                                )}
                                </tbody>
                                }
                            </Table>

                            {pendingGettingRecordsData &&
                            <div className="d-flex justify-content-center pt-5">
                                <BeatLoader
                                    size={35}
                                    color={"#4A5073"}
                                    loading={pendingGettingRecordsData}
                                />
                            </div>}

                            {pendingGettingRecordsData || errorGettingRecordsData || (recordsData && recordsData.length === 0) &&
                            <div className={"centerContent"}>
                                <Row>
                                    {!pendingGettingRecordsData && (errorGettingRecordsData || (recordsData && recordsData.length === 0)) &&
                                    <div className="pt-1">
                                        <Row>
                                            <LottieAnimationComponent
                                                animationHeight={150}
                                                animationWidth={150}
                                                animationSourceFile={lottieAnimationSourceFile}/>
                                        </Row>

                                        <Row>
                                            <div className={'customBadgeError'}>
                                                {i18next.t("generic.noDataFound")}
                                            </div>
                                        </Row>
                                    </div>}
                                </Row>
                            </div>}

                        </>
                    </Card.Body>
                </Card>

                {serverSidePaginationEnabled &&
                <Card>
                    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                        <Nav>
                            <Pagination className="mb-2 mb-lg-0">

                                <Pagination.First
                                    disabled={paginatedData?.currentPage === 0}
                                    onClick={() => serverSidePaginationServiceCallback(0, totalItemsPerPage)}
                                >
                                    {i18next.t("generic.pagination.first")}
                                </Pagination.First>

                                <Pagination.Prev
                                    disabled={paginatedData?.currentPage === 0}
                                    onClick={() => serverSidePaginationServiceCallback(paginatedData?.currentPage > 0 ? paginatedData?.currentPage - 1 : 0, totalItemsPerPage)}
                                >
                                    {i18next.t("generic.pagination.previous")}
                                </Pagination.Prev>

                                {[...Array(paginatedData?.totalPages)].map((x, index) => {

                                    if (index >= paginatedData?.currentPage - 5 && index < paginatedData?.currentPage + 6) {
                                        return (
                                            <Pagination.Item
                                                key={index}
                                                active={paginatedData?.currentPage === index}
                                                onClick={() => serverSidePaginationServiceCallback(index, totalItemsPerPage)}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}

                                <Pagination.Next
                                    disabled={paginatedData?.currentPage === paginatedData?.totalPages - 1}
                                    onClick={() => serverSidePaginationServiceCallback(paginatedData?.currentPage < paginatedData?.totalPages ? paginatedData?.currentPage + 1 : 1, totalItemsPerPage)}
                                >
                                    {i18next.t("generic.pagination.next")}
                                </Pagination.Next>

                                <Pagination.Last
                                    disabled={paginatedData?.currentPage === paginatedData?.totalPages - 1}
                                    onClick={() => serverSidePaginationServiceCallback(paginatedData?.totalPages - 1, totalItemsPerPage)}
                                >
                                    {i18next.t("generic.pagination.last")}
                                </Pagination.Last>

                            </Pagination>
                        </Nav>
                        <small className="fw-bold">
                            {i18next.t("generic.pagination.pageIndicatorOfTotalPages", {
                                currentPageNumber: paginatedData?.currentPage + 1,
                                totalPagesNumber: paginatedData?.totalPages
                            })}
                        </small>
                    </Card.Footer>
                </Card>}

            </>
        )
    }
}

export default GenericTableComponent;
