export const AddressModel = {
    id: null,
    state: null,
    municipality: null,
    address: null,
    addressNumber: null,
    zipCode: null,
    validFrom: "2021-06-27T21:00:00.000+00:00",
    validTo: "2021-06-27T21:00:00.000+00:00",
    notes: "",
    comments: "",
    residenceType: {
        id: null,
        description: null
    },
    main: false
}
