// =========================================================
// * OPS-YTKE Back office React Platform
// =========================================================

import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Router} from "react-router-dom";
import {Provider} from "react-redux";
import 'react-notifications-component/dist/theme.css'
import ReactNotification from "react-notifications-component";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import ScrollToTop from "./components/ScrollToTop";
import rootStore from "./store/configureStore";
import AuthManagerComponent from "./components/authManagerComponent/AuthManagerComponent";
import history from "./routing/history";

// Redux Store
const store = rootStore;

ReactDOM.render(
    <Provider store={store}>
        <HashRouter history={history}>
            <ScrollToTop/>
            <ReactNotification />
            <AuthManagerComponent/>
        </HashRouter>
    </Provider>,
    document.getElementById("root")
);
