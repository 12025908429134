import axios from 'axios';
import authHeader from "../authorization/authHeader";
import opsYtkeApiAxiosInstance from "../OpsYtkeApi";

const urlPath = process.env.REACT_APP_WEB_API_BASE + '/report';

/**
 * Reporting service / pdf / files
 * @returns
 */
const reportService = {
	getTestReport() {
		axios({
			url: urlPath, //your url
			method: 'GET',
			responseType: 'blob', // important
			headers: authHeader()
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'file.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	},

	byAgeGroup() {
		return opsYtkeApiAxiosInstance.get(urlPath + "/byAgeGroup")
	},
	byGender() {
		return opsYtkeApiAxiosInstance.get(urlPath + "/byGender");
	},
	byMaritalStatus() {
		return opsYtkeApiAxiosInstance.get(urlPath + "/byMaritalStatus");
	},
	byApplicationType() {
		return opsYtkeApiAxiosInstance.get(urlPath + "/byApplicationType")
	}

};

export default reportService;
