import i18next from "../../../translations/i18nConfigInstance";

export const insuranceCoverageSchema = {
    title: i18next.t("beneficiaryForm.insuranceCoveragesTitle"),
    required:[
        //"id",
        "insurer",
        "insuranceCapacityType"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t('beneficiaryForm.sessionsAndReferences.id'),
        //     type:"integer",
        // },
        insurer:{
            label: i18next.t('beneficiaryForm.insuranceCoverages.insurer'),
            type:"string"
        },
        insuranceCapacityType: {
            title: i18next.t("beneficiaryForm.insuranceCoverages.insuranceCapacityType"),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t("beneficiaryForm.insuranceCoverages.insuranceCapacityType"),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        },
    }
};
