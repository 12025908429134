
const ROLE_BENEFICIARY = process.env.REACT_APP_AUTH_USER_ROLE_BENEFICIARY;
const ROLE_BACK_OFFICER = process.env.REACT_APP_AUTH_USER_ROLE_BACKOFFICER;

export const Routes = {
    // Home Page
    HomePage: { path: "/" },

    // Beneficiary's Form Pages
    BeneficiaryListPageRoute: { path: "/beneficiary", roles: [ROLE_BACK_OFFICER] },
    BeneficiaryFormRoute: { path: "/beneficiary/:action/:userId", roles: [ROLE_BENEFICIARY, ROLE_BACK_OFFICER] },
    BeneficiaryFormRouteNew: { path: "/beneficiary/:action", roles: [ROLE_BENEFICIARY] },

    // Beneficiary's Application Form Pages
    BeneficiaryApplicationFormsListPageRoute: { path: "/applicationForm", roles: [ROLE_BENEFICIARY, ROLE_BACK_OFFICER] },
    BeneficiaryApplicationFormRoute: { path: "/applicationForm/:action/:applicationFormID", roles: [ROLE_BENEFICIARY, ROLE_BACK_OFFICER] },
    BeneficiaryApplicationFormRouteNew: { path: "/applicationForm/:action", roles: [ROLE_BENEFICIARY] },

    //Charts
    ChartPageRoute: { path: "/chart", roles: [ROLE_BACK_OFFICER] },

    //Available Actions on the system
    beneficiaryAvailableActionsByMunicipality: { path: "/municipalityActions", roles: [ROLE_BENEFICIARY, ROLE_BACK_OFFICER] },

    // pages
    DashboardOverview: { path: "/dashboard" },
    Presentation: { path: "/presentation" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    SignupWithCode: { path: "/sign-up/:code" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};
