import React from 'react';
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import i18next from "../../translations/i18nConfigInstance";

type Props = {
    firstName: string,
    lastName: string,
    amka: string,
    afm: string,
    title: string
}

class GenericBeneficiaryDetailsComponent extends React.Component<Props> {

    render() {
        const {
            firstName,
            lastName,
            amka,
            afm,
            title
        } = this.props;

        return(
            <Card border="light" className="bg-success shadow-sm text-white">
                <Card.Body>

                    {title &&
                    <Row>
                        <Col xs={12} xl={12}>
                            <h5 className="mb-4">
                                <FontAwesomeIcon icon={faCheckCircle} className="icon icon-xs me-3"/>
                                {title}
                            </h5>
                        </Col>
                    </Row>}

                    <Row>
                        <Col md={6}>
                            <h6>{i18next.t('beneficiaryForm.firstName')}</h6>
                            <p>{firstName}</p>
                        </Col>

                        <Col md={6}>
                            <h6>{i18next.t('beneficiaryForm.lastName')}</h6>
                            <p>{lastName}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <h6>{i18next.t('beneficiaryForm.amka')}</h6>
                            <p>{amka}</p>
                        </Col>

                        <Col md={6}>
                            <h6>{i18next.t('beneficiaryForm.afm')}</h6>
                            <p>{afm}</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        )
    }
}

export default GenericBeneficiaryDetailsComponent;
