import i18next from "../../../translations/i18nConfigInstance";

export const socialProgramSchema = {
    title: i18next.t("beneficiaryForm.sessionsAndReferencesTitle"),
    required:[
        //"id",
        "socialInstitution",
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t('beneficiaryForm.sessionsAndReferences.id'),
        //     type:"integer",
        // },
        socialInstitution:{
            label: i18next.t('beneficiaryForm.socialProgramsToParticipate.socialInstitution'),
            type:"string"
        },
        socialProgramCategory: {
            title: i18next.t("beneficiaryForm.socialProgramsToParticipate.socialProgramCategory"),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t("beneficiaryForm.socialProgramsToParticipate.socialProgramCategory"),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        },
    }
};
