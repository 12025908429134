import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import { Col, Row } from "@themesberg/react-bootstrap";
import { AutoField, AutoForm, SelectField } from "uniforms-bootstrap5";
import { createUniformSchema } from "../../../../../utils/uniformsSchemaBridgeGenerator";
import { workSchema } from "../../../../../schema/beneficiaryFormSchemas/nested/workSchema";
import domainsService from "../../../../../_services/domainsServices/domainsService";
import { WorkModel } from "../../../../../model/beneficiary/workModel";

type Props = {
	openFormModal: boolean,
	modalTitle: String,
	onClosingModalCallback: Function,
	onSaveButtonCallback: Function,
	okButtonTitle: String,
	recordData: Object,
	isEditMode: boolean,
	isViewMode: boolean,
	isCreateMode: boolean,
	isEditModalFormState: boolean,
	isViewModalFormState: boolean,
	isCreateModalFormState: boolean,
}

class WorkFormComp extends React.Component<Props> {

	state = {
		localRecordData: Object.assign({}, this.props.recordData),

		//ResidenceTypes State
		pendingGettingEmploymentStatusesTypes: false,
		errorGettingEmploymentStatusesTypes: false,
		employmentStatusesTypes: null,
	};

	_workFormRef = null;

	/**
	 * Creates the Uniform Schema Bridge
	 * @returns {JSONSchemaBridge}
	 */
	_workUniformSchema = createUniformSchema(workSchema);

	componentDidMount() {
		const {
			// Parent Form State
			isCreateMode,
			isEditMode,
			isViewMode,

			// Modal Form State
			isEditModalFormState,
			isViewModalFormState,
			isCreateModalFormState,
		} = this.props;

		if (isCreateModalFormState) {
			this.setState({
				localRecordData: Object.assign({}, WorkModel)
			})
		}

		this.fetchEmploymentStatusesTypes();
	}

	componentWillUnmount() {
		this._workFormRef = null;
	}

	/**
	 * Called every time form onSubmit occurs
	 * On Submit we update the parent model by calling it's callback to update records data
	 * witch comes back to us from parent once again via props
	 * @param model
	 */
	updateModelCallback = (model) => {
		if (model) {
			if (model.employmentStatus && model.employmentStatus.id) {
				model.employmentStatus.id = parseInt(model.employmentStatus.id);
			}
		}

		this.setState({
			localRecordData: Object.assign({}, model)
		});
	};

	/**
	 *  In case of Autoform of single
	 */
	onSaveButtonPress = () => {
		const {
			localRecordData
		} = this.state;

		const {
			onSaveButtonCallback
		} = this.props;

		this._workFormRef.submit()
			.then(() => {
				onSaveButtonCallback(localRecordData);
			}).catch((error) => {
				console.log("Form has validation errors", error);
			});
	};

	fetchEmploymentStatusesTypes = () => {
		this.setState({
			pendingGettingEmploymentStatusesTypes: true,
		});

		domainsService.getEmploymentStatuses()
			// In success we split model to master/details
			// so that we can easily handle them on separated forms
			.then((response) => {
				const uniformObjCompatible = response.map((item, index) => {
					return {
						label: item.description,
						value: item.id
					}
				});

				this.setState({
					pendingGettingEmploymentStatusesTypes: false,
					errorGettingEmploymentStatusesTypes: false,
					employmentStatusesTypes: uniformObjCompatible,
				})
			})
			.catch((error) => {
				console.log("Error getting Residence Types Data: ", error);
				this.setState({
					pendingGettingEmploymentStatusesTypes: false,
					errorGettingEmploymentStatusesTypes: true
				})
			});
	}

	render() {
		const {
			openFormModal,
			onClosingModalCallback,
			okModalButtonCallback,
			okButtonTitle,
			modalTitle,
			isViewMode,
			isEditMode,
			isCreateMode,
			isEditModalFormState,
			isViewModalFormState,
			isCreateModalFormState,
		} = this.props;

		const {
			localRecordData,

			//ResidenceTypes State
			pendingGettingEmploymentStatusesTypes,
			errorGettingEmploymentStatusesTypes,
			employmentStatusesTypes,
		} = this.state;

		return (
			<GenericModalComponent
				showOkButton={!isViewModalFormState}
				showCancelButton={true}
				okModalButtonTitle={okButtonTitle}
				okButtonCallback={this.onSaveButtonPress}
				closeModalCallback={onClosingModalCallback}
				isModalVisible={openFormModal}
				modalTitle={modalTitle}
				isModalFullScreen={false}
				modalSize={'lg'}
			>

				<Row className={"autoFormCustom"}>
					<Col xs={12} xl={12}>
						<AutoForm
							ref={(form) => {
								this._workFormRef = form
							}}
							schema={this._workUniformSchema}
							onChangeModel={(model) => this.updateModelCallback(model)}
							model={localRecordData ? localRecordData : {}}
							readOnly={isViewMode}
							showInlineError
							placeholder
						>
							<Row>
								<Col xs={12} xl={10}>
									<AutoField name={"oaedRegistrationNumber"} />
								</Col>
							</Row>
							{localRecordData.oaedRegistrationNumber &&
								<Row>
									<Col xs={12} xl={4}>
										<AutoField name={"oaedRegistrationDate"} />
									</Col>
								</Row>
							}

							<Row>
								<Col xs={12} xl={6}>
									<AutoField name={"workPosition"} />
								</Col>
							</Row>

							<Row className={"mt-4 mb-4"}>
								<Col xs={12} xl={4}>
									<SelectField
										name="employmentStatus.id"
										options={employmentStatusesTypes}
									/>
								</Col>
							</Row>

							{/* <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"validFrom"}/>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"validTo"}/>
                                </Col>
                            </Row> */}

							<Row>
								<Col xs={12} xl={10}>
									<AutoField name={"notes"} />
								</Col>
								<Col xs={12} xl={10}>
									<AutoField name={"comments"} />
								</Col>
							</Row>

						</AutoForm>
					</Col>
				</Row>
			</GenericModalComponent>
		)
	}
}

export default WorkFormComp;
