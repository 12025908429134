import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import {Col, Row} from "@themesberg/react-bootstrap";
import {AutoField, AutoForm, SelectField} from "uniforms-bootstrap5";
import {createUniformSchema} from "../../../../../utils/uniformsSchemaBridgeGenerator";
import {sessionAndRefSchema} from "../../../../../schema/beneficiaryFormSchemas/nested/sessionAndRefSchema";
import {SessionAndReferenceModel} from "../../../../../model/beneficiary/sessionAndReferenceModel";

type Props = {
    openFormModal: boolean,
    modalTitle: String,
    onClosingModalCallback: Function,
    onSaveButtonCallback: Function,
    okButtonTitle: String,
    recordData: Object,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    isEditModalFormState: boolean,
    isViewModalFormState: boolean,
    isCreateModalFormState: boolean,
}

class SessionAndRefFormComp extends React.Component<Props> {

    state = {
        localRecordData: Object.assign({}, this.props.recordData),
    };

    _sessionAndRefFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _sessionAndRefUniformSchema = createUniformSchema(sessionAndRefSchema);

    componentDidMount() {
        const {
            // Parent Form State
            isCreateMode,
            isEditMode,
            isViewMode,

            // Modal Form State
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        if (isCreateModalFormState) {
            this.setState({
                localRecordData: Object.assign({}, SessionAndReferenceModel)
            })
        }
    }

    componentWillUnmount() {
        this._sessionAndRefFormRef = null;
    }

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if(model) {

        }

        this.setState({
            localRecordData: Object.assign({}, model)
        });
    };

    /**
     *  In case of Autoform of single
     */
    onSaveButtonPress = () => {
        const {
            localRecordData
        } = this.state;

        const {
            onSaveButtonCallback
        } = this.props;

        this._sessionAndRefFormRef.submit()
            .then(() => {
                onSaveButtonCallback(localRecordData);
            }).catch((error) => {
            console.log("Form has validation errors", error);
        });
    };

    render() {
        const {
            openFormModal,
            onClosingModalCallback,
            okModalButtonCallback,
            okButtonTitle,
            modalTitle,
            isViewMode,
            isEditMode,
            isCreateMode,
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        const {
            localRecordData,
        } = this.state;

        return (
            <GenericModalComponent
                showOkButton={!isViewModalFormState}
                showCancelButton={true}
                okModalButtonTitle={okButtonTitle}
                okButtonCallback={this.onSaveButtonPress}
                closeModalCallback={onClosingModalCallback}
                isModalVisible={openFormModal}
                modalTitle={modalTitle}
                isModalFullScreen={false}
                modalSize={'lg'}
            >

                <Row className={"autoFormCustom"}>
                    <Col xs={12} xl={12}>
                        <AutoForm
                            ref={(form) => {
                                this._sessionAndRefFormRef = form
                            }}
                            schema={this._sessionAndRefUniformSchema}
                            onChangeModel={(model) => this.updateModelCallback(model)}
                            model={localRecordData ? localRecordData : {}}
                            readOnly={isViewMode}
                            showInlineError
                            placeholder
                        >
                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"subject"}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"programmed"} dateTimePicker={true} />
                                </Col>
                            </Row>

                            <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"startedAt"} dateTimePicker={true}/>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"endedAt"} dateTimePicker={true}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"notes"}/>
                                </Col>
                            </Row>

                        </AutoForm>
                    </Col>
                </Row>
            </GenericModalComponent>
        )
    }
}

export default SessionAndRefFormComp;
