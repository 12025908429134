import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import { Col, Row, Form } from "@themesberg/react-bootstrap";
import { AutoField, AutoForm, SelectField } from "uniforms-bootstrap5";
import i18next from "translations/i18nConfigInstance";
import { createUniformSchema } from "../../../../../utils/uniformsSchemaBridgeGenerator";
import { referralSchema } from "../../../../../schema/beneficiaryFormSchemas/nested/referralSchema";
import { ReferralsModel } from "../../../../../model/beneficiary/ReferralsModel";
import Parapompes from "dataStatic/beneficiary/parapompes.json";

type Props = {
	openFormModal: boolean,
	modalTitle: String,
	onClosingModalCallback: Function,
	onSaveButtonCallback: Function,
	okButtonTitle: String,
	recordData: Object,
	isEditMode: boolean,
	isViewMode: boolean,
	isCreateMode: boolean,
	isEditModalFormState: boolean,
	isViewModalFormState: boolean,
	isCreateModalFormState: boolean,
}

class ReferralFormComp extends React.Component<Props> {

	state = {
		localRecordData: Object.assign({}, this.props.recordData),
	};

	_referralFormRef = null;

	/**
	 * Creates the Uniform Schema Bridge
	 * @returns {JSONSchemaBridge}
	 */
	_referralUniformSchema = createUniformSchema(referralSchema);

	componentDidMount() {
		const {
			// Parent Form State
			isCreateMode,
			isEditMode,
			isViewMode,

			// Modal Form State
			isEditModalFormState,
			isViewModalFormState,
			isCreateModalFormState,
		} = this.props;

		if (isCreateModalFormState) {
			this.setState({
				localRecordData: Object.assign({}, ReferralsModel)
			})
		}

		let systemSelections = [];
		systemSelections.push({
			label: i18next.t('generic.pleaseSelect'),
			value: ""
		})
		Object.keys(Parapompes.system).forEach(function (key) {
			systemSelections.push({
				label: Parapompes.system[key].title,
				value: parseInt(key)
			})
		});


		let katigoriaYpiresiasSelectionsOut = [];
		Object.keys(Parapompes.system[1]).forEach(function (key) {
			if (key !== "title") {
				katigoriaYpiresiasSelectionsOut.push({
					label: Parapompes.system[1].[key].title,
					value: parseInt(key)
				})
			}
		});


		let katigoriaYpiresiasSelectionsIn = [];
		Object.keys(Parapompes.system[2]).forEach(function (key) {
			if (key !== "title") {
				katigoriaYpiresiasSelectionsIn.push({
					label: Parapompes.system[2].[key].title,
					value: parseInt(key)
				})
			}
		});

		this.setState({
			systemSelections: systemSelections,
			katigoriaYpiresiasSelectionsOut: katigoriaYpiresiasSelectionsOut,
			katigoriaYpiresiasSelectionsIn: katigoriaYpiresiasSelectionsIn
		});
		this.createKatigoriaDomisSelections(this.state.localRecordData.katigoriaYpiresias);
		this.createKinonikiDomiSelection(this.state.localRecordData.katigoriaDomis);
	}




	createKatigoriaDomisSelections(katigoriaYpiresias) {
		if (typeof katigoriaYpiresias === "undefined") {
			return;
		}
		katigoriaYpiresias = parseInt(katigoriaYpiresias);

		let tmpLocalRecordData = this.state.localRecordData;
		tmpLocalRecordData.katigoriaYpiresias = katigoriaYpiresias;
		this.setState({
			localRecordData: tmpLocalRecordData
		});
		let katigoriaDomisSelections = [];
		if (typeof this.state.localRecordData.katigoriaYpiresias !== "undefined" && this.state.localRecordData.katigoriaYpiresias !== "" && this.state.localRecordData.system == "2") {
			let tmpKatigoriaYpiresias = this.state.localRecordData.katigoriaYpiresias;
			Object.keys(Parapompes.system[2].[tmpKatigoriaYpiresias]).forEach(function (key2) {
				if (key2 !== "title") {
					katigoriaDomisSelections.push({
						label: Parapompes.system[2].[tmpKatigoriaYpiresias].[key2].title,
						value: parseInt(key2)
					});
				}

			});
		}
		this.setState({
			katigoriaDomisSelections: katigoriaDomisSelections
		});
	}

	createKinonikiDomiSelection(katigoriaDomis) {
		
		if (typeof katigoriaDomis === "undefined") {
			return;
		}

		katigoriaDomis = parseInt(katigoriaDomis);

		let tmpLocalRecordData = this.state.localRecordData;
		tmpLocalRecordData.katigoriaDomis = katigoriaDomis;
		this.setState({
			localRecordData: tmpLocalRecordData
		});


		let kinonikiDomiSelections = [];
		if (typeof this.state.localRecordData.katigoriaDomis !== "undefined" && this.state.localRecordData.katigoriaDomis !== "" && this.state.localRecordData.system == "2") {
			let tmpKatigoriaYpiresias = this.state.localRecordData.katigoriaYpiresias;
			let tmpKatigoriaDomis = this.state.localRecordData.katigoriaDomis;

			Object.keys(Parapompes.system[2].[tmpKatigoriaYpiresias].[tmpKatigoriaDomis]).forEach(function (key2) {
				if (key2 !== "title") {
					kinonikiDomiSelections.push({
						label: Parapompes.system[2].[tmpKatigoriaYpiresias].[tmpKatigoriaDomis].[key2].title,
						value: parseInt(key2)
					});
				}

			});
		}
		this.setState({
			kinonikiDomiSelections: kinonikiDomiSelections
		});
	}


	setKinonikiDomi(kinonikiDomi) {
		let tmpLocalRecordData = this.state.localRecordData;
		tmpLocalRecordData.kinonikiDomi = parseInt(kinonikiDomi);
		this.setState({
			localRecordData: tmpLocalRecordData
		});
	}

	setSystem(system) {
		let tmpLocalRecordData = this.state.localRecordData;
		tmpLocalRecordData.system = parseInt(system);
		this.setState({
			localRecordData: tmpLocalRecordData
		});
	}

	setKatigoriaYpiresias(katigoriaYpiresias) {
		let tmpLocalRecordData = this.state.localRecordData;
		tmpLocalRecordData.katigoriaYpiresias = parseInt(katigoriaYpiresias);
		this.setState({
			localRecordData: tmpLocalRecordData
		});
	}



	// componentDidUpdate(pre,next){
	// 	this.createKatigoriaDomisSelections();
	// }


	componentWillUnmount() {
		this._referralFormRef = null;
	}

	/**
	 * Called every time form onSubmit occurs
	 * On Submit we update the parent model by calling it's callback to update records data
	 * witch comes back to us from parent once again via props
	 * @param model
	 */
	updateModelCallback = (model) => {
		if (model) {

		}

		this.setState({
			localRecordData: Object.assign({}, model)
		});
	};

	/**
	 *  In case of Autoform of single
	 */
	onSaveButtonPress = () => {
		const {
			localRecordData
		} = this.state;

		const {
			onSaveButtonCallback
		} = this.props;

		this._referralFormRef.submit()
			.then(() => {
				onSaveButtonCallback(localRecordData);
			}).catch((error) => {
				console.log("Form has validation errors", error);
			});
	};

	render() {
		const {
			openFormModal,
			onClosingModalCallback,
			okButtonTitle,
			modalTitle,
			isViewMode,
			isViewModalFormState,
		} = this.props;

		const {
			localRecordData,
			systemSelections,
			katigoriaYpiresiasSelectionsOut,
			katigoriaYpiresiasSelectionsIn,
			katigoriaDomisSelections,
			kinonikiDomiSelections
		} = this.state;

		return (
			<GenericModalComponent
				showOkButton={!isViewModalFormState}
				showCancelButton={true}
				okModalButtonTitle={okButtonTitle}
				okButtonCallback={this.onSaveButtonPress}
				closeModalCallback={onClosingModalCallback}
				isModalVisible={openFormModal}
				modalTitle={modalTitle}
				isModalFullScreen={false}
				modalSize={'lg'}
			>

				<Row className={"autoFormCustom"}>
					<Col xs={12} xl={12}>
						<AutoForm
							ref={(form) => {
								this._referralFormRef = form
							}}
							schema={this._referralUniformSchema}
							onChangeModel={(model) => this.updateModelCallback(model)}
							model={localRecordData ? localRecordData : {}}
							readOnly={isViewMode}
							showInlineError
							placeholder
						>
							<Row>
								<Col xs={12} xl={10}>
									<AutoField name={"whoIsCreated"} />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={10}>
									<AutoField name={"whoIsServed"} />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={10}>
									<AutoField name={"notes"} />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={10}>
									<SelectField
										name="system"
										options={systemSelections}
										onChange={event => this.setSystem(event)}
									/>
								</Col>
							</Row>
							{localRecordData.system === 1 &&
								<Row>
									<Col xs={12} xl={10}>
										<SelectField
											name="katigoriaYpiresias"
											options={katigoriaYpiresiasSelectionsOut}
											onChange={event => this.setKatigoriaYpiresias(event)}
										/>
									</Col>
								</Row>
							}

							{localRecordData.system === 2 &&
								<Row>
									<Col xs={12} xl={10}>
										<SelectField
											name="katigoriaYpiresias"
											options={katigoriaYpiresiasSelectionsIn}
											onChange={event => this.createKatigoriaDomisSelections(event)}
										/>
									</Col>
								</Row>
							}
							{localRecordData.katigoriaYpiresias !== null && localRecordData.system === 2 &&
								<Row>
									<Col xs={12} xl={10}>
										<SelectField
											name="katigoriaDomis"
											options={katigoriaDomisSelections}
											onChange={event => this.createKinonikiDomiSelection(event)}
										/>
									</Col>
								</Row>
							}
							{localRecordData.katigoriaDomis !== null && localRecordData.system === 2 &&
								<Row>
									<Col xs={12} xl={10}>
										<SelectField
											name="kinonikiDomi"
											options={kinonikiDomiSelections}
											onChange={event => this.setKinonikiDomi(event)}
										/>
									</Col>
								</Row>
							}
						</AutoForm>
					</Col>
				</Row>
			</GenericModalComponent>
		)
	}
}

export default ReferralFormComp;
