import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import i18next from "../../../translations/i18nConfigInstance";

export const SuccessApproveFormPromptComponent = ({showSuccessApproveFormPrompt, confirmActionCallback}) => {
    return(
        <div className={"sweetAlertCustomStyles"}>
            <SweetAlert
                show={showSuccessApproveFormPrompt}
                success
                confirmBtnText={i18next.t("prompts.successApproveFormAction.confirmButtonTitle")}
                confirmBtnBsStyle="success"
                title={i18next.t("prompts.successApproveFormAction.successApproveTitle")}
                onConfirm={confirmActionCallback}
                focusConfirmBtn
            >
                {i18next.t("prompts.successApproveFormAction.successApproveSubTitle")}
            </SweetAlert>
        </div>
    )
};
