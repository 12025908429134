import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";

export const financeSchema = {
    title: i18next.t("beneficiaryForm.addressesTitle"),
    required:[
        //"id",
        "income",
        "validFrom",
        "validTo",
        "vehicleType",
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.finances.id"),
        //     type:"integer",
        // },
        income:{
            label: i18next.t("beneficiaryForm.finances.income"),
            type:"number"
        },
        allowances:{
            label: i18next.t("beneficiaryForm.finances.allowances"),
            type:"number",
            minimum: 0,
            maximum: 999999999.999,
        },
        otherSources:{
            label: i18next.t("beneficiaryForm.finances.otherSources"),
            type:"number",
            minimum: 0,
            maximum: 999999999.999,
        },
        valueRealEstate:{
            label: i18next.t("beneficiaryForm.finances.valueRealEstate"),
            type:"number",
            minimum: 0,
            maximum: 999999999.999,
        },
        valueMovableProperty:{
            label: i18next.t("beneficiaryForm.finances.valueMovableProperty"),
            type:"number",
            minimum: 0,
            maximum: 999999999.999,
        },
        validFrom:{
            label: i18next.t("beneficiaryForm.finances.validFrom"),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        validTo:{
            label: i18next.t("beneficiaryForm.finances.validTo"),
            type:"string",
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        notes:{
            label: i18next.t("beneficiaryForm.finances.notes"),
            type:"string"
        },
        comments:{
            label: i18next.t("beneficiaryForm.finances.comments"),
            type:"string"
        },
        vehicleType: {
            title: i18next.t("beneficiaryForm.finances.vehicleType"),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    title: i18next.t("beneficiaryForm.finances.vehicleType"),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        }
    }
};
