import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import {Col, Row} from "@themesberg/react-bootstrap";
import {AutoField, AutoForm, SelectField} from "uniforms-bootstrap5";
import {createUniformSchema} from "../../../../../utils/uniformsSchemaBridgeGenerator";
import {insuranceCoverageSchema} from "../../../../../schema/beneficiaryFormSchemas/nested/insuranceCoverageSchema";
import domainsService from "../../../../../_services/domainsServices/domainsService";
import {InsuranceCoverageModel} from "../../../../../model/beneficiary/insuranceCoverageModel";

type Props = {
    openFormModal: boolean,
    modalTitle: String,
    onClosingModalCallback: Function,
    onSaveButtonCallback: Function,
    okButtonTitle: String,
    recordData: Object,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    isEditModalFormState: boolean,
    isViewModalFormState: boolean,
    isCreateModalFormState: boolean,
}

class InsuranceCoverageFormComp extends React.Component<Props> {

    state = {
        localRecordData: Object.assign({}, this.props.recordData),

        // InsuranceCapacityTypes state
        pendingGettingInsuranceCapacityTypes: false,
        errorGettingInsuranceCapacityTypes: false,
        insuranceCapacityTypes: null,
    };

    _insuranceCoverageFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _insuranceCoverageUniformSchema = createUniformSchema(insuranceCoverageSchema);

    componentDidMount() {
        const {
            // Parent Form State
            isCreateMode,
            isEditMode,
            isViewMode,

            // Modal Form State
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        if (isCreateModalFormState) {
            this.setState({
                localRecordData: Object.assign({}, InsuranceCoverageModel)
            })
        }

        this.fetchSpecialVulnerableCategories();
    }

    componentWillUnmount() {
        this._insuranceCoverageFormRef = null;
    }

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if(model) {
            if(model.insuranceCapacityType && model.insuranceCapacityType.id) {
                model.insuranceCapacityType.id = parseInt(model.insuranceCapacityType.id);
            }
        }

        this.setState({
            localRecordData: Object.assign({}, model)
        });
    };

    /**
     *  In case of Autoform of single
     */
    onSaveButtonPress = () => {
        const {
            localRecordData
        } = this.state;

        const {
            onSaveButtonCallback
        } = this.props;

        this._insuranceCoverageFormRef.submit()
            .then(() => {
                onSaveButtonCallback(localRecordData);
            }).catch((error) => {
            console.log("Form has validation errors", error);
        });
    };

    fetchSpecialVulnerableCategories = () => {
        this.setState({
            pendingGettingInsuranceCapacityTypes: true,
        });

        domainsService.getInsuranceCapacityTypes()
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {
                const uniformObjCompatible = response.map((item, index) => {
                    return {
                        label: item.description,
                        value: item.id
                    }
                });

                this.setState({
                    // InsuranceCapacityTypes state
                    pendingGettingInsuranceCapacityTypes: false,
                    errorGettingInsuranceCapacityTypes: false,
                    insuranceCapacityTypes: uniformObjCompatible,
                })
            })
            .catch((error) => {
                console.log("Error getting Insurance Capacity Types: ", error);
                this.setState({
                    pendingGettingInsuranceCapacityTypes: false,
                    errorGettingInsuranceCapacityTypes: true
                })
            });
    }

    render() {
        const {
            openFormModal,
            onClosingModalCallback,
            okModalButtonCallback,
            okButtonTitle,
            modalTitle,
            isViewMode,
            isEditMode,
            isCreateMode,
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        const {
            localRecordData,

            // InsuranceCapacityTypes State
            pendingGettingInsuranceCapacityTypes,
            errorGettingInsuranceCapacityTypes,
            insuranceCapacityTypes
        } = this.state;

        return (
            <GenericModalComponent
                showOkButton={!isViewModalFormState}
                showCancelButton={true}
                okModalButtonTitle={okButtonTitle}
                okButtonCallback={this.onSaveButtonPress}
                closeModalCallback={onClosingModalCallback}
                isModalVisible={openFormModal}
                modalTitle={modalTitle}
                isModalFullScreen={false}
                modalSize={'md'}
            >

                <Row className={"autoFormCustom"}>
                    <Col xs={12} xl={12}>
                        <AutoForm
                            ref={(form) => {
                                this._insuranceCoverageFormRef = form
                            }}
                            schema={this._insuranceCoverageUniformSchema}
                            onChangeModel={(model) => this.updateModelCallback(model)}
                            model={localRecordData ? localRecordData : {}}
                            readOnly={isViewMode}
                            showInlineError
                            placeholder
                        >
                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"insurer"}/>
                                </Col>
                            </Row>

                            <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={10}>
                                    <SelectField
                                        name="insuranceCapacityType.id"
                                        options={insuranceCapacityTypes}
                                    />
                                </Col>
                            </Row>

                        </AutoForm>
                    </Col>
                </Row>
            </GenericModalComponent>
        )
    }
}

export default InsuranceCoverageFormComp;
