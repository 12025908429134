import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import {Col, Row} from "@themesberg/react-bootstrap";
import {AutoField, AutoForm} from "uniforms-bootstrap5";
import {createUniformSchema} from "../../../../../utils/uniformsSchemaBridgeGenerator";
import {LanguageModel} from "../../../../../model/beneficiary/languageModel";
import {languageSchema} from "../../../../../schema/beneficiaryFormSchemas/nested/languageSchema";

type Props = {
    openFormModal: boolean,
    modalTitle: String,
    onClosingModalCallback: Function,
    onSaveButtonCallback: Function,
    okButtonTitle: String,
    recordData: Object,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    isEditModalFormState: boolean,
    isViewModalFormState: boolean,
    isCreateModalFormState: boolean,
}

class LanguageFormComp extends React.Component<Props> {

    state = {
        localRecordData: Object.assign({}, this.props.recordData),

        //ResidenceTypes State
        pendingGettingResidenceTypes: false,
        errorGettingResidenceTypes: false,
        residenceTypes: null,

        // Form Validation Errors Counter
        uniformValidationFormCounter: null,
        outsideUniformFieldsValidationErrorCounter: null,
        outsideUniformFieldsValidationErrors: null,
    };

    _languageFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _languageUniformSchema = createUniformSchema(languageSchema);

    componentDidMount() {
        const {
            // Parent Form State
            isCreateMode,
            isEditMode,
            isViewMode,

            // Modal Form State
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        if (isCreateModalFormState) {
            this.setState({
                localRecordData: Object.assign({}, LanguageModel)
            })
        }
    }

    componentWillUnmount() {
        this._languageFormRef = null;
    }

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    onFormValidationSuccess = (model) => {
        this.setState({
            localRecordData: Object.assign({}, model)
        });
    };

    validateForm = async () => {
        const {
            onSaveButtonCallback,

            // Form State
            isCreateMode,
            isEditMode,
            isViewMode,
        } = this.props;

        this.setState({
            uniformValidationFormCounter: null,
            outsideUniformFieldsValidationErrorCounter: null,
            outsideUniformFieldsValidationErrors: null
        });

        const {
            localRecordData,
        } = this.state;

        const formPromisesArray = [
            this._languageFormRef.submit(),
            this.validateFieldsOutsideUniform(),
        ];

        // Promise.allSettled returns the results of EVERY promise (rejected/resolved)
        // in the order they are imported for settle
        Promise.allSettled(formPromisesArray)
            .then((promisesResults) => {

                let validationFailed = false;

                // Catch validation errors for each form
                // as Promise.allSettled returns
                for (let i = 0; i < formPromisesArray.length; i++) {
                    if (promisesResults[i].status === 'rejected') {

                        validationFailed = true;

                        if (i === 0) {
                            this.setState({
                                uniformValidationFormCounter: promisesResults[i].reason?.details?.length,
                            });
                        }

                        if (i === 1) {
                            const customFieldErrors = promisesResults[i].reason;
                            this.setState({
                                outsideUniformFieldsValidationErrorCounter: customFieldErrors.length,
                                outsideUniformFieldsValidationErrors: customFieldErrors
                            });
                        }
                    }
                }

                if (!validationFailed) {
                    const modelToBeSent = Object.assign({}, localRecordData);
                    onSaveButtonCallback(modelToBeSent);
                } else {
                    console.log("Validation Errors", formPromisesArray);
                }
            });

    };

    validateFieldsOutsideUniform = async () => {
        return new Promise((resolve, reject) => {
            const {
                localRecordData
            } = this.state;

            const customFieldsErrors = [];
            const error = {
                fieldName: null,
                errorMessage: null
            }

           // TODO Put your business validations for custom fields here

            if (customFieldsErrors.length < 1) {
                resolve(null);
            } else {
                reject(customFieldsErrors);
            }
        });
    };

    render() {
        const {
            openFormModal,
            onClosingModalCallback,
            okModalButtonCallback,
            okButtonTitle,
            modalTitle,
            isViewMode,
            isEditMode,
            isCreateMode,
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        const {
            localRecordData,

            // Fields Outside Uniforms Errors
            outsideUniformFieldsValidationErrorCounter,
            outsideUniformFieldsValidationErrors
        } = this.state;

        return (
            <GenericModalComponent
                showOkButton={!isViewModalFormState}
                showCancelButton={true}
                okModalButtonTitle={okButtonTitle}
                okButtonCallback={this.validateForm}
                closeModalCallback={onClosingModalCallback}
                isModalVisible={openFormModal}
                modalTitle={modalTitle}
                isModalFullScreen={false}
                modalSize={'lg'}
            >

                <Row className={"autoFormCustom"}>
                    <Col xs={12} xl={12}>
                        <AutoForm
                            ref={(form) => {
                                this._languageFormRef = form
                            }}
                            schema={this._languageUniformSchema}
                            onChangeModel={(model) => this.onFormValidationSuccess(model)}
                            model={localRecordData ? localRecordData : {}}
                            readOnly={isViewMode}
                            showInlineError
                            placeholder
                            // validate="onChange"
                            // autosave={true}
                            //disabled={isViewMode}
                        >
                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"name"}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"spokenLanguage"}/>
                                </Col>
                            </Row>

                        </AutoForm>
                    </Col>
                </Row>
            </GenericModalComponent>
        )
    }
}

export default LanguageFormComp;
