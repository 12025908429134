export const educationWritingSpeakingLevelData = [
    {
        label: "Πολύ Χαμηλό",
        value: "VERY_LOW",
    },
    {
        label: "Χαμηλό",
        value: "LOW",
    },
    {
        label: "Μέτριο",
        value: "MID",
    },
    {
        label: "Καλό",
        value: "GOOD",
    },
    {
        label: "Πολύ καλό",
        value: "VERY_GOOD",
    },
];
