import React from 'react';
import {Button, ButtonGroup, Col, Form, InputGroup, Row, Card, FormGroup, FormCheck} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import i18next from "../../../../translations/i18nConfigInstance";
import domainsService from "../../../../_services/domainsServices/domainsService";
import GenericInputFieldWithSearchModalComponent
    from "../../../../components/genericInputFieldWithSearchModal/GenericInputFieldWithSearchModalComponent";
import beneficiaryService from "../../../../_services/beneficiaryServices/beneficiaryService";
import BeneficiarySearchFilterTableRowComponent
    from "./beneficiarySearchFilter/BeneficiarySearchFilterTableRowComponent";

const beneficiarySearchModalTableHeaderTitles = [
    i18next.t("beneficiaryForm.id"),
    i18next.t("beneficiaryForm.amka"),
    i18next.t("beneficiaryForm.afm"),
    i18next.t("beneficiaryForm.firstName"),
    i18next.t("beneficiaryForm.lastName"),
    i18next.t("beneficiaryForm.birthDate"),
    i18next.t("beneficiaryForm.gender"),
    i18next.t("beneficiaryForm.alive"),
    i18next.t("beneficiaryForm.active"),
    i18next.t("beneficiaryForm.approved"),
];

const initSearchFilters = {
    status: null,
    applicationFormType: null,
    beneficiaryId: null,
}

type Props = {
    pendingGettingRecords: boolean,
    filterSearchRequestCallback: Function,
    isBackOfficerUser: boolean
}

class ApplicationFormSearchFilterComponent extends React.Component<Props> {

    state = {
        searchFilters: initSearchFilters,
        isBeneficiarySearchModalOpen: false,

        // Application Form Types State
        pendingGettingApplicationFormTypes: false,
        errorGettingApplicationFormTypes: false,
        applicationFormTypes: null,
    }

    componentDidMount() {
        this.fetchApplicationFormTypesList();
    }

    fetchApplicationFormTypesList = () => {
        this.setState({
            pendingGettingApplicationFormTypes: true,
        });

        domainsService.getApplicationFormTypes()
            .then((response) => {
                this.setState({
                    pendingGettingApplicationFormTypes: false,
                    errorGettingApplicationFormTypes: false,
                    applicationFormTypes: response,
                })
            })
            .catch((error) => {
                console.log("Error getting Application Form Types List  records ", error);
                this.setState({
                    pendingGettingApplicationFormTypes: false,
                    errorGettingApplicationFormTypes: true,
                })
            });
    };

    doTheSearchFilterRequest = () => {
        const {
            searchFilters
        } = this.state;

        const {
            filterSearchRequestCallback
        } = this.props;

        filterSearchRequestCallback(0, 5, searchFilters);
    }

    onClearingSearchFilters = () => {
        this.setState({
            searchFilters: initSearchFilters
        }, () => {
            this.doTheSearchFilterRequest();
        });
    };

    setStatus = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.status = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    setApplicationFormType = (event) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.applicationFormType = event.target.value;

        this.setState({
            searchFilters: tmp
        });
    };

    toggleBeneficiarySearchModalOpen = (value) => {
        this.setState({
            isBeneficiarySearchModalOpen: value
        })
    }

    setBeneficiaryUserFilter = (data) => {
        const {
            searchFilters
        } = this.state;

        const tmp = Object.assign({}, searchFilters);
        tmp.beneficiaryId = data.id;

        this.setState({
            searchFilters: tmp
        }, () => {
            this.toggleBeneficiarySearchModalOpen(false);
        });
    }

    render() {

        const {
            // Records from Network Request
            pendingGettingRecords,
            isBackOfficerUser
        } = this.props;

        const {
            // Search Inputs state
            searchFilters: {
                beneficiaryId,
                applicationFormType,
                status,
            },

            isBeneficiarySearchModalOpen,

            // Application Form Types State
            pendingGettingApplicationFormTypes,
            errorGettingApplicationFormTypes,
            applicationFormTypes,
        } = this.state;

        return (
            <Card border="light" className="bg-secondary shadow-sm mb-4 mt-1">
                <Card.Body>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <Col xs={8} md={4} xl={4}>
                            {isBackOfficerUser &&
                            <GenericInputFieldWithSearchModalComponent
                                fieldValue={beneficiaryId}
                                fieldDescription={null}
                                fieldLabel={i18next.t('applicationForm.beneficiaryId')}
                                disabled={true}
                                searchButtonDisabled={pendingGettingRecords}
                                placeholder={beneficiaryId ? "" : i18next.t('applicationForm.searchBeneficiaryId')}
                                isModalOpen={isBeneficiarySearchModalOpen}
                                modalToggleCallback={this.toggleBeneficiarySearchModalOpen}
                                searchModalTitle={i18next.t('applicationForm.filterBasedOnBeneficiaryUser')}
                                modalSize={'xl'}
                                serviceToFetchPagedTableData={beneficiaryService.getList}
                                datatableColumnsNames={beneficiarySearchModalTableHeaderTitles}
                                tableRowComponent={
                                    <BeneficiarySearchFilterTableRowComponent
                                        onUpdateParentComponent={this.setBeneficiaryUserFilter}
                                    />
                                }
                                isInvalid={false}
                                errorMessage={null}
                            />}
                        </Col>
                    </div>

                    <div className=" h-100 d-flex justify-content-center align-items-center mt-4">
                        <Row>
                            <Col xs={6} xl={6}>
                                <Form.Group>
                                    <Form.Label>{i18next.t('applicationForm.status')}</Form.Label>
                                    <Form.Select
                                        disabled={pendingGettingRecords}
                                        defaultValue={status || ''}
                                        onChange={this.setStatus}
                                    >
                                        <option value={''}>{i18next.t('generic.statusAll')}</option>
                                        <option value={0}>{i18next.t('generic.pending')}</option>
                                        <option value={1}>{i18next.t('generic.approved')}</option>
                                        <option value={2}>{i18next.t('generic.rejected')}</option>
                                        <option value={3}>{i18next.t('generic.sentBackToBeneficiary')}</option>
                                        <option value={4}>{i18next.t('generic.sentBackToBackOfficer')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col xs={6} xl={6}>
                                <Form.Group>
                                    <Form.Label>{i18next.t('applicationForm.type')}</Form.Label>
                                    <Form.Select
                                        disabled={pendingGettingRecords || pendingGettingApplicationFormTypes}
                                        defaultValue={applicationFormType || ''}
                                        onChange={this.setApplicationFormType}
                                    >
                                        <option value={''}>{i18next.t('generic.all')}</option>
                                        {applicationFormTypes && applicationFormTypes.map(appFormType =>
                                            <option key={appFormType.id}
                                                    value={appFormType.id}>{appFormType.description}</option>
                                        )};
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className=" h-100 d-flex justify-content-center align-items-center mt-4">
                        <Row>
                            <Col xs={12}>
                                <ButtonGroup>
                                    <Button
                                        disabled={pendingGettingRecords}
                                        variant="warning"
                                        className="text-white"
                                        onClick={this.onClearingSearchFilters}

                                    >
                                        <FontAwesomeIcon icon={faTimesCircle} className="me-2"/>
                                        <span>{i18next.t("generic.clearSearchFilter")}</span>
                                    </Button>

                                    <Button
                                        disabled={pendingGettingRecords}
                                        variant="primary"
                                        className="text-white"
                                        onClick={this.doTheSearchFilterRequest}

                                    >
                                        <FontAwesomeIcon icon={faSearch} className="me-2"/>
                                        <span>{i18next.t("generic.search")}</span>
                                    </Button>

                                </ButtonGroup>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default ApplicationFormSearchFilterComponent;
