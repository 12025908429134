import React from 'react';
import GenericTableComponent from "../../../../components/genericTableComponent/GenericTableComponent";
import i18next from "../../../../translations/i18nConfigInstance";
import {Col, Row} from "@themesberg/react-bootstrap";
import {CreateNewButtonComponent} from "../../../../components/CreateNewButtonComponent";
import WorkTableRowComp from "./components/WorkTableRowComp";
import WorkFormComp from "./components/WorkFormComp";

const tableHeaderTitlesDemo = [
    i18next.t('beneficiaryForm.works.id'),
    i18next.t('beneficiaryForm.works.oaedRegistrationNumber'),
    i18next.t('beneficiaryForm.works.oaedRegistrationDate'),
    i18next.t('beneficiaryForm.works.workPosition'),
    i18next.t('beneficiaryForm.works.employmentStatus'),
    // i18next.t('beneficiaryForm.works.validFrom'),
    // i18next.t('beneficiaryForm.works.validTo'),
    i18next.t('beneficiaryForm.works.notes'),
    i18next.t('beneficiaryForm.works.comments'),
    i18next.t('generic.actions')
];

type Props = {
    dataArray: Array,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    updateModelParentCallback: Function
}

class WorksListComponent extends React.Component<Props> {

    state = {
        selectedObjectIndex: null,
        selectedItemObject: null,
        openFormModal: false,
        modalTitle: null,
        okButtonTitle: null,
        isEditModalState: false,
        isViewModalState: false,
        isCreateModalState: false,
    };

    /**
     * Updates the parent model containing
     * current data schema with local schema refreshed data
     * via callback
     * @param data
     */
    updateParentModel = (data) => {
        const {
            updateModelParentCallback
        } = this.props;
        updateModelParentCallback(data);
    };

    onNewButtonPress = () => {
        this.setState({
            selectedObjectIndex: null,
            selectedItemObject: {},
            openFormModal: true,
            modalTitle:  i18next.t('beneficiaryForm.works.workTitleNew'),
            okButtonTitle: i18next.t("generic.save"),
            isEditModalState: false,
            isViewModalState: false,
            isCreateModalState: true,
        })
    };

    onClosingModal = () => {
        this.setState({
            selectedObjectIndex: null,
            selectedItemObject: null,
            openFormModal: false,
            modalTitle: null,
            isEditModalState: false,
            isViewModalState: false,
            isCreateModalState: false,
        })
    };

    onViewTableRowItemForm = (data, index) => {
        this.setState({
            selectedObjectIndex: index,
            selectedItemObject: data,
            openFormModal: true,
            modalTitle:  i18next.t('beneficiaryForm.works.workTitleView'),
            okButtonTitle: i18next.t("generic.ok"),
            isEditModalState: false,
            isViewModalState: true,
            isCreateModalState: false,
        })
    };

    onEditTableRowItemForm = (data, index) => {
        this.setState({
            selectedObjectIndex: index,
            selectedItemObject: data,
            openFormModal: true,
            modalTitle:  i18next.t('beneficiaryForm.works.workTitleEdit'),
            okButtonTitle: i18next.t("generic.update"),
            isEditModalState: true,
            isViewModalState: false,
            isCreateModalState: false,
        })

    };

    /**
     * Row deletion with instant parent model update
     * @param data
     */
    onDeleteTableRowItemForm = (data, index) => {
        const {
            dataArray,
        } = this.props;

        if (index !== null) {
            dataArray.splice(index, 1);

            // TODO Prompt Modal has to be opened
            this.updateParentModel(dataArray);
        }

    };

    onSaveData = (data) => {
        if (data) {
            const {
                dataArray,
            } = this.props;

            const {
                isEditModalState,
                isViewModalState,
                isCreateModalState,
                selectedObjectIndex,
            } = this.state;

            if (isEditModalState) { // UPDATE
                if (selectedObjectIndex !== null) {
                    dataArray[selectedObjectIndex] = data;
                    this.updateParentModel(dataArray);
                }
            } else if (isCreateModalState) { // CREATE
                let tempDataArray = [];
                if(dataArray) {
                    tempDataArray = Object.assign([], dataArray);
                    tempDataArray.push(data);
                } else {
                    tempDataArray.push(data);
                }
                this.updateParentModel(tempDataArray);
            }

            this.onClosingModal();
        }
    };

    render() {
        const {
            isViewMode,
            isEditMode,
            isCreateMode,
            dataArray
        } = this.props;

        const {
            selectedItemObject,
            openFormModal,
            modalTitle,
            okButtonTitle,
            isEditModalState,
            isViewModalState,
            isCreateModalState
        } = this.state;

        return(
            <>
                <Row>
                    <Col xs={12} xl={12}>

                        <div className="table-settings mt-2 mb-2">
                            <Row className="justify-content-between align-items-center">
                                <Col className="ps-md-0 text-end float-end">
                                    <CreateNewButtonComponent
                                        isDisabled={isViewMode}
                                        onPressCallback={this.onNewButtonPress}/>
                                </Col>
                            </Row>
                        </div>

                        <GenericTableComponent
                            recordsData={dataArray}
                            pendingGettingRecordsData={false}
                            errorGettingRecordsData={null}
                            tableHeaderTitles={tableHeaderTitlesDemo}
                            tableRowComponent={
                                <WorkTableRowComp
                                    onViewRowItemFormCallback={this.onViewTableRowItemForm}
                                    onEditRowItemFormCallback={this.onEditTableRowItemForm}
                                    onDeleteRowItemFormCallback={this.onDeleteTableRowItemForm}
                                    isViewMode={isViewMode}
                                    isEditMode={isEditMode}
                                    isCreateMode={isCreateMode}
                                />
                            }
                        />

                        {selectedItemObject &&
                        <WorkFormComp
                            openFormModal={openFormModal}
                            modalTitle={modalTitle}
                            onClosingModalCallback={this.onClosingModal}
                            onSaveButtonCallback={this.onSaveData}
                            recordData={selectedItemObject}
                            isEditMode={isEditMode}
                            isViewMode={isViewMode}
                            isCreateMode={isCreateMode}
                            okButtonTitle={okButtonTitle}
                            isEditModalFormState={isEditModalState}
                            isViewModalFormState={isViewModalState}
                            isCreateModalFormState={isCreateModalState}
                        />}

                    </Col>
                </Row>
            </>

        )
    }
}

export default WorksListComponent;
