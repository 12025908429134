import i18next from "../../../translations/i18nConfigInstance";
import {educationWritingSpeakingLevelData} from "../../../dataStatic/beneficiary/educationWritingSpeakingLevelData";

export const educationSchema = {
    title: i18next.t("beneficiaryForm.educationTitle"),
    required:[
        //"id",
        // "specialization",
        // "greekSpeakingLevel",
        // "greekWritingLevel",
        // "educationLevel"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.languages.id"),
        //     type:"integer",
        // },
        specialization:{
            label: i18next.t("beneficiaryForm.education.specialization"),
            oneOf: [{ type: "string" },  { type: "null" }],
            maxLength:256,
            minLength:0,
        },
        greekSpeakingLevel:{
            label: i18next.t("beneficiaryForm.education.greekSpeakingLevel"),
            oneOf: [{ type: "string" },  { type: "null" }],
            options: educationWritingSpeakingLevelData,
            uniforms: {
                type: "String",
                placeholder: i18next.t("generic.pleaseSelect")
            },
            // "enum":[
            //     "VERY_LOW",
            //     "LOW",
            //     "MID",
            //     "GOOD",
            //     "VERY_GOOD"
            // ]
        },
        greekWritingLevel:{
            label: i18next.t("beneficiaryForm.education.greekWritingLevel"),
            oneOf: [{ type: "string" },  { type: "null" }],
            options: educationWritingSpeakingLevelData,
            uniforms: {
                type: "String",
                placeholder: i18next.t("generic.pleaseSelect")
            },
            // "enum":[
            //     "VERY_LOW",
            //     "LOW",
            //     "MID",
            //     "GOOD",
            //     "VERY_GOOD"
            // ]
        },
        educationLevel: {
            //title: i18next.t("beneficiaryForm.educationTitle"),
            required:[
                // "id"
            ],
            type:"object",
            properties:{
                id:{
                    label: i18next.t("beneficiaryForm.education.educationLevel"),
                    oneOf: [{ type: "integer" },  { type: "null" }],
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                },
            }
        }
    }
};
