import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import i18next from "../../../translations/i18nConfigInstance";

export const SuccessRejectFormPromptComponent = ({showSuccessRejectFormPrompt, confirmActionCallback}) => {
    return(
        <div className={"sweetAlertCustomStyles"}>
            <SweetAlert
                show={showSuccessRejectFormPrompt}
                success
                confirmBtnText={i18next.t("prompts.successRejectFormAction.confirmButtonTitle")}
                confirmBtnBsStyle="success"
                title={i18next.t("prompts.successRejectFormAction.successRejectTitle")}
                onConfirm={confirmActionCallback}
                focusConfirmBtn
            >
                {i18next.t("prompts.successRejectFormAction.successRejectSubTitle")}
            </SweetAlert>
        </div>
    )
};
