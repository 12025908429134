import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Routes } from "../../routing/routes";
import BgImage from "../../assets/img/beneficiary-monitoring.jpg";
import AuthService from "../../_services/authorization/authService";
import { withStyles } from '@material-ui/core/styles';
import Copyright from './Copyright';
import i18next from '../../translations/i18nConfigInstance';
import { Card, Row } from "@themesberg/react-bootstrap";

const styles = (theme) => ({
    root: {
        height: '100vh',
        //  opacity: 0,
        transition: '2s opacity'
    },
    fadeIn: {
        opacity: 1,
    },
    image: {
        backgroundImage: 'url(' + BgImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        marginTop: '2.5em'
    },
    centerLogo: {
        // display: 'flex',
        // justifyContent: 'center',
        // marginTop: '10px',
        // backgroundColor: 'white'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'none'
    },
    cardRoot: {
        maxWidth: 345,
    },
    cardMedia: {
        height: 140,
    }
});

class SignInPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            message: "",
            errors: {
                username: "",
                password: ""
            },
            userError: "",
            passwordError: "",
            validUsername: true,
            validPassword: true
        };
    }

    componentDidMount() {
        let el = document.querySelector('#root');
        el.classList.add('fadeIn');
    }

    onChangeUsername = (e) => {
        this.setState({
            username: e.target.value
        });
    };

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    };

    validate = () => {
        let errors = {};
        let isValid = true;
        if (this.state.password === undefined || this.state.password === '') {
            errors["password"] = "Please provide a password.";
            this.setState({ validPassword: false });
            isValid = false;
        } else {
            errors["password"] = "";
            this.setState({ validPassword: true });
        }
        if (this.state.username === undefined || this.state.username === '') {
            errors['username'] = "Please provide a username";
            this.setState({ validUsername: false });
            isValid = false;
        } else {
            errors["username"] = "";
            this.setState({ validUsername: true });
        }
        this.setState({
            userError: errors.username,
            passwordError: errors.password
        });
        return isValid;
    };

    handleLogin = (e) => {
        e.preventDefault();
        if (this.validate()) {
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                    this.props.history.push(Routes.HomePage.path);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            );
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

                    <Card border="light" className="bg-white shadow-sm"
                        style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                        <Card.Body>
                            <div className={classes.paper}>

                                <Row>
                                    <h4 style={{ textAlign: 'center', color: "#606066" }}>
                                        {i18next.t('generic.opsYtke')}
                                    </h4>
                                </Row>

                                <Row>
                                    <h6 style={{ textAlign: 'center', color: "#606066" }}>
                                        {i18next.t('generic.opsYtkeDescription')}
                                    </h6>
                                </Row>

                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h6" variant="h6">
                                    {i18next.t('authPages.signIn')}
                                </Typography>
                                <form className={classes.form}>
                                    <TextField
                                        InputProps={{
                                            error: !this.state.validUsername
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={this.state.username}
                                        onChange={this.onChangeUsername}
                                        id="username"
                                        label={i18next.t('authPages.username')}
                                        name="username"
                                        autoComplete="email"
                                        helperText={this.state.userError}

                                    />
                                    <TextField
                                        InputProps={{
                                            error: !this.state.validPassword
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={i18next.t('authPages.password')}
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.onChangePassword}
                                        helperText={this.state.passwordError}
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label={i18next.t('authPages.rememberMe')}
                                    />
                                    <Button
                                        onClick={this.handleLogin}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        {i18next.t('authPages.signIn')}
                                    </Button>
                                    {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    )}
                                    <Grid container>
                                        {/* <Grid item xs>
                                            <Link href="#" variant="body2">
                                                {i18next.t('authPages.forgotPassword')}
                                            </Link>
                                        </Grid> */}
                                        <Grid item>
                                            <Link to={Routes.Signup.path} className="fw-bold" variant="body2">
                                                {i18next.t('authPages.dontHaveAccount')}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Box mt={5}>
                                        <Copyright copyrightTitle={i18next.t('generic.ppel')} />
                                    </Box>
                                </form>
                            </div>
                        </Card.Body>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(SignInPage)));
