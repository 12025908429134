import React from 'react';
import GenericModalComponent from "../../../../../components/genericModalComponent/GenericModalComponent";
import {Col, Row} from "@themesberg/react-bootstrap";
import {AutoField, AutoForm, SelectField} from "uniforms-bootstrap5";
import {createUniformSchema} from "../../../../../utils/uniformsSchemaBridgeGenerator";
import {housingStatusSchema} from "../../../../../schema/beneficiaryFormSchemas/nested/housingStatusSchema";
import domainsService from "../../../../../_services/domainsServices/domainsService";
import {HousingStatusModel} from "../../../../../model/beneficiary/housingStatusModel";

type Props = {
    openFormModal: boolean,
    modalTitle: String,
    onClosingModalCallback: Function,
    onSaveButtonCallback: Function,
    okButtonTitle: String,
    recordData: Object,
    isEditMode: boolean,
    isViewMode: boolean,
    isCreateMode: boolean,
    isEditModalFormState: boolean,
    isViewModalFormState: boolean,
    isCreateModalFormState: boolean,
}

class HousingStatusFormComp extends React.Component<Props> {

    state = {
        localRecordData: Object.assign({}, this.props.recordData),

        //HousingStruct State
        pendingGettingHousingStructTypes: false,
        errorGettingHousingStructTypes: false,
        housingStructTypes: null,

        //ResidenceStatuses State
        pendingGettingResidenceStatuses: false,
        errorGettingResidenceStatuses: false,
        residenceStatusesTypes: null,
    };

    _housingStatusFormRef = null;

    /**
     * Creates the Uniform Schema Bridge
     * @returns {JSONSchemaBridge}
     */
    _housingStatusUniformSchema = createUniformSchema(housingStatusSchema);

    componentDidMount() {
        const {
            // Parent Form State
            isCreateMode,
            isEditMode,
            isViewMode,

            // Modal Form State
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        if (isCreateModalFormState) {
            this.setState({
                localRecordData: Object.assign({}, HousingStatusModel)
            })
        }

        this.fetchHousingStructTypes();

        this.fetchResidenceStatusTypes();
    }

    componentWillUnmount() {
        this._housingStatusFormRef = null;
    }

    /**
     * Called every time form onSubmit occurs
     * On Submit we update the parent model by calling it's callback to update records data
     * witch comes back to us from parent once again via props
     * @param model
     */
    updateModelCallback = (model) => {
        if(model) {
            if(model.housingStructType && model.housingStructType.id) {
                model.housingStructType.id = parseInt(model.housingStructType.id);
            }

            if(model.residenceStatusType && model.residenceStatusType.id) {
                model.residenceStatusType.id = parseInt(model.residenceStatusType.id);
            }
        }

        this.setState({
            localRecordData: Object.assign({}, model)
        });
    };

    /**
     *  In case of Autoform of single
     */
    onSaveButtonPress = () => {
        const {
            localRecordData
        } = this.state;

        const {
            onSaveButtonCallback
        } = this.props;

        this._housingStatusFormRef.submit()
            .then(() => {
                onSaveButtonCallback(localRecordData);
            }).catch((error) => {
            console.log("Form has validation errors", error);
        });
    };

    fetchHousingStructTypes = () => {
        this.setState({
            pendingGettingHousingStructTypes: true,
        });

        domainsService.getHousingStructTypes()
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {
                const uniformObjCompatible = response.map((item, index) => {
                    return {
                        label: item.description,
                        value: item.id
                    }
                });

                this.setState({
                    pendingGettingHousingStructTypes: false,
                    errorGettingHousingStructTypes: false,
                    housingStructTypes: uniformObjCompatible,
                })
            })
            .catch((error) => {
                console.log("Error getting Residence Types Data: ", error);
                this.setState({
                    pendingGettingHousingStructTypes: false,
                    errorGettingHousingStructTypes: true
                })
            });
    }

    fetchResidenceStatusTypes = () => {
        this.setState({
            pendingGettingResidenceStatuses: true,
        });

        domainsService.getResidenceStatuses()
            // In success we split model to master/details
            // so that we can easily handle them on separated forms
            .then((response) => {
                const uniformObjCompatible = response.map((item, index) => {
                    return {
                        label: item.description,
                        value: item.id
                    }
                });

                this.setState({
                    pendingGettingResidenceStatuses: false,
                    errorGettingResidenceStatuses: false,
                    residenceStatusesTypes: uniformObjCompatible,
                })
            })
            .catch((error) => {
                console.log("Error getting Residence Types Data: ", error);
                this.setState({
                    pendingGettingResidenceStatuses: false,
                    errorGettingResidenceStatuses: true
                })
            });
    }

    render() {
        const {
            openFormModal,
            onClosingModalCallback,
            okModalButtonCallback,
            okButtonTitle,
            modalTitle,
            isViewMode,
            isEditMode,
            isCreateMode,
            isEditModalFormState,
            isViewModalFormState,
            isCreateModalFormState,
        } = this.props;

        const {
            localRecordData,

            //HousingStruct State
            pendingGettingHousingStructTypes,
            errorGettingHousingStructTypes,
            housingStructTypes,

            //ResidenceStatuses State
            pendingGettingResidenceStatuses,
            errorGettingResidenceStatuses,
            residenceStatusesTypes
        } = this.state;

        return (
            <GenericModalComponent
                showOkButton={!isViewModalFormState}
                showCancelButton={true}
                okModalButtonTitle={okButtonTitle}
                okButtonCallback={this.onSaveButtonPress}
                closeModalCallback={onClosingModalCallback}
                isModalVisible={openFormModal}
                modalTitle={modalTitle}
                isModalFullScreen={false}
                modalSize={'lg'}
            >

                <Row className={"autoFormCustom"}>
                    <Col xs={12} xl={12}>
                        <AutoForm
                            ref={(form) => {
                                this._housingStatusFormRef = form
                            }}
                            schema={this._housingStatusUniformSchema}
                            onChangeModel={(model) => this.updateModelCallback(model)}
                            model={localRecordData ? localRecordData : {}}
                            readOnly={isViewMode}
                            showInlineError
                            placeholder
                        >
                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"noPermanentResidence"}/>
                                </Col>
                            </Row>

                            <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={4}>
                                    <SelectField
                                        name="housingStructType.id"
                                        options={housingStructTypes}
                                    />
                                </Col>
                            </Row>

                            <Row className={"mt-4 mb-4"}>
                                <Col xs={12} xl={4}>
                                    <SelectField
                                        name="residenceStatusType.id"
                                        options={residenceStatusesTypes}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={10}>
                                    <AutoField name={"residenceStatusDescription"}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={4}>
                                    <AutoField name={"homelessFrom"}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} xl={6}>
                                    <AutoField name={"homelessReasonDescription"}/>
                                </Col>
                            </Row>

                        </AutoForm>
                    </Col>
                </Row>
            </GenericModalComponent>
        )
    }
}

export default HousingStatusFormComp;
