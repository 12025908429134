import history from "../../routing/history";
import {decodeJwtToken} from "../../utils/jwtUtils";
import opsYtkePublicApiAxiosInstance from "../OpsYtkePublicApi";

const API_URL = process.env.REACT_APP_WEB_API_BASE;
const LOCAL_STORAGE_AUTH_USER_DATA = process.env.REACT_APP_AUTH_USER_DATA;

class AuthService {
    login(username, password) {
        return opsYtkePublicApiAxiosInstance.post(API_URL + "/public/login",
            {
                userName: username,
                password: password
            }).then(response => {
            if (response.data.jwt) {
                const userAuthenticatedData = decodeJwtToken(response.data.jwt);
                localStorage.setItem(LOCAL_STORAGE_AUTH_USER_DATA, JSON.stringify(userAuthenticatedData));
            }
            return response.data;
        });
    }

    logout() {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_USER_DATA);
    }

    logoutAndGoToLoginScreen() {
        this.logout();
        history.push('/sign-in')
    }

    validateUserViaTaxisNet(username, password) {
        return opsYtkePublicApiAxiosInstance.post(API_URL + "/public/taxis", {
            userName: username,
            password: password
        });
    }

	validateUserViaTaxisNetCode(code) {
        return opsYtkePublicApiAxiosInstance.post(API_URL + "/public/taxis", {
            code: code
        });
    }

    register(username, password, alfa) {
        return opsYtkePublicApiAxiosInstance.post(API_URL + '/public/user/register/' + alfa, {
            username: username,
            encPassword: password
        });
    }

    validateTokenAndGetUser = () => {
        return new Promise((resolve, reject) => {
            const accessTokenFromLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_USER_DATA))?.accessToken;
            if (accessTokenFromLocalStorage) {

                // TODO Change static validate response from dynamic from API service when service is ready
                resolve(accessTokenFromLocalStorage);
                // return axios.post(API_URL + "/public/validate", {
                //     accessToken: accessTokenFromLocalStorage,
                // }).then((response) => {
                //     resolve(response.data.jwt);
                // }).catch((error) => {
                //     console.log("Error from token validation:", error);
                //     resolve(null);
                // });
            } else {
                resolve(null);
            }
        });
    };

    getCurrentUser() {
        const userFromStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_USER_DATA));
        if (typeof userFromStorage !== "undefined" && userFromStorage !== null && userFromStorage !== '') {
            return userFromStorage;
        } else {
            return null;
        }
    }
}

export default new AuthService();
