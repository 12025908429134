import {FamilyDetailsModel} from "./familyDetailsModel";
import {EducationModel} from "./educationModel";

export const beneficiaryModel = {
    id: null,
    amka: null,
    afm: null,
    lastName: null,
    firstName: null,
    gender: null,
    birthDate: null,
    alive: null,
    addresses: [],
    education: EducationModel,
    familyDetails: FamilyDetailsModel,
    finances: [],
    housingStatuses: [],
    insuranceCoverages: [],
    languages: [],
    legalStates: [],
    communicationWays: [],
    sessionsAndReferences: [],
    socialProgramsToParticipate: [],
    specialCategories: [],
    works: []
}
