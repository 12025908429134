import i18next from "../../../translations/i18nConfigInstance";
import {UniformsMaterialDatePicker} from "../../../components/genericMaterialDatePicker/UniformsMaterialDatePicker";

export const housingStatusSchema = {
    title: i18next.t("beneficiaryForm.addressesTitle"),
    required:[
        //"id",
        "noPermanentResidence",
        "residenceStatusDescription",
        "residenceStatusType"
    ],
    type:"object",
    properties:{
        // id:{
        //     label: i18next.t("beneficiaryForm.addresses.id"),
        //     type:"integer",
        // },
        noPermanentResidence:{
            label: i18next.t("beneficiaryForm.housingStatuses.noPermanentResidence"),
            type:"boolean"
        },
        homelessFrom:{
            label: i18next.t("beneficiaryForm.housingStatuses.homelessFrom"),
            oneOf: [{ type: "string" },  { type: "null" }],
            uniforms: {
                component: UniformsMaterialDatePicker
            }
        },
        homelessReasonDescription:{
            label: i18next.t("beneficiaryForm.housingStatuses.homelessReasonDescription"),
            type:"string"
        },
        residenceStatusDescription:{
            label: i18next.t("beneficiaryForm.housingStatuses.residenceStatusDescription"),
            type:"string"
        },
        residenceStatusType: {
            title: i18next.t("beneficiaryForm.housingStatuses.residenceStatusType"),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t("beneficiaryForm.housingStatuses.residenceStatusType"),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        },
        housingStructType: {
            title: i18next.t("beneficiaryForm.housingStatuses.housingStructType"),
            required:[
                "id"
            ],
            type:"object",
            properties: {
                id: {
                    label: i18next.t("beneficiaryForm.housingStatuses.housingStructType"),
                    type:"integer",
                    options: [],
                    uniforms: {
                        type: "String",
                        placeholder: i18next.t("generic.pleaseSelect")
                    }
                }
            }
        }
    }
};
